import { IonButton, IonIcon } from '@ionic/react'
import { addCircle, cloudUpload, download } from 'ionicons/icons'
import { useState } from 'react'
import { useHistory } from 'react-router'
import ModalSmall from '../../../../components/Organisms/ModalSmall/ModalSmall'
import { ModalExportProducts } from '../ModalExportProducts/ModalExportProducts'
import { ModalImportProducts } from '../ModalImportProducts/ModalImportProducts'

export function ButtonsHeader () {
  const history = useHistory()
  const [isOpenModalExport, setIsOpenModalExport] = useState(false)
  const [isOpenModalImport, setIsOpenModalImport] = useState(false)

  return (
    <>
      <div className="ion-text-end ion-margin-top">
        <IonButton fill="clear" color="secondary" shape="round" onClick={() => { setIsOpenModalExport(true) }}>
          <IonIcon slot="start" icon={download} />
          Exportar
        </IonButton>
        <IonButton fill="clear" color="secondary" shape="round" onClick={() => { setIsOpenModalImport(true) }}>
          <IonIcon slot="start" icon={cloudUpload} />
          Importar
        </IonButton>
        <IonButton color="secondary" shape="round" onClick={() => { history.push('/productos/crear-producto') }}>
          <IonIcon slot="start" icon={addCircle} />
          Agregar producto
        </IonButton>
      </div>
      <ModalSmall isOpen={isOpenModalExport} onDidDismiss={() => { setIsOpenModalExport(false) }} customClass="ion-padding">
        <ModalExportProducts setIsOpenModalExport={setIsOpenModalExport} />
      </ModalSmall>

      <ModalSmall isOpen={isOpenModalImport} onDidDismiss={() => { setIsOpenModalImport(false) }}>
        <ModalImportProducts setIsOpenModalImport={setIsOpenModalImport} />
      </ModalSmall>
    </>
  )
}
