import { IonButton, IonButtons, IonCard, IonIcon, IonInput, IonItem, IonLabel, IonList, IonMenuButton, useIonToast } from '@ionic/react'
import { addCircle, search } from 'ionicons/icons'
import { TitleForm } from '../../components/Atoms/TitleForm'
import { WrapperApp } from '../../components/Organisms/WrapperApp/WrapperApp'
import { HeaderDashboard } from '../Dashboard/components/HeaderDashboard'
import { LineSeparator } from '../../components/Atoms/LineSeparator/LineSeparator'
import { useMemo, useState } from 'react'
import ModalSmall from '../../components/Organisms/ModalSmall/ModalSmall'
import { Input } from '../../components/Atoms/Input/Input'

interface Divice {
  id?: number
  name: string
  box: string
  balanceE4coin: number
  balanceBs: number
}

const initialDivices: Divice[] = [
  {
    id: 1,
    name: 'Post tienda 1',
    box: 'Caja 1',
    balanceE4coin: 300.00,
    balanceBs: 200.00
  },
  {
    id: 2,
    name: 'Post tienda 2',
    box: 'Caja 2',
    balanceE4coin: 150.00,
    balanceBs: 100.00
  }
]

export function Divices () {
  const [present] = useIonToast()
  const [searchValue, setSearchValue] = useState('')
  const [divices, setDivices] = useState(initialDivices)
  const [openModalAddDivice, setOpenModalAddDivice] = useState(false)
  const INITIAL_NEW_DIVICE = {
    name: '',
    box: '',
    balanceE4coin: 0,
    balanceBs: 0
  }
  const [newDivice, setNewDivice] = useState<Divice>(INITIAL_NEW_DIVICE)

  const handleSearchValue = (e: any) => {
    setSearchValue(e?.target?.value)
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setOpenModalAddDivice(false)
    setDivices(prevDivices => [...prevDivices, newDivice])
    setNewDivice(INITIAL_NEW_DIVICE)

    setSearchValue('')
    // run services edit here!
    present({
      message: 'Dispositivo agregado',
      duration: 2500,
      position: 'bottom',
      color: 'success'
    })
  }

  const handleChange = (e: any) => {
    setNewDivice(prevValue => ({
      ...prevValue,
      id: divices.length + 1,
      balanceE4coin: 300.00,
      balanceBs: 200.00,
      [e.target.name]: e.target.value
    }))
  }

  const filteredDivices = useMemo(() => {
    return searchValue !== null
      ? divices.filter(divice => divice.name.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) || divice.box.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()))
      : divices
  }, [searchValue, divices])

  return (
    <>
      <WrapperApp
        pageName="Dispositivos"
        hiddenAvatar={true}
        renderButtons={() => (
          <>
            <IonButtons slot="start">
              <IonMenuButton />
            </IonButtons>
            <HeaderDashboard />
          </>
        )}
      >
        <>
          <IonCard className="box ion-padding">
            <div>
              <TitleForm title="Dispositivos" customClass="ion-margin-start" />
              <LineSeparator />
              <IonItem fill='solid' style={{ width: '100%' }}>
                <IonIcon slot="start" icon={search} />
                <IonInput name='search' placeholder='Buscar' type='search' value={searchValue} onIonChange={handleSearchValue} />
              </IonItem>
              <IonItem button={false} detail={false} lines='full'>
                <IonLabel slot='start' className='ion-no-padding'>
                  <p className='ion-no-padding'>Nombre</p>
                </IonLabel>
                <IonLabel slot='end' className='ion-no-padding'>
                  <p className='ion-no-padding'>Saldo</p>
                </IonLabel>
              </IonItem>
              <IonList>
                {filteredDivices.map(divice =>
                  <IonItem button={true} detail={false} lines='full' key={divice.id}>
                    <IonLabel slot='start'>
                      <h2>{divice.name}</h2>
                      <p>{divice.box}</p>
                    </IonLabel>
                    <IonLabel slot='end'>
                      <h2>${divice.balanceE4coin} e4coin</h2>
                      <p>{divice.balanceBs} bs</p>
                    </IonLabel>
                  </IonItem>
                )}
              </IonList>
            </div>
            <IonItem lines="none">
              <IonIcon icon={addCircle} slot="end" size="large" onClick={() => { setOpenModalAddDivice(true) }}/>
            </IonItem>
          </IonCard>
          <ModalSmall isOpen={openModalAddDivice} onDidDismiss={() => { setOpenModalAddDivice(false) }}>
            <div className="box ion-padding">
              <form className="form" onSubmit={e => { handleSubmit(e) }}>
                <TitleForm title='Añadir dispositivo' />
                <Input label="Nombre del dispositivo" type="text" name="name" value={newDivice.name} onChange={e => { handleChange(e) }}/>
                <Input label="Caja" type="text" name="box" value={newDivice.box} onChange={e => { handleChange(e) }}/>
                <div className="ion-text-end">
                  <IonButton color="secondary" shape="round" type="submit">Añadir</IonButton>
                  <IonButton color="secondary" shape="round" fill="clear" type="button" onClick={() => { setOpenModalAddDivice(false) }}>Cancelar</IonButton>
                </div>
              </form>
            </div>
          </ModalSmall>
        </>
      </WrapperApp>
    </>
  )
}
