import { IonButton, IonIcon, IonImg, IonItem, IonLabel, IonText, IonToggle, useIonToast } from '@ionic/react'
import { checkmarkDoneSharp, fingerPrintSharp, logInSharp, scanSharp } from 'ionicons/icons'
import { useState } from 'react'
import qr from '../../../assets/qr-code.png'
import { Input } from '../../../components/Atoms/Input/Input'
import { TitleForm } from '../../../components/Atoms/TitleForm'
import ModalSmall from '../../../components/Organisms/ModalSmall/ModalSmall'
import { SectionListOfData } from '../../../components/Organisms/SectionListOfData/SectionListOfData'
import { type DataUserType } from '../../../models'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { editChekedDoudleSegurity, editFaceId, editLoginSessionExt, editTouchId } from '../../../store/states/userSegurity'

export interface Props {
  dataList: DataUserType[]
  children?: React.ReactNode
}

const SectionListSegurityData: React.FC<Props> = ({ dataList }) => {
  const userSegurityConfig = useAppSelector(store => store.userSegurity)
  const dispatch = useAppDispatch()

  const [isOpenModalShowQr, setIsOpenModalShowQr] = useState(false)
  const [isOpenModalShowCode, setIsOpenModalShowCode] = useState(false)
  const [present] = useIonToast()

  const handleDoubleSecurity = () => {
    if (userSegurityConfig.chekedDoudleSegurity) {
      dispatch(editChekedDoudleSegurity(false))
      return
    }
    setIsOpenModalShowQr(true)
  }

  const handleEnterCode = () => {
    setIsOpenModalShowQr(false)
    setIsOpenModalShowCode(true)
  }

  const handleSendCode = () => {
    dispatch(editChekedDoudleSegurity(true))
    setIsOpenModalShowCode(false)
    setIsOpenModalShowQr(false)
    present({
      message: 'Doble autenticación realizada exitosamente',
      duration: 2500,
      position: 'bottom',
      color: 'success'
    })
  }

  return (
		<>
		<SectionListOfData titleSection="Seguridad" dataList={dataList} >
			<IonItem>
				<IonIcon icon={checkmarkDoneSharp} slot="start"></IonIcon>
				<IonLabel>
					<span>Doble autentificación</span>
				</IonLabel>
				<div onClick={() => { handleDoubleSecurity() }}>
					<IonToggle color="secondary" name="Auth" checked={userSegurityConfig.chekedDoudleSegurity} slot="end" />
				</div>
			</IonItem>
			<IonItem>
				<IonIcon icon={fingerPrintSharp} slot="start"></IonIcon>
				<IonLabel>Touch ID</IonLabel>
				<IonToggle color="secondary" name="Touch" checked={userSegurityConfig.touchId} slot="end" onIonChange={e => dispatch(editTouchId(e.detail.checked))} />
			</IonItem>
			<IonItem>
				<IonIcon icon={scanSharp} slot="start"></IonIcon>
				<IonLabel>Face ID</IonLabel>
				<IonToggle color="secondary" name="Face" checked={userSegurityConfig.faceId} slot="end" onIonChange={e => dispatch(editFaceId(e.detail.checked))} />
			</IonItem>
			<IonItem>
				<IonIcon icon={logInSharp} slot="start"></IonIcon>
				<IonLabel>login session ext</IonLabel>
				<IonToggle color="secondary" name="Login" checked={userSegurityConfig.loginSessionExt} slot="end" onIonChange={e => dispatch(editLoginSessionExt(e.detail.checked))} />
			</IonItem>
		</SectionListOfData>

		<ModalSmall isOpen={isOpenModalShowQr} onDidDismiss={() => { setIsOpenModalShowQr(false) }}>
			<div className="form ion-text-center ion-padding">
				<IonText color="dark"><p>Abra la aplicación en su otro dispositivo móvil, luego escanee el código QR con la cámara de su dispositivo para obtener un código.</p></IonText>
				<IonText color="dark"><h2>456 338</h2></IonText>
				<div className="flex ion-justify-content-center"><IonImg src={qr} alt="qr" style={{ maxWidth: '150px', maxHeight: '150px' }}/></div>
				<IonText color="secondary"><p>¿No puedes escanear el código QR?</p></IonText>
				<IonButton color="secondary" shape="round" onClick={() => { handleEnterCode() }}>Ingresar código</IonButton>
				<IonText color="dark"><p>Utilice este dispositivo en su lugar</p></IonText>
			</div>
		</ModalSmall>

		<ModalSmall isOpen={isOpenModalShowCode} onDidDismiss={() => { setIsOpenModalShowCode(false) }}>
			<div className="form">
				<TitleForm title="Ingresa el código envíado" />
				<Input label="Código de verificación" type="text" name="code" value="" />
				<IonText color="danger"><p className="ion-text-end">Tiempo restante 50 segundos</p></IonText>
				<div className="ion-text-center" >
					<IonButton color="secondary" shape="round" onClick={() => { handleSendCode() }}>Enviar código</IonButton>
					<IonButton color="secondary" shape="round" fill="clear" type="button" onClick={() => { setIsOpenModalShowCode(false) }}>Cancelar</IonButton>
				</div>
			</div>
		</ModalSmall>
		</>
  )
}

export default SectionListSegurityData
