import { IonCard, IonIcon, IonItem, IonLabel, IonList, useIonActionSheet, useIonToast } from '@ionic/react'
import { addCircle, chevronForwardOutline, trashSharp } from 'ionicons/icons'
import { useState } from 'react'
import { TitleForm } from '../../../../components/Atoms/TitleForm'
import ModalSmall from '../../../../components/Organisms/ModalSmall/ModalSmall'
import type { Agent } from '../../../../models'
import { FormAgent } from '../FormAgent'

interface HandleParams {
  isEdit: boolean
  itemEdit?: Agent
}

const ListOfAgent: React.FC = () => {
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [itemEdit, setItemEdit] = useState<Agent>()
  const [present] = useIonActionSheet()
  const [presentAlert] = useIonToast()

  const agentsList: Agent[] = [
    {
      id: 'ASad-sf45s-dffds1',
      name: 'Agente 1'
    },
    {
      id: 'ASad-sf45s-dffds2',
      name: 'Agente 2'
    }
  ]

  const handleAgent = ({ isEdit, itemEdit }: HandleParams) => {
    setIsOpenModal(true)
    setIsEdit(isEdit)
    if (isEdit) setItemEdit(itemEdit)
  }

  const showActionSheetDeleteAgent = (agent: Agent) => {
    present({
      header: `Desea eliminar el agente ${agent.name}`,
      buttons: [
        {
          text: 'Si, eliminar',
          role: 'destructive',
          data: {
            action: 'delete'
          },
          handler: handleDeleteAgent
        },
        {
          text: 'Cancel',
          role: 'cancel',
          data: {
            action: 'cancel'
          }
        }
      ]
    })
  }

  const handleDeleteAgent = () => {
    // services delete here!
    presentAlert({
      message: 'Agente eliminado',
      duration: 2500,
      position: 'bottom',
      color: 'success'
    })
  }

  return (
		<>
			<IonCard className="box ion-padding">
				<div>
					<TitleForm title="Agentes" customClass="ion-margin-start" />
					<IonList>
						{agentsList.map(agent =>
							<IonItem key={agent.id} button={true} >
								<IonLabel>
									{ agent.name }
								</IonLabel>
								<IonIcon icon={trashSharp} slot="end" onClick={() => { showActionSheetDeleteAgent(agent) }}/>
								<IonIcon icon={chevronForwardOutline} slot="end" onClick={() => { handleAgent({ isEdit: true, itemEdit: agent }) }} />
							</IonItem>
						)}
					</IonList>
				</div>
				<IonItem lines="none">
					<IonIcon icon={addCircle} slot="end" size="large" onClick={() => { handleAgent({ isEdit: false }) }}/>
				</IonItem>
			</IonCard>
			<ModalSmall isOpen={isOpenModal} onDidDismiss={() => { setIsOpenModal(false) }}>
				<FormAgent isEdit={isEdit} itemEdit={itemEdit} closeModal={() => { setIsOpenModal(false) }} />
			</ModalSmall>
			</>
  )
}

export default ListOfAgent
