import { IonCol, IonGrid, IonIcon, IonRow } from '@ionic/react'
import { logoFacebook, logoGoogle, logoInstagram, logoTwitter } from 'ionicons/icons'
import './LoginSocialMedia.css'

const socialMedia = [
  logoFacebook,
  logoGoogle,
  logoInstagram,
  logoTwitter
]

export const LoginSocialMedia = () => {
  return (
    <IonGrid className="grid-login-social ion-padding">
      <IonRow className="flex ion-justify-content-center">
        {socialMedia.map((social, key) => (
          <IonCol size="2" className="flex ion-justify-content-center" key={key}>
            <IonIcon ios-size="large" md={social} size="large" />
          </IonCol>
        ))}
      </IonRow>
    </IonGrid>
  )
}
