import { IonText } from '@ionic/react'
import React from 'react'
import { type DataUserType } from '../../../models'
import { ListOfData } from '../../Molecules/ListOfData/ListOfData'

interface SectionListOfDataProps {
  titleSection: string
  dataList: DataUserType[]
  children?: React.ReactNode
}

export const SectionListOfData: React.FC<SectionListOfDataProps> = ({ titleSection, dataList, children }) => {
  return (
    <>
      <IonText><h5 className="ion-padding">{ titleSection }</h5></IonText>
      <ListOfData dataList={dataList}>
        { children }
      </ListOfData>
    </>
  )
}
