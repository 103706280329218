import { IonCard, IonItem, IonLabel, IonList } from '@ionic/react'
import { useState } from 'react'
import { TitleForm } from '../../../../components/Atoms/TitleForm'
import ModalSmall from '../../../../components/Organisms/ModalSmall/ModalSmall'
import { FormSecurityQuestions } from '../FormSecurityQuestions'

const ListOfSecurityQuestions: React.FC = () => {
  const [isOpenModal, setIsOpenModal] = useState(false)

  return (
		<>
			<IonCard className="box ion-padding">
				<div>
					<TitleForm title="Preguntas de seguridad" customClass="ion-margin-start" />
					<IonList>
						<IonItem button={true} detail={true} onClick={() => { setIsOpenModal(true) }}>
							<IonLabel>
								<h2>Nombre de su primera mascota</h2>
								<p>Dog</p>
							</IonLabel>
						</IonItem>
						<IonItem button={true} detail={true} onClick={() => { setIsOpenModal(true) }}>
							<IonLabel>
								<h2>Nombre de soltera de su madre</h2>
								<p>Rodriguez</p>
							</IonLabel>
						</IonItem>
						<IonItem button={true} detail={true} onClick={() => { setIsOpenModal(true) }}>
							<IonLabel>
								<h2>Nombre del heroe de su infancia</h2>
								<p>Spiderman</p>
							</IonLabel>
						</IonItem>
					</IonList>
				</div>
			</IonCard>
			<ModalSmall isOpen={isOpenModal} onDidDismiss={() => { setIsOpenModal(false) }}>
				<FormSecurityQuestions closeModal={() => { setIsOpenModal(false) }} />
			</ModalSmall>
		</>
  )
}

export default ListOfSecurityQuestions
