import { IonButton, IonCard, IonItem, IonLabel, IonToggle } from '@ionic/react'
import { TitleForm } from '../../../../components/Atoms/TitleForm'

export function OrderNotifications () {
  return (
		<IonCard className="box ion-padding">
			<TitleForm title='Notificaciones de pedido' customClass='ion-padding-start'/>
			<TitleForm title='Se desactivaron las notifiaciones push para esta aplicación en este dispositivo. Para recibir notifiaciones push, ve a tus Ajustes de notifiación de aplicación y activalas.' customClass='ion-padding-start ion-padding-top'/>
			<div style={{ width: '100%', height: '1px', background: '#727070b1' }}></div>
			<IonItem lines='none'>
				<IonLabel>
					<h2>Permitir notifiaciones de pedidos nuevos</h2>
				</IonLabel>
				<IonToggle color="secondary" slot="end" onIonChange={e => { console.log(e) }}></IonToggle>
			</IonItem>
			<div style={{ width: '100%', height: '1px', background: '#727070b1' }}></div>
			<IonLabel>
				<p className='ion-text-wrap ion-padding-start ion-padding-top ion-text-center'>Mostrar una notificación cuando un pedido se reciba</p>
			</IonLabel>
			<div style={{ marginTop: '3.5rem', marginBottom: '1.5rem' }}>
				<IonButton color="secondary" shape="round" size='large' fill='outline' expand="full">Previsualizar notificación</IonButton>
			</div>
			<IonItem lines='none'>
				<IonLabel>
					<h2>Permitir notifiaciones de llegada en puerta</h2>
				</IonLabel>
				<IonToggle color="secondary" slot="end" onIonChange={e => { console.log(e) }}></IonToggle>
			</IonItem>
			<div style={{ width: '100%', height: '1px', background: '#727070b1' }}></div>
			<IonLabel>
				<p className='ion-text-wrap ion-padding-start ion-padding-top ion-text-center'>Mostrar una alerta cuando llegue tu cliente</p>
			</IonLabel>

			<IonItem lines='none'>
				<IonLabel>
					<h2>Notificarme cuando la impresión esté pausada debedo al segundo plano</h2>
				</IonLabel>
				<IonToggle color="secondary" slot="end" onIonChange={e => { console.log(e) }}></IonToggle>
			</IonItem>
			<div style={{ width: '100%', height: '1px', background: '#727070b1' }}></div>
			<IonLabel>
				<p className='ion-text-wrap ion-padding-start ion-padding-top ion-text-center'>Mostrar una notificación cuando la impresión se detiene debido a que la aplicación  está en segundo plano durante un periodo.</p>
			</IonLabel>
		</IonCard>
  )
}
