import { IonButton, IonCard, IonItem, IonLabel, IonList, IonToggle, useIonToast } from '@ionic/react'
import { useState } from 'react'
import { useHistory } from 'react-router'
import { TitleForm } from '../../../../components/Atoms/TitleForm'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import { editPreferredChannelOfUse } from '../../../../store/states/userPreference'

const ListPreferredChannelOfUse: React.FC = () => {
  const [present] = useIonToast()
  const userPreference = useAppSelector(store => store.userPreference)
  const dispatch = useAppDispatch()
  const history = useHistory()
  const [checked, setCheked] = useState({
    instaCash: userPreference.preferredChannelOfUse.instaCash,
    qr: userPreference.preferredChannelOfUse.qr,
    nfc: userPreference.preferredChannelOfUse.nfc,
    todos: userPreference.preferredChannelOfUse.todos
  })

  const handleSubmit = () => {
    dispatch(editPreferredChannelOfUse(checked))
    present({
      message: 'Cambio guardado exitosamente',
      duration: 2500,
      position: 'bottom',
      color: 'success'
    })
  }

  const onChange = (e: any) => {
    setCheked(prevValue => ({
      ...prevValue,
      [e.target.name]: e.detail.checked
    }))
  }

  return (
		<>
			<IonCard className="box ion-padding">
				<div>
					<TitleForm title="Canal de uso" customClass="ion-margin-start" />
					<IonList>
						<IonItem>
							<IonLabel>InstaCash</IonLabel>
							<IonToggle name="instaCash" color="secondary" checked={checked.instaCash} slot="end" onIonChange={e => { onChange(e) }} />
						</IonItem>
						<IonItem>
							<IonLabel>QR</IonLabel>
							<IonToggle name="qr" color="secondary" checked={checked.qr} slot="end" onIonChange={e => { onChange(e) }} />
						</IonItem>
						<IonItem>
							<IonLabel>NFC</IonLabel>
							<IonToggle name="nfc" color="secondary" checked={checked.nfc} slot="end" onIonChange={e => { onChange(e) }} />
						</IonItem>
						<IonItem lines="none">
							<IonLabel>Todos</IonLabel>
							<IonToggle name="todos" color="secondary" checked={checked.todos} slot="end" onIonChange={e => { onChange(e) }} />
						</IonItem>
					</IonList>
					<div className="ion-text-end">
						<IonButton color="secondary" shape="round" type="submit" onClick={() => { handleSubmit() }}>Guardar</IonButton>
						<IonButton color="secondary" shape="round" fill="clear" type="button" onClick={() => { history.goBack() }}>Cancelar</IonButton>
					</div>
				</div>
			</IonCard>
		</>
  )
}

export default ListPreferredChannelOfUse
