export const banks: Array<{ value: string, label: string }> = [
  {
    value: '0108',
    label: 'Provincial'
  },
  {
    value: '0134',
    label: 'Banesco'
  },
  {
    value: '0105',
    label: 'Mercantil'
  },
  {
    value: '0102',
    label: 'Venezuela'
  },
  {
    value: '0049',
    label: 'Santander'
  }
]
