import { current, type PayloadAction, createSlice } from '@reduxjs/toolkit'

import type { AccountBank, UserBanks } from '../../models'
import { getLocalStorage, setLocalStorage } from '../../utilities/localStorage.utility'
import bankSantander from '../../assets/bank-santander.png'
import bankVenezuela from '../../assets/bank-venezuela.png'

const initialState: UserBanks = {
  banks: [
    {
      id: 1,
      name: 'Banco Santander',
      accountNumber: '00498436994470088379',
      icon: bankSantander,
      documentUserType: 'V',
      documentUser: '27346807'
    },
    {
      id: 2,
      name: 'Banco de Venezuela',
      accountNumber: '00494960426068457802',
      icon: bankVenezuela,
      documentUserType: 'V',
      documentUser: '27346807'
    }
  ]
}

export const userBanksSlice = createSlice({
  name: 'user-banks',
  initialState: getLocalStorage('user-banks') ? JSON.parse(getLocalStorage('user-banks') as string) : initialState,
  reducers: {
    saveBanks (state: UserBanks, action: PayloadAction<UserBanks>) {
      setLocalStorage('user-banks', action.payload)
      return { ...state, ...action.payload }
    },
    addBankAccount (state: UserBanks, action: PayloadAction<AccountBank>) {
      const result = { ...state, banks: [...state.banks, action.payload] }
      setLocalStorage('user-banks', result)
      return result
    },
    editBankAccount (state: UserBanks, action: PayloadAction<AccountBank>) {
      const filterBanks = [...current(state).banks].filter(bank => bank.id !== action.payload.id)
      const findBank = [...current(state).banks].find(bank => bank.id === action.payload.id)
      const editBank = { ...findBank, ...action.payload }
      const banks = [...filterBanks, editBank]
      const result = { ...state, banks }
      setLocalStorage('user-banks', result)
      return result
    }
  }
})

export const {
  saveBanks,
  addBankAccount,
  editBankAccount
} = userBanksSlice.actions
export default userBanksSlice.reducer
