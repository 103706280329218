import { IonAvatar, IonIcon, IonImg, useIonToast } from '@ionic/react'
import { cameraOutline } from 'ionicons/icons'
import fondo from '../../../assets/fondo-profile.png'
import { Camera, CameraResultType } from '@capacitor/camera'
import './ProfileUserAvatar.css'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { editAvatar } from '../../../store/states/user'
import { editAvatarCommerce } from '../../../store/states/commerce'

interface Props {
  avatar: string
  portrait?: string
}

export const ProfileUserAvatar: React.FC<Props> = ({ avatar, portrait = null }) => {
  const dispatch = useAppDispatch()
  const roleUser = useAppSelector(store => store.roleUserActive)
  const [present] = useIonToast()

  const takePicture = async () => {
    try {
      const image = await Camera.getPhoto({
        quality: 100,
        allowEditing: true,
        resultType: CameraResultType.Uri
      })

      if (roleUser.id.toString() === '1') {
        dispatch(editAvatar({ avatar: image.webPath }))
      } else {
        dispatch(editAvatarCommerce({ avatar: image.webPath }))
      }

      present({
        message: 'Foto de perfil cambiada',
        duration: 2500,
        position: 'bottom',
        color: 'success'
      })
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className="profile-avatar flex ion-padding ion-justify-content-center ion-align-items-center">
      <div className="fondo_profile"><img className="fondo_profile-img" src={portrait ?? fondo} alt="fondo" /></div>
      <IonAvatar slot="end" className="flex ion-justify-content-center" style={{ position: 'relative', width: '90px', height: '90px' }}>
        <div className="profile-avatar-camera" onClick={async () => { await takePicture() }}>
          <IonIcon icon={cameraOutline} size="large" />
        </div>
        <IonImg src={avatar} alt="avatar" style={{ width: '90px', height: '90px', objectFit: 'contain', boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)' }} className="ion-align-self-center" />
      </IonAvatar>
    </div>
  )
}
