import { IonModal } from '@ionic/react'

interface ModalSmallProps {
  isOpen: boolean
  onDidDismiss: () => void
  children: React.ReactNode
  customClass?: string
}

const ModalSmall: React.FC<ModalSmallProps> = ({ isOpen, onDidDismiss, children, customClass }) => {
  return (
    <IonModal isOpen={isOpen} onDidDismiss={onDidDismiss} className={`modal-small ${customClass}`}>
      <div className="ion-padding">
        { children }
      </div>
    </IonModal>
  )
}

export default ModalSmall
