import { IonAvatar, IonButton, IonCard, IonIcon, IonImg, IonItem, IonLabel, IonList, useIonToast } from '@ionic/react'
import { logOutSharp } from 'ionicons/icons'
import { useState } from 'react'
import googleIcon from '../../../../assets/google-icon.png'
import instagramIcon from '../../../../assets/instagram-icon.png'
import { TitleForm } from '../../../../components/Atoms/TitleForm'

interface Account {
  id: number
  name: string
  icon: string
}

const ListOfConnectedAccount: React.FC = () => {
  const [present] = useIonToast()
  const [accountConnected, setAccountConnected] = useState<Account[]>([
    {
      id: 1,
      name: 'Google',
      icon: googleIcon
    },
    {
      id: 2,
      name: 'Instagram',
      icon: instagramIcon
    }
  ])

  const handleDisconnect = (account: Account) => {
    setAccountConnected(prevAccounts => {
      return prevAccounts.filter(ele => ele.id !== account.id)
    })
    present({
      message: 'Desconexión exitosa',
      duration: 2500,
      position: 'bottom',
      color: 'success'
    })
  }

  return (
		<>
      <IonCard className="box ion-padding">
        <div>
          <TitleForm title="Cuentas conectadas" customClass="ion-margin-start" />
          <IonList>
            {accountConnected.map(account =>
              <IonItem key={account.id} onClick={() => { handleDisconnect(account) }}>
                <IonAvatar slot="start" className="flex ion-justify-content-center ion-align-items-center">
                  <IonImg alt={account.name} src={account.icon} style={{ width: '25px', height: '25px' }} />
                </IonAvatar>
                <IonLabel>{account.name}</IonLabel>
                <IonButton color="danger" fill="clear">Desconectar<IonIcon slot="end" icon={logOutSharp}></IonIcon></IonButton>
              </IonItem>
            )}
          </IonList>
        </div>
      </IonCard>
		</>
  )
}

export default ListOfConnectedAccount
