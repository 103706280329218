import { IonCard, IonIcon, IonItem, IonLabel, IonList, IonText } from '@ionic/react'
import { TitleForm } from '../../../../components/Atoms/TitleForm'
import ModalSmall from '../../../../components/Organisms/ModalSmall/ModalSmall'
import { useState } from 'react'
import { addCircle } from 'ionicons/icons'
import { FormSalesTaxes } from './components/FormSalesTaxes/FormSalesTaxes'
import { type SalesTaxes as SalesTaxesType } from '../../../../models/sales-taxes'

interface HandleSalesTaxesParams {
  isEdit: boolean
  itemEdit?: SalesTaxesType
}

const listSalesTaxes: SalesTaxesType[] = [
  {
    id: 1,
    name: 'ITBIS',
    percentage: 18
  }
]

export function SalesTaxes () {
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [itemEdit, setItemEdit] = useState<SalesTaxesType>()

  const handleSalesTaxes = ({ isEdit, itemEdit }: HandleSalesTaxesParams) => {
    setIsOpenModal(true)
    setIsEdit(isEdit)
    if (isEdit) setItemEdit(itemEdit)
  }

  return (
		<>
			<IonCard className="box ion-padding">
				<div>
					<TitleForm title={'Impuesto sobre la venta'} customClass="ion-margin-start" />
					<IonList>
						{
							listSalesTaxes.map(sales =>
								<IonItem key={sales.id} button detail={true} onClick={() => { handleSalesTaxes({ isEdit: true, itemEdit: sales }) }}>
									<IonLabel>
										<IonLabel><h2>{ sales.name }</h2></IonLabel>
									</IonLabel>
									<IonLabel slot='end'>{ sales.percentage }%</IonLabel>
								</IonItem>
							)
						}
					</IonList>
				</div>
				<IonItem lines="none">
					<IonIcon icon={addCircle} slot="end" size="large" onClick={() => { handleSalesTaxes({ isEdit: false }) }}/>
				</IonItem>
				<div className="ion-padding ion-text-center">
					<IonText>Impuesto sobre la venta se calculará automaticamente para cada compra</IonText>
				</div>
			</IonCard>
			<ModalSmall isOpen={isOpenModal} onDidDismiss={() => { setIsOpenModal(false) }}>
				<FormSalesTaxes isEdit={isEdit} itemEdit={itemEdit} closeModal={() => { setIsOpenModal(false) }} />
			</ModalSmall>
		</>
  )
}
