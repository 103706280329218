import { IonButton, IonCol, IonGrid, IonRow, IonText, useIonToast } from '@ionic/react'
import { useState } from 'react'
import { Input } from '../../../components/Atoms/Input/Input'
import { TitleForm } from '../../../components/Atoms/TitleForm'
import { type Identity } from '../../../models/identity'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { addIdentitiesCommerce, editIdentitiesCommerce } from '../../../store/states/commerce'
import { addIdentities, editIdentities } from '../../../store/states/user'

export interface Props {
  isEdit: boolean
  itemEdit?: Identity
  closeModal: () => void
}

const FormIdentityDocuments: React.FC<Props> = ({ isEdit, itemEdit, closeModal }) => {
  const dispatch = useAppDispatch()
  const roleUser = useAppSelector(store => store.roleUserActive)
  const [present] = useIonToast()
  const initialValue = isEdit ? { ...itemEdit, type: itemEdit?.type, document: itemEdit?.document } : { type: '', document: '' }
  const [document, setDocument] = useState<Identity>(initialValue as Identity)

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    closeModal()

    // run services edit here!
    if (roleUser.id.toString() === '1') {
      if (isEdit) {
        dispatch(editIdentities(document))
      } else {
        dispatch(addIdentities({ ...document, id: Math.ceil(Math.random() * 1000) }))
      }
    } else {
      if (isEdit) {
        dispatch(editIdentitiesCommerce(document))
      } else {
        dispatch(addIdentitiesCommerce({ ...document, id: Math.ceil(Math.random() * 1000) }))
      }
    }
    present({
      message: isEdit ? 'Datos guardados' : 'Dato creado',
      duration: 2500,
      position: 'bottom',
      color: 'success'
    })
  }

  const handleChange = (e: any) => {
    setDocument(prevValue => ({
      ...prevValue,
      [e.target.name]: e.target.value
    }))
  }

  return (
		<div className="box ion-padding">
			<form className="form" onSubmit={e => { handleSubmit(e) }}>
				<TitleForm title={`${isEdit ? 'Editar' : 'Agregar'} documento de identidad`} customClass="ion-padding-start" />
				<IonGrid>
          <IonRow>
            <IonCol size="4">
							<Input label="Tipo DNI" type="text" name="type" value={document.type} onChange={e => { handleChange(e) }}/>
            </IonCol>
            <IonCol size="8">
							<Input label="Documento" type="text" name="document" value={document.document} onChange={e => { handleChange(e) }}/>
            </IonCol>
          </IonRow>
        </IonGrid>
				<div className="ion-padding">
					<IonText>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</IonText>
				</div>
				<div className="ion-text-end">
					<IonButton color="secondary" shape="round" type="submit">{isEdit ? 'Guardar' : 'Crear'}</IonButton>
					<IonButton color="secondary" shape="round" fill="clear" type="button" onClick={() => { closeModal() }}>Cancelar</IonButton>
				</div>
			</form>
		</div>
  )
}

export default FormIdentityDocuments
