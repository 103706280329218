import { IonButton, IonIcon, IonItem, IonLabel } from '@ionic/react'
import { closeOutline } from 'ionicons/icons'
import { Input } from '../../../../components/Atoms/Input/Input'

interface Props {
  setIsOpenModalPaymentPersonalizeEnterAmount: React.Dispatch<React.SetStateAction<boolean>>
  setIsOpenModalSuccessPayment: React.Dispatch<React.SetStateAction<boolean>>
}

export function ModalPaymentPeronalizeEnterAmount ({ setIsOpenModalPaymentPersonalizeEnterAmount, setIsOpenModalSuccessPayment }: Props) {
  const handleFinish = () => {
    setIsOpenModalPaymentPersonalizeEnterAmount(false)
    setIsOpenModalSuccessPayment(true)
  }

  return (
		<div style={{ minWidth: '100%' }}>
			<IonItem className='ion-no-padding ion-no-margin' lines='none'>
        <IonIcon icon={closeOutline} size='small' slot='start' onClick={() => { setIsOpenModalPaymentPersonalizeEnterAmount(false) }} className='ion-padding-start' />
      </IonItem>
			<div>
				<IonItem className='ion-no-padding ion-no-margin ion-text-center ion-padding-start' lines='none'>
					<IonLabel>
						 Total de $151515 dividido en partes iguales
					</IonLabel>
				</IonItem>
				<div>
					<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '0', padding: '0 1rem' }}>
						<div style={{ flex: 1 }}>
							<Input noSpace label="Cantidad de devisión" type="number" name="ammout" value={'1357.00'} onChange={e => { console.log(e) }} />
						</div>
						<IonButton color="secondary" expand="block" fill='outline' onClick={() => { handleFinish() }} style={{ minHeight: '100%' }}>Continuar</IonButton>
					</div>
				</div>
			</div>
    </div>
  )
}
