import { IonAvatar, IonContent, IonFooter, IonHeader, IonIcon, IonImg, IonItem, IonLabel, IonList, IonMenu, IonMenuToggle, IonNote, IonText, IonToggle, IonToolbar } from '@ionic/react'
import { logOutSharp, moonSharp, sunnySharp } from 'ionicons/icons'
// import { card, bagCheck, cube, wallet, fileTrayFull, gitMerge, logOutSharp, moonSharp, phonePortrait, receipt, sunnySharp } from 'ionicons/icons'
import { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { saveRolUserActive } from '../../../store/states/roleUserActive'
import { clearUser } from '../../../store/states/user'
import { changeTheme } from '../../../store/states/userTheme'
import { createUserAvatarDefault } from '../../../utilities/utilities'
import './Menu.css'

// interface AppPagesType {
//   title: string
//   url: string
//   routePrivate?: boolean
//   mdIcon: string
// }

export const Menu = () => {
  const user = useAppSelector(store => store.user)
  const isMenuSmall = useAppSelector(store => store.toggleMenu)
  const roleUser = useAppSelector(store => store.roleUserActive)
  const dispatch = useAppDispatch()
  const history = useHistory()
  const location = useLocation()
  const isDarkMode = useAppSelector(store => store.userDarkmode)
  const isLogged = Boolean(user.firstName)
  const mainMenu = useAppSelector(store => store.mainMenu)
  const mainMenuActive = mainMenu.filter(item => item.show)

  // const conditionShowItemPrivate = false

  // const appPages: AppPagesType[] = [
  //   // {
  //   //   title: 'Dashboard',
  //   //   url: '/dashboard',
  //   //   mdIcon: pieChartSharp
  //   // },
  //   {
  //     title: 'Cobro',
  //     url: '/punto-de-venta',
  //     mdIcon: card
  //   },
  //   {
  //     title: 'CheckOut',
  //     url: '/checkout',
  //     mdIcon: bagCheck
  //   },
  //   {
  //     title: 'Productos',
  //     url: '/productos',
  //     mdIcon: cube
  //   },
  //   {
  //     title: 'Pedidos',
  //     url: '/pedidos',
  //     mdIcon: cube
  //   },
  //   {
  //     title: 'Actividad',
  //     url: '/actividad',
  //     mdIcon: phonePortrait
  //   },
  //   {
  //     title: 'Reporte',
  //     url: '/reporte',
  //     mdIcon: receipt
  //   }
  //   // {
  //   //   title: 'Dispositivos',
  //   //   url: '/dispositivos',
  //   //   routePrivate: true,
  //   //   mdIcon: fileTrayFull
  //   // },
  //   // {
  //   //   title: 'Retiro',
  //   //   url: '/retiro',
  //   //   routePrivate: true,
  //   //   mdIcon: wallet
  //   // },
  //   // {
  //   //   title: 'Complementos',
  //   //   url: '/complementos',
  //   //   routePrivate: true,
  //   //   mdIcon: gitMerge
  //   // }
  // ]

  useEffect(() => {
    isDarkMode && document.body.classList.add('dark')
    Boolean(isMenuSmall) && document.body.classList.add('menu-small')
  }, [isDarkMode, isMenuSmall])

  // switch type user to natural
  const handleChangeTypeUser = () => {
    history.replace('/perfil', { replace: true })
    if (roleUser.id.toString() === '1') return
    dispatch(saveRolUserActive({ id: '1', type: 'natural' }))
  }

  // Cerrar sesion - Aqui logica para loggout
  const handleLoggout = async () => {
    history.replace('/login', { replace: true })
    dispatch(clearUser())
  }

  // Change theme here
  const handleChangeTheme = ({ e }: { e: any | boolean }) => {
    document.body.classList.toggle('dark')
    dispatch(changeTheme(e?.detail?.checked ?? e))
  }

  return (
    <IonMenu contentId="main" type="overlay" disabled={!isLogged}>
      <IonContent>
        <IonList className="inbox-list">
          <IonHeader className="ion-no-border ion-margin-bottom header-menu-container">
            <IonItem lines="none" className="header-menu">
              <IonAvatar slot="start" style={isMenuSmall ? { minWidth: '40px', minHeight: '40px', objectFit: 'contain', position: 'relative', right: '5px' } : { position: 'relative', right: '5px' }}>
                <IonImg alt={user.firstName} src={user.avatar ?? createUserAvatarDefault(user.firstName)} onClick={() => { handleChangeTypeUser() }}/>
              </IonAvatar>
              <IonLabel>
                <p><IonNote>${user.username}</IonNote></p>
                <IonMenuToggle auto-hide="false"> <p className="header-menu-link" onClick={() => { handleChangeTypeUser() }}><IonNote>Configuración</IonNote></p></IonMenuToggle>
              </IonLabel>
            </IonItem>
          </IonHeader>
          <div className="header-menu-list">
            {mainMenuActive.map((appPage, index) => {
              return (
								 <IonMenuToggle key={index} autoHide={false}>
                    <IonItem className={location.pathname === appPage.url ? '' : ''} routerLink={appPage.url} routerDirection="none" lines="none" detail={false}>
                      <IonIcon slot="start" icon={appPage.mdIcon} style={isMenuSmall ? { minWidth: '90px', position: 'relative', right: '33px' } : {}} />
                      {!isMenuSmall && <IonLabel>{appPage.title}</IonLabel>}
                    </IonItem>
                  </IonMenuToggle>
              )
            })}
          </div>
        </IonList>
      </IonContent>

      <IonFooter className="ion-no-border">
        <IonToolbar>
          <IonItem lines="none">
            <IonIcon onClick={() => { isMenuSmall && handleChangeTheme({ e: !isDarkMode }) }} style={ isMenuSmall ? { minWidth: '90px', position: 'relative', right: '22px' } : { position: 'relative', left: '10px' }} slot="start" icon={isDarkMode ? moonSharp : sunnySharp} ></IonIcon>
            {!isMenuSmall && <IonLabel>{isDarkMode ? 'Modo oscuro' : 'Modo claro'}</IonLabel>}
            {!isMenuSmall && <IonToggle color="secondary" slot="end" checked={isDarkMode} onIonChange={e => { handleChangeTheme({ e }) }}/>}
          </IonItem>
          <IonMenuToggle autoHide={false}>
            <IonItem routerDirection="none" lines="none" detail={false} button onClick={handleLoggout}>
              <IonIcon slot="start" icon={logOutSharp} style={ isMenuSmall ? { minWidth: '90px', position: 'relative', right: '22px' } : { position: 'relative', left: '10px' }} color="danger" />{!isMenuSmall && 'Cerrar Sesión'}
            </IonItem>
          </IonMenuToggle>
        </IonToolbar>
        <IonToolbar>
         {!isMenuSmall && <IonText color="medium" className="ion-padding-start">Version 0.0.1</IonText>}
        </IonToolbar>
      </IonFooter>
    </IonMenu>
  )
}
