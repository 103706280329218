import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { UserSegurity } from '../../models/user-segurity'
import { getLocalStorage, setLocalStorage } from '../../utilities/localStorage.utility'

const initialState: UserSegurity = {
  chekedDoudleSegurity: false,
  touchId: true,
  faceId: true,
  loginSessionExt: true
}

export const userSeguritySlice = createSlice({
  name: 'user-segurity',
  initialState: getLocalStorage('user-segurity') ? JSON.parse(getLocalStorage('user-segurity') as string) : initialState,
  reducers: {
    saveUserSegurity (state: UserSegurity, action: PayloadAction<UserSegurity>) {
      setLocalStorage('user-segurity', action.payload)
      return { ...state, ...action.payload }
    },
    editChekedDoudleSegurity (state: UserSegurity, action: PayloadAction<boolean>) {
      const result = { ...state, chekedDoudleSegurity: action.payload }
      setLocalStorage('user-segurity', result)
      return result
    },
    editTouchId (state: UserSegurity, action: PayloadAction<boolean>) {
      const result = { ...state, touchId: action.payload }
      setLocalStorage('user-segurity', result)
      return result
    },
    editFaceId (state: UserSegurity, action: PayloadAction<boolean>) {
      const result = { ...state, faceId: action.payload }
      setLocalStorage('user-segurity', result)
      return result
    },
    editLoginSessionExt (state: UserSegurity, action: PayloadAction<boolean>) {
      const result = { ...state, loginSessionExt: action.payload }
      setLocalStorage('user-segurity', result)
      return result
    }
  }
})

export const {
  saveUserSegurity,
  editChekedDoudleSegurity,
  editTouchId,
  editFaceId,
  editLoginSessionExt
} = userSeguritySlice.actions
export default userSeguritySlice.reducer
