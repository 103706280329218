import { IonButton, IonCol, IonGrid, IonRow, useIonToast } from '@ionic/react'
import { useState } from 'react'
import { Input } from '../../../../components/Atoms/Input/Input'
import { TitleForm } from '../../../../components/Atoms/TitleForm'
import type { AccountBank } from '../../../../models/user-banks'
import { useAppDispatch } from '../../../../store/hooks'
import { addBankAccount, editBankAccount } from '../../../../store/states/userBanks'
import { generateIconBank } from '../../../../utilities/generateImageOfBank'

export interface Props {
  isEdit: boolean
  itemEdit?: AccountBank
  closeModal: () => void
}

const FormBanksAccount: React.FC<Props> = ({ isEdit, itemEdit, closeModal }) => {
  const [present] = useIonToast()
  const initialValue = isEdit
    ? {
        ...itemEdit,
        name: itemEdit?.name,
        accountNumber: itemEdit?.accountNumber,
        documentUserType: itemEdit?.documentUserType,
        documentUser: itemEdit?.documentUser
      }
    : { name: '', accountNumber: '', documentUserType: '', documentUser: '' }
  const [accountBank, setAccountBank] = useState<AccountBank>(initialValue as AccountBank)
  const dispatch = useAppDispatch()

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    const key: any = accountBank.accountNumber.slice(0, 4).toString()
    e.preventDefault()
    closeModal()

    // run services edit here!
    if (isEdit) {
      dispatch(editBankAccount(accountBank))
    } else {
      dispatch(addBankAccount({ ...accountBank, icon: generateIconBank(key), id: Math.ceil(Math.random() * 1000) }))
    }
    present({
      message: isEdit ? 'Datos guardados' : 'Dato creado',
      duration: 2500,
      position: 'bottom',
      color: 'success'
    })
  }

  const handleChange = (e: any) => {
    setAccountBank(prevValue => ({
      ...prevValue,
      [e.target.name]: e.target.value
    }))
  }

  return (
		<div className="box ion-padding">
			<form className="form" onSubmit={e => { handleSubmit(e) }}>
				<TitleForm title={`${isEdit ? 'Edición' : 'Creación'} de cuenta`} />
				<Input label="Nombre del banco" type="text" name="name" value={accountBank.name} onChange={e => { handleChange(e) }}/>
				<Input label="Cuenta bancaria" type="text" name="accountNumber" value={accountBank.accountNumber} onChange={e => { handleChange(e) }}/>
				<IonGrid className="ion-no-padding">
          <IonRow>
            <IonCol size="4" className="ion-padding-end">
							<Input label="Tipo" type="text" name="documentUserType" value={accountBank.documentUserType} onChange={e => { handleChange(e) }}/>
            </IonCol>
            <IonCol size="8">
							<Input label="Documento" type="text" name="documentUser" value={accountBank.documentUser} onChange={e => { handleChange(e) }}/>
            </IonCol>
          </IonRow>
        </IonGrid>
				<div className="ion-text-end">
					<IonButton color="secondary" shape="round" type="submit">{isEdit ? 'Guardar' : 'Crear'}</IonButton>
					<IonButton color="secondary" shape="round" fill="clear" type="button" onClick={() => { closeModal() }}>Cancelar</IonButton>
				</div>
			</form>
		</div>
  )
}

export default FormBanksAccount
