import { IonAvatar, IonButton, IonCard, IonImg, IonItem, IonLabel, IonList, IonToggle, useIonToast } from '@ionic/react'
import { useState } from 'react'
import { useHistory } from 'react-router'
import flagRD from '../../../../assets/flag-dominican-republic.svg'
import flage4cash from '../../../../assets/flag-e4cash.svg'
import flagVenezuela from '../../../../assets/flag-venezuela.svg'
import { TitleForm } from '../../../../components/Atoms/TitleForm'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import { editGivenCurrency } from '../../../../store/states/userPreference'

const ListGivenCurrency: React.FC = () => {
  const [present] = useIonToast()
  const userPreference = useAppSelector(store => store.userPreference)
  const dispatch = useAppDispatch()
  const history = useHistory()
  const [checked, setCheked] = useState({
    dop: userPreference.givenCurrency.dop,
    bs: userPreference.givenCurrency.bs,
    e4coin: userPreference.givenCurrency.e4coin
  })

  const handleSubmit = () => {
    dispatch(editGivenCurrency(checked))
    present({
      message: 'Cambio guardado exitosamente',
      duration: 2500,
      position: 'bottom',
      color: 'success'
    })
  }

  const onChange = (e: any) => {
    setCheked(prevValue => ({
      ...prevValue,
      [e.target.name]: e.detail.checked
    }))
  }

  return (
		<>
			<IonCard className="box ion-padding">
				<div>
					<TitleForm title="Moneda determinada" customClass="ion-margin-start" />
					<IonList>
						<IonItem>
							<IonAvatar slot="start" className="flex ion-justify-content-center ion-align-items-center">
								<IonImg alt="rd" src={flagRD} style={{ width: '30px', height: '30px' }} />
							</IonAvatar>
							<IonLabel>DOP</IonLabel>
							<IonToggle name="dop" color="secondary" checked={checked.dop} slot="end" onIonChange={e => { onChange(e) }} />
						</IonItem>
						<IonItem>
							<IonAvatar slot="start" className="flex ion-justify-content-center ion-align-items-center">
								<IonImg alt="Venezuela" src={flagVenezuela} style={{ width: '30px', height: '30px' }} />
							</IonAvatar>
							<IonLabel>BS</IonLabel>
							<IonToggle name="bs" color="secondary" checked={checked.bs} slot="end" onIonChange={e => { onChange(e) }} />
						</IonItem>
						<IonItem>
							<IonAvatar slot="start" className="flex ion-justify-content-center ion-align-items-center">
								<IonImg alt="e4cash" src={flage4cash} style={{ width: '30px', height: '30px' }} />
							</IonAvatar>
							<IonLabel>e4Coin</IonLabel>
							<IonToggle name="e4coin" color="secondary" checked={checked.e4coin} slot="end" onIonChange={e => { onChange(e) }} />
						</IonItem>
					</IonList>
					<div className="ion-text-end">
						<IonButton color="secondary" shape="round" type="submit" onClick={() => { handleSubmit() }}>Guardar</IonButton>
						<IonButton color="secondary" shape="round" fill="clear" type="button" onClick={() => { history.goBack() }}>Cancelar</IonButton>
					</div>
				</div>
			</IonCard>
		</>
  )
}

export default ListGivenCurrency
