import { IonCard, IonIcon, IonItem, IonLabel, IonList } from '@ionic/react'
import { addCircle } from 'ionicons/icons'
import { useState } from 'react'
import { TitleForm } from '../../../components/Atoms/TitleForm'
import ModalSmall from '../../../components/Organisms/ModalSmall/ModalSmall'
import { type Address } from '../../../models/address'
import { FormAddress } from '../FormAddress'

export interface Props {
  title: string
  list: Address[]
}

interface HandleEmailParams {
  isEdit: boolean
  itemEdit?: Address
}

const ListOfAddress: React.FC<Props> = ({ title, list }) => {
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [itemEdit, setItemEdit] = useState<Address>()

  const handleEmail = ({ isEdit, itemEdit }: HandleEmailParams) => {
    setIsOpenModal(true)
    setIsEdit(isEdit)
    if (isEdit) setItemEdit(itemEdit)
  }

  return (
		<>
		<IonCard className="box ion-padding">
      <div>
        <TitleForm title={title} customClass="ion-margin-start" />
				<IonList>
					{list.map(address =>
						<IonItem key={address.id} button={true} detail={true} onClick={() => { handleEmail({ isEdit: true, itemEdit: address }) }}>
							<IonLabel>
								<h2>{ address.city } - { address.municipality }</h2>
								<p>{ address.address }</p>
							</IonLabel>
						</IonItem>
					)}
				</IonList>
      </div>
			<IonItem lines="none">
				<IonIcon icon={addCircle} slot="end" size="large" onClick={() => { handleEmail({ isEdit: false }) }}/>
			</IonItem>
    </IonCard>
		<ModalSmall isOpen={isOpenModal} onDidDismiss={() => { setIsOpenModal(false) }}>
			<FormAddress isEdit={isEdit} itemEdit={itemEdit} closeModal={() => { setIsOpenModal(false) }} />
		</ModalSmall>
		</>
  )
}

export default ListOfAddress
