import { IonCard, IonIcon, IonItem, IonList } from '@ionic/react'
import { addCircle } from 'ionicons/icons'
import { useState } from 'react'
import { TitleForm } from '../../../components/Atoms/TitleForm'
import ModalSmall from '../../../components/Organisms/ModalSmall/ModalSmall'
import { type Identity } from '../../../models/identity'
import { FormIdentityDocuments } from '../FormIdentityDocuments'

export interface Props {
  title: string
  list: Identity[]
}

interface HandleDocumentsParams {
  isEdit: boolean
  itemEdit?: Identity
}

const ListOfIdentityDocuments: React.FC<Props> = ({ title, list }) => {
  const [isOpenModalCI, setIsOpenModalCI] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [itemEdit, setItemEdit] = useState<Identity>()

  const handleDocuments = ({ isEdit, itemEdit }: HandleDocumentsParams) => {
    setIsOpenModalCI(true)
    setIsEdit(isEdit)
    if (isEdit) setItemEdit(itemEdit)
  }

  return (
		<>
		<IonCard className="box ion-padding">
      <div>
        <TitleForm title={title} customClass="ion-margin-start" />
				<IonList>
					{list.map(identity =>
						<IonItem key={identity.id} button={true} detail={true} onClick={() => { handleDocuments({ isEdit: true, itemEdit: identity }) }}>{identity.type}-{identity.document}</IonItem>
					)}
				</IonList>
      </div>
			<IonItem lines="none">
				<IonIcon icon={addCircle} slot="end" size="large" onClick={() => { handleDocuments({ isEdit: false }) }}/>
			</IonItem>
    </IonCard>
		<ModalSmall isOpen={isOpenModalCI} onDidDismiss={() => { setIsOpenModalCI(false) }}>
			<FormIdentityDocuments isEdit={isEdit} itemEdit={itemEdit} closeModal={() => { setIsOpenModalCI(false) }} />
		</ModalSmall>
		</>
  )
}

export default ListOfIdentityDocuments
