import { GoogleMap } from '@capacitor/google-maps'
import { IonButton, IonCol, IonGrid, IonRow, IonSkeletonText, IonText, useIonToast } from '@ionic/react'
import { useEffect, useRef, useState } from 'react'
import { Input } from '../../../components/Atoms/Input/Input'
import { TitleForm } from '../../../components/Atoms/TitleForm'
import { useGetCurrentPosition } from '../../../hooks'
import { type Address } from '../../../models/address'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { addAddressCommerce, editAddressCommerce } from '../../../store/states/commerce'
import { addAddress, editAddress } from '../../../store/states/user'

export interface Props {
  isEdit: boolean
  itemEdit?: Address
  closeModal: () => void
}

const FormAddress: React.FC<Props> = ({ isEdit, itemEdit, closeModal }) => {
  const dispatch = useAppDispatch()
  const roleUser = useAppSelector(store => store.roleUserActive)
  const [present] = useIonToast()
  const initialValue = isEdit ? { id: itemEdit?.id, city: itemEdit?.city, municipality: itemEdit?.municipality, address: itemEdit?.address } : { city: '', municipality: '', address: '' }
  const [address, setAddress] = useState<Address>(initialValue as Address)

  const mapRef = useRef<HTMLElement>(null)
  const { loadingCurrentLocation, currentLocation } = useGetCurrentPosition()

  useEffect(() => {
    const createMap = async () => {
      if (!mapRef.current) return

      if (!loadingCurrentLocation) {
        const map = await GoogleMap.create({
          id: 'google-map',
          element: mapRef.current,
          apiKey: process.env.REACT_APP_GOOGLE_API_KEY as string,
          config: {
            center: {
              lat: currentLocation?.coords?.latitude ?? 99.500000,
              lng: currentLocation?.coords?.longitude ?? -11.916664
            },
            zoom: 12
          }
        })

        // Add a marker current to the map
        await map.addMarker({
          coordinate: {
            lat: currentLocation?.coords?.latitude ?? 99.500000,
            lng: currentLocation?.coords?.longitude ?? -11.916664
          },
          iconUrl: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS1zICi8wZq54ksui8lf6kDPs3ft1FA9Av6bQ7z5T5fKQ&s',
          iconSize: {
            width: 35,
            height: 35
          },
          title: 'Location'
        })

        // Handle marker click
        await map.setOnMarkerClickListener(async (event) => {
          await map.removeMarker(event.markerId)
        })

        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        await map.setOnMapClickListener(async (event) => {
          await map.addMarker({
            coordinate: {
              lat: event.latitude,
              lng: event.longitude
            },
            iconUrl: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS1zICi8wZq54ksui8lf6kDPs3ft1FA9Av6bQ7z5T5fKQ&s',
            iconSize: {
              width: 35,
              height: 35
            },
            title: 'Nueva con click'
          })
        })
      }
    }

    createMap()
  }, [currentLocation?.coords?.latitude, currentLocation?.coords?.longitude, loadingCurrentLocation])

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    closeModal()

    // run services edit here!
    if (roleUser.id.toString() === '1') {
      if (isEdit) {
        dispatch(editAddress(address))
      } else {
        dispatch(addAddress({ ...address, id: Math.ceil(Math.random() * 1000) }))
      }
    } else {
      if (isEdit) {
        dispatch(editAddressCommerce(address))
      } else {
        dispatch(addAddressCommerce({ ...address, id: Math.ceil(Math.random() * 1000) }))
      }
    }
    present({
      message: isEdit ? 'Datos guardados' : 'Dato creado',
      duration: 2500,
      position: 'bottom',
      color: 'success'
    })
  }

  const handleChange = (e: any) => {
    setAddress(prevValue => ({
      ...prevValue,
      [e.target.name]: e.target.value
    }))
  }

  return (
		<div className="box ion-padding">
			<form className="form" onSubmit={e => { handleSubmit(e) }}>
				<TitleForm title={`${isEdit ? 'Editar' : 'Agregar'} ubicación`} customClass="ion-margin-start" />
				<IonGrid>
          <IonRow>
            <IonCol>
							<Input label="Cuidad" type="text" name="city" value={address.city} onChange={e => { handleChange(e) }}/>
            </IonCol>
            <IonCol>
							<Input label="Municipio" type="text" name="municipality" value={address.municipality} onChange={e => { handleChange(e) }}/>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
							<Input label="Dirección" type="text" name="address" value={address.address} onChange={e => { handleChange(e) }}/>
            </IonCol>
          </IonRow>
        </IonGrid>

				<div className="ion-padding">
					{ loadingCurrentLocation
					  ? <IonSkeletonText animated={true} style={{ width: '100%', height: 200, borderRadius: '10px' }}></IonSkeletonText>
					  :	<capacitor-google-map ref={mapRef} style={{
					    display: 'inline-block',
					    width: '100%',
					    height: 200,
					   	borderRadius: '10px'
					  }}>
						</capacitor-google-map>}
				</div>

				<div className="ion-padding">
					<IonText>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</IonText>
				</div>

				<div className="ion-text-end">
					<IonButton color="secondary" shape="round" type="submit">{isEdit ? 'Guardar' : 'Crear'}</IonButton>
					<IonButton color="secondary" shape="round" fill="clear" type="button" onClick={() => { closeModal() }}>Cancelar</IonButton>
				</div>
			</form>
		</div>
  )
}

export default FormAddress
