import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { type CustomGridState } from '../../models/customGrid'

const initialState: CustomGridState = {
  gridSize: 0
}

export const customGridSlice = createSlice({
  name: 'customGrid',
  initialState,
  reducers: {
    saveCustomGrid: (state, action: PayloadAction<number>) => {
      state.gridSize = action.payload
    }
  }
})

export const { saveCustomGrid } = customGridSlice.actions
export default customGridSlice.reducer
