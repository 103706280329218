import { IonButton, IonCol, IonGrid, IonItem, IonLabel, IonRow, IonSelect, IonSelectOption, useIonToast } from '@ionic/react'
import { useState } from 'react'
import { Input } from '../../../../components/Atoms/Input/Input'
import { TitleForm } from '../../../../components/Atoms/TitleForm'
import type { Employee } from '../../../../models'

export interface Props {
  itemEdit?: Employee
  closeModal: () => void
}

const FormEditEmployee: React.FC<Props> = ({ itemEdit, closeModal }) => {
  const [present] = useIonToast()
  const initialValue = {
    id: itemEdit?.id,
    firstName: itemEdit?.firstName,
    lastName: itemEdit?.lastName,
    typeDocument: itemEdit?.typeDocument,
    numberDocument: itemEdit?.numberDocument,
    jobTitle: itemEdit?.jobTitle,
    branchOffice: itemEdit?.branchOffice,
    profile: {
      id: itemEdit?.profile.id,
      title: itemEdit?.profile.title
    }
  }
  const [employee, setEmployee] = useState<Employee>(initialValue as Employee)

  const profilesEmployees = [ // change for employees of state
    {
      id: 1,
      title: 'Super Administrador'
    },
    {
      id: 2,
      title: 'Administrador'
    }
  ]

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    closeModal()

    // run services edit here!
    present({
      message: 'Datos guardados',
      duration: 2500,
      position: 'bottom',
      color: 'success'
    })
  }

  const handleChange = (e: any) => {
    setEmployee(prevValue => ({
      ...prevValue,
      [e.target.name]: {
        id: e.detail.value,
        title: profilesEmployees.find(profile => profile.id === e.detail.value)?.title
      }
    }))
  }

  return (
		<div className="box ion-padding">
			<form className="form" onSubmit={e => { handleSubmit(e) }}>
				<TitleForm title="Empleado detalle" customClass="ion-margin-start" />
				<IonGrid>
          <IonRow>
            <IonCol>
							<Input disabled={true} label="Nombre" type="text" name="firstName" value={employee.firstName} />
            </IonCol>
            <IonCol>
							<Input disabled={true} label="Apellido" type="text" name="lastName" value={employee.lastName}/>
            </IonCol>
          </IonRow>
					<IonRow>
            <IonCol size="4">
							<Input disabled={true} label="Tipo" type="text" name="typeDocument" value={employee.typeDocument} />
            </IonCol>
            <IonCol size="8">
							<Input disabled={true} label="Documento" type="text" name="numberDocument" value={employee.numberDocument} />
            </IonCol>
          </IonRow>
					<IonRow>
            <IonCol>
							<Input disabled={true} label="Cargo" type="text" name="jobTitle" value={employee.jobTitle} />
            </IonCol>
            <IonCol>
							<Input disabled={true} label="Sucursal" type="text" name="lastName" value={employee.branchOffice} />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
							<IonItem className="input-item">
								<IonLabel position="floating">Perfil</IonLabel>
								<IonSelect value={employee.profile.id} name="profile" onIonChange={e => { handleChange(e) }}>
									{profilesEmployees.map(profile =>
										<IonSelectOption key={profile.id} value={profile.id}>{profile.title}</IonSelectOption>
									)}
								</IonSelect>
							</IonItem>
            </IonCol>
          </IonRow>
        </IonGrid>
				<div className="ion-text-end">
					<IonButton color="secondary" shape="round" type="submit">Guardar</IonButton>
					<IonButton color="secondary" shape="round" fill="clear" type="button" onClick={() => { closeModal() }}>Cancelar</IonButton>
				</div>
			</form>
		</div>
  )
}

export default FormEditEmployee
