import { IonButton, IonItem, IonLabel, IonList, IonModal, IonToggle } from '@ionic/react'
import React, { useEffect, useRef, useState } from 'react'
import { TitleForm } from '../../../../../../components/Atoms/TitleForm'
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks'
import { editNotificationsSms } from '../../../../../../store/states/userPreference'

const ListToggleNotificationsSms: React.FC = () => {
  const userPreference = useAppSelector(store => store.userPreference)
  const dispatch = useAppDispatch()
  const modal = useRef<HTMLIonModalElement>(null)
  const [checked, setCheked] = useState({
    login: userPreference.notifications.sms.login,
    receiveOfMoney: userPreference.notifications.sms.receiveOfMoney,
    sendingOfMoney: userPreference.notifications.sms.sendingOfMoney
  })

  useEffect(() => {
    setCheked({
      login: userPreference.notifications.sms.login,
      receiveOfMoney: userPreference.notifications.sms.receiveOfMoney,
      sendingOfMoney: userPreference.notifications.sms.sendingOfMoney
    })
  }, [userPreference])

  const onChange = (e: any) => {
    setCheked(prevValue => ({
      ...prevValue,
      [e.target.name]: e.detail.checked
    }))
  }

  function dismiss () {
    dispatch(editNotificationsSms(checked))
    modal.current?.dismiss()
  }

  return (
		<IonModal ref={modal} trigger="open-modal-sms" initialBreakpoint={0.75} breakpoints={[0, 0.25, 0.5, 0.75]}>
			<div className="ion-padding">
				<TitleForm title="SMS" customClass="ion-margin-start" />
				<IonList>
					<IonItem>
						<IonLabel>Login</IonLabel>
						<IonToggle color="secondary" name="login" checked={checked.login} slot="end" onIonChange={e => { onChange(e) }}></IonToggle>
					</IonItem>
					<IonItem>
						<IonLabel>Envío de dinero</IonLabel>
						<IonToggle color="secondary" name="sendingOfMoney" checked={checked.sendingOfMoney} slot="end" onIonChange={e => { onChange(e) }}></IonToggle>
					</IonItem>
					<IonItem>
						<IonLabel>Recibir dinero</IonLabel>
						<IonToggle color="secondary" name="receiveOfMoney" checked={checked.receiveOfMoney} slot="end" onIonChange={e => { onChange(e) }}></IonToggle>
					</IonItem>
				</IonList>
				<div className="ion-text-end">
					<IonButton color="secondary" shape="round" type="submit" onClick={() => { dismiss() }}>Guardar</IonButton>
					<IonButton color="secondary" shape="round" fill="clear" type="button" onClick={async () => await modal.current?.dismiss()}>Cancelar</IonButton>
				</div>
			</div>
		</IonModal>
  )
}

export default ListToggleNotificationsSms
