import { IonButton, IonText, useIonToast } from '@ionic/react'
import { useState } from 'react'
import { Input } from '../../../components/Atoms/Input/Input'
import { TitleForm } from '../../../components/Atoms/TitleForm'
import { type SocialMedia } from '../../../models/social-media'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { addSocialMediaCommerce, editSocialMediaCommerce } from '../../../store/states/commerce'
import { addSocialMedia, editSocialMedia } from '../../../store/states/user'

export interface Props {
  isEdit: boolean
  itemEdit?: SocialMedia
  closeModal: () => void
}

const FormSocialMedia: React.FC<Props> = ({ isEdit, itemEdit, closeModal }) => {
  const dispatch = useAppDispatch()
  const roleUser = useAppSelector(store => store.roleUserActive)
  const [present] = useIonToast()
  const initialValue = isEdit
    ? { ...itemEdit, social: itemEdit?.social }
    : { social: '' }
  const [socialmedia, setSocialmedia] = useState<SocialMedia>(initialValue as SocialMedia)

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    closeModal()
    // run services edit here!
    if (roleUser.id.toString() === '1') {
      if (isEdit) {
        dispatch(editSocialMedia(socialmedia))
      } else {
        dispatch(addSocialMedia({ ...socialmedia, id: Math.ceil(Math.random() * 1000) }))
      }
    } else {
      if (isEdit) {
        dispatch(editSocialMediaCommerce(socialmedia))
      } else {
        dispatch(addSocialMediaCommerce({ ...socialmedia, id: Math.ceil(Math.random() * 1000) }))
      }
    }
    present({
      message: isEdit ? 'Datos guardados' : 'Dato creado',
      duration: 2500,
      position: 'bottom',
      color: 'success'
    })
  }

  const handleChange = (e: any) => {
    setSocialmedia(prevValue => ({
      ...prevValue,
      [e.target.name]: e.target.value
    }))
  }

  return (
		<div className="box ion-padding">
			<form className="form" onSubmit={e => { handleSubmit(e) }}>
				<TitleForm title={`${isEdit ? 'Editar' : 'Agregar'} red social`} />
				<Input label="Red social" type="text" name="social" value={socialmedia.social} onChange={e => { handleChange(e) }}/>
				<div className="ion-padding">
					<IonText>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</IonText>
				</div>
				<div className="ion-text-end">
					<IonButton color="secondary" shape="round" type="submit">{isEdit ? 'Guardar' : 'Crear'}</IonButton>
					<IonButton color="secondary" shape="round" fill="clear" type="button" onClick={() => { closeModal() }}>Cancelar</IonButton>
				</div>
			</form>
		</div>
  )
}

export default FormSocialMedia
