import { IonText, IonButton } from '@ionic/react'
import ModalSmall from '../../../../../../components/Organisms/ModalSmall/ModalSmall'
import TitleForm from '../../../../../../components/Atoms/TitleForm/TitleForm'

interface Props {
  openModalDeleteServices: boolean
  setOpenModalDeleteServices: (value: React.SetStateAction<boolean>) => void
  handleDeleteService: () => void
}

export function ModalDeleteServiceItem ({ openModalDeleteServices, setOpenModalDeleteServices, handleDeleteService }: Props) {
  return (
    <ModalSmall isOpen={openModalDeleteServices} onDidDismiss={() => { setOpenModalDeleteServices(false) }}>
      <div>
        <TitleForm title="Eliminar servicio" customClass="ion-margin-start" />
        <div className="ion-padding">
          <IonText>Seguro que  desea eliminar el sericio</IonText>
        </div>
        <div className="ion-text-end">
          <IonButton color="secondary" shape="round" onClick={() => { handleDeleteService() }}>Si, eliminar</IonButton>
          <IonButton color="secondary" shape="round" fill="clear" onClick={() => { setOpenModalDeleteServices(false) }}>Cancelar</IonButton>
        </div>
      </div>
    </ModalSmall>
  )
}
