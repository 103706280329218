import { IonCard, IonCol, IonLabel, IonText } from '@ionic/react'
import { LineSeparator } from '../../../../components/Atoms/LineSeparator/LineSeparator'
import { TitleForm } from '../../../../components/Atoms/TitleForm'
import { Chart } from '../Chart/Chart'
import { HeaderReports } from '../HeaderReports/HeaderReports'

export function SalesSummary () {
  return (
    <IonCol size="12" size-md="8" size-lg="8" size-xl="9">
      <IonCard className="ion-padding">
        <HeaderReports />
        <div style={{ minHeight: '380px', overflow: 'auto' }}>
          <TitleForm title="Overview" customClass="ion-padding-horizontal ion-margin-verticcal" />
          <div className="flex ion-justify-content-between ion-align-items-center ion-padding">
            <div>
              <h2 className="ion-text-start ion-no-margin">$33.07</h2>
              <IonLabel style={{ fontSize: '14px' }}>
                <IonText color='medium'>Gross Sales</IonText>
              </IonLabel>
            </div>
            <div>
              <h2 className="ion-text-start ion-no-margin">4</h2>
              <IonLabel style={{ fontSize: '14px' }}>
                <IonText color='medium'>Sales</IonText>
              </IonLabel>
            </div>
            <div>
              <h2 className="ion-text-start ion-no-margin">$8.26</h2>
              <IonLabel style={{ fontSize: '14px' }}>
                <IonText color='medium'>Average Sales</IonText>
              </IonLabel>
            </div>
          </div>
          <LineSeparator />
          <TitleForm title="Hourly sales vs. Last monday" customClass="ion-padding-horizontal ion-margin-vertical" />
          <Chart />
          <LineSeparator />
          <TitleForm title="Sales summary" customClass="ion-padding-horizontal" />
          <div className="flex ion-justify-content-between ion-align-items-center">
            <TitleForm title="Gross sales" customClass="ion-padding-horizontal ion-margin-top" />
            <IonLabel style={{ fontSize: '16px' }}>
              <IonText color='medium'>$33.87</IonText>
            </IonLabel>
          </div>
          <div className="flex ion-justify-content-between ion-align-items-center ion-margin-top">
            <TitleForm title="Refunds" customClass="ion-padding-horizontal ion-no-margin" />
            <IonLabel style={{ fontSize: '16px' }}>
              <IonText color='medium'>$0.00</IonText>
            </IonLabel>
          </div>
        </div>
      </IonCard>
    </IonCol>
  )
}
