import { FormForgotUser } from './components/FormForgotUser/FormForgotUser'
import WrapperFormBackground from '../../components/Organisms/WrapperFormBackground/WrapperFormBackground'

export const ForgotUser = () => {
  return (
    <WrapperFormBackground title="Recuperación de usuario">
      <FormForgotUser />
    </WrapperFormBackground>
  )
}
