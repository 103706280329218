import { IonCard, IonIcon, IonItem, IonList } from '@ionic/react'
import { addCircle } from 'ionicons/icons'
import { useState } from 'react'
import { TitleForm } from '../../../components/Atoms/TitleForm'
import ModalSmall from '../../../components/Organisms/ModalSmall/ModalSmall'
import { type Email } from '../../../models/email'
import { FormEmails } from '../FormEmails'

export interface Props {
  title: string
  list: Email[]
}
interface HandleEmailParams {
  isEdit: boolean
  itemEdit?: Email
}

const ListOfEmails: React.FC<Props> = ({ title, list }) => {
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [itemEdit, setItemEdit] = useState<Email>()

  const handleEmail = ({ isEdit, itemEdit }: HandleEmailParams) => {
    setIsOpenModal(true)
    setIsEdit(isEdit)
    if (isEdit) setItemEdit(itemEdit)
  }

  return (
		<>
		<IonCard className="box ion-padding">
      <div>
        <TitleForm title={title} customClass="ion-margin-start" />
				<IonList>
					{list.map(email =>
						<IonItem key={email.id} button={true} detail={true} onClick={() => { handleEmail({ isEdit: true, itemEdit: email }) }}>{ email.email }</IonItem>
					)}
				</IonList>
      </div>
			<IonItem lines="none">
				<IonIcon icon={addCircle} slot="end" size="large" onClick={() => { handleEmail({ isEdit: false }) }}/>
			</IonItem>
    </IonCard>
		<ModalSmall isOpen={isOpenModal} onDidDismiss={() => { setIsOpenModal(false) }}>
			<FormEmails isEdit={isEdit} itemEdit={itemEdit} closeModal={() => { setIsOpenModal(false) }} />
		</ModalSmall>
		</>
  )
}

export default ListOfEmails
