import { IonText } from '@ionic/react'
import './TitleForm.css'

export interface TitleFormInterface {
  title: string
  customClass?: string
  noSpaceTop?: boolean
}

const TitleForm: React.FC<TitleFormInterface> = ({ title, customClass = '', noSpaceTop = false }) => {
  return <IonText color="dark"><h2 className={`title-form ${customClass} ${noSpaceTop && 'no-space-top'}`}>{ title }</h2></IonText>
}

export default TitleForm
