import { IonCard, IonIcon, IonItem, IonLabel, IonList, useIonActionSheet, useIonToast } from '@ionic/react'
import { addCircle, chevronForwardOutline, trashSharp } from 'ionicons/icons'
import { useState } from 'react'
import { TitleForm } from '../../../../components/Atoms/TitleForm'
import ModalSmall from '../../../../components/Organisms/ModalSmall/ModalSmall'
import type { Dispositive } from '../../../../models/dispositive'
import { FormDispositive } from '../FormDispositive'

interface HandleParams {
  isEdit: boolean
  itemEdit?: Dispositive
}

const ListOfDispositives: React.FC = () => {
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [itemEdit, setItemEdit] = useState<Dispositive>()
  const [present] = useIonActionSheet()
  const [presentAlert] = useIonToast()

  const dispositiveList: Dispositive[] = [
    {
      id: 'ASad-sf45s-dffds1sdsds34',
      name: 'POS tienda del valle',
      agent: {
        id: 'ASad-sf45s-dffds1',
        name: 'Agente 1'
      }
    }
  ]

  const handleDispositive = ({ isEdit, itemEdit }: HandleParams) => {
    setIsOpenModal(true)
    setIsEdit(isEdit)
    if (isEdit) setItemEdit(itemEdit)
  }

  const showActionSheetDeleteDispositive = (dispositive: Dispositive) => {
    present({
      header: `Desea eliminar el dispositivo: ${dispositive.name}`,
      buttons: [
        {
          text: 'Si, eliminar',
          role: 'destructive',
          data: {
            action: 'delete'
          },
          handler: handleDeleteDispositive
        },
        {
          text: 'Cancel',
          role: 'cancel',
          data: {
            action: 'cancel'
          }
        }
      ]
    })
  }

  const handleDeleteDispositive = () => {
    // services delete here!
    presentAlert({
      message: 'Dispositivo eliminado',
      duration: 2500,
      position: 'bottom',
      color: 'success'
    })
  }

  return (
		<>
			<IonCard className="box ion-padding">
				<div>
					<TitleForm title="Dispositivos" customClass="ion-margin-start" />
					<IonList>
						{dispositiveList.map(dispositive =>
							<IonItem key={dispositive.id} button={true} >
								<IonLabel>
									{ dispositive.name }
								</IonLabel>
								<IonIcon icon={trashSharp} slot="end" onClick={() => { showActionSheetDeleteDispositive(dispositive) }}/>
								<IonIcon icon={chevronForwardOutline} slot="end" onClick={() => { handleDispositive({ isEdit: true, itemEdit: dispositive }) }} />
							</IonItem>
						)}
					</IonList>
				</div>
				<IonItem lines="none">
					<IonIcon icon={addCircle} slot="end" size="large" onClick={() => { handleDispositive({ isEdit: false }) }}/>
				</IonItem>
			</IonCard>
			<ModalSmall isOpen={isOpenModal} onDidDismiss={() => { setIsOpenModal(false) }}>
				<FormDispositive isEdit={isEdit} itemEdit={itemEdit} closeModal={() => { setIsOpenModal(false) }} />
			</ModalSmall>
		</>
  )
}

export default ListOfDispositives
