import { IonButton, IonCol, IonGrid, IonItem, IonLabel, IonList, IonRadio, IonRadioGroup, IonRow, IonToggle, useIonToast } from '@ionic/react'
import { useState } from 'react'
import { Input } from '../../../../../../components/Atoms/Input/Input'
import { TitleForm } from '../../../../../../components/Atoms/TitleForm'
import { type SalesTaxes } from '../../../../../../models/sales-taxes'

export interface Props {
  isEdit: boolean
  itemEdit?: SalesTaxes
  closeModal: () => void
}

export function FormSalesTaxes ({ isEdit, itemEdit, closeModal }: Props) {
  const [present] = useIonToast()
  const initialValue = isEdit ? { ...itemEdit, name: itemEdit?.name, document: itemEdit?.percentage } : { name: '', percentage: '' }
  const [salesTaxes, setSalesTaxes] = useState<SalesTaxes>(initialValue as SalesTaxes)

  const handleChange = (e: any) => {
    setSalesTaxes(prevValue => ({
      ...prevValue,
      [e.target.name]: e.target.value
    }))
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    closeModal()

    // run services edit here!

    present({
      message: isEdit ? 'Datos guardados' : 'Dato creado',
      duration: 2500,
      position: 'bottom',
      color: 'success'
    })
  }

  return (
		<div className="box ion-padding">
			<form className="form" onSubmit={e => { handleSubmit(e) }}>
				<TitleForm title={`${isEdit ? 'Editar' : 'Crear'} impuesto`} customClass="ion-padding-start" />
				<IonGrid>
					<IonRow>
						<IonCol size="12">
						<IonItem>
							<IonLabel>Activado</IonLabel>
							<IonToggle color="secondary" slot="end" onIonChange={e => { console.log(e) }}></IonToggle>
						</IonItem>
						</IonCol>
						<IonCol size="4">
							<Input label="Nombre" type="text" name="name" value={salesTaxes.name} onChange={e => { handleChange(e) }}/>
						</IonCol>
						<IonCol size="8">
							<Input label="Porcentaje" type="text" name="percentage" value={salesTaxes.percentage} onChange={e => { handleChange(e) }}/>
						</IonCol>
					</IonRow>
					<div style={{ width: '100%', height: '4px', background: '#727070b1' }}></div>
					<TitleForm title="Asignación de impuesto" customClass="ion-margin-start" />
					<IonLabel className="ion-padding-start">
						<p className="ion-padding-start">Asignar impuesto a </p>
					</IonLabel>
				</IonGrid>
				<IonList>
					<IonRadioGroup>
						<IonItem>
							<IonLabel>
								<h2>Todos los articulos y servicios gravables</h2>
								<p>Incluye todos los articulos futuros y los servicios</p>
							</IonLabel>
							<IonRadio value="1" slot='end'></IonRadio>
						</IonItem>
						<IonItem>
							<IonLabel>Seleccionar articulos</IonLabel>
							<IonRadio value="2" slot='end'></IonRadio>
						</IonItem>
					</IonRadioGroup>
					<IonItem lines='none'>
						<IonLabel>Importe personalizado</IonLabel>
						<IonToggle color="secondary" slot="end" onIonChange={e => { console.log(e) }}></IonToggle>
					</IonItem>
				</IonList>
				<div style={{ width: '100%', height: '4px', background: '#727070b1' }}></div>
				<IonItem button={true} detail={true} lines='none' >
					<div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
						<p style={{ fontWeight: 'bold', margin: 0 }}>Precio de articulo </p>
						<IonLabel> Incluir impuesto en el precio del articulo</IonLabel>
					</div>
				</IonItem>
				{
					isEdit &&
					<div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
						<IonButton color="secondary" shape="round" size='large' fill='outline' expand="full">Eliminar el impuesto</IonButton>
					</div>
				}
				<div className="ion-text-end">
					<IonButton color="secondary" shape="round" type="submit">{isEdit ? 'Guardar' : 'Crear'}</IonButton>
					<IonButton color="secondary" shape="round" fill="clear" type="button" onClick={() => { closeModal() }}>Cancelar</IonButton>
				</div>
			</form>
		</div>
  )
}
