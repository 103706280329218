import { IonApp } from '@ionic/react'
import { IonReactRouter } from '@ionic/react-router'
import { PrivateRouter } from './components/Routes/Private'
import { PublicRouter } from './components/Routes/Public'
import { PrivateSections, PublicSections } from './components/Routes/Routes'

export const AppRouter: React.FC = () => {
  return (
    <IonApp>
      <IonReactRouter>
        <PublicRouter>
          <PublicSections />
        </PublicRouter>
        <PrivateRouter>
          <PrivateSections />
        </PrivateRouter>
      </IonReactRouter>
    </IonApp>
  )
}
