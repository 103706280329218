import { IonBackButton, IonButtons } from '@ionic/react'
import { Redirect, useRouteMatch } from 'react-router'
import { WrapperApp } from '../../components/Organisms/WrapperApp/WrapperApp'
import { useAppSelector } from '../../store/hooks'
import { ListOfIdentityDocuments } from '../CommonComponentsInPages/ListOfIdentityDocuments'
import { ViewAccountWallet } from '../CommonComponentsInPages/ViewAccountWallet'
import { FormEditNameCompany } from './components/FormEditNameCompany'
import { ListOfPhones } from '../CommonComponentsInPages/ListOfPhones'
import { ListOfEmails } from '../CommonComponentsInPages/ListOfEmails'
import { ListOfSocialMedia } from '../CommonComponentsInPages/ListOfSocialMedia'
import { FormEditSchedule } from './components/FormEditSchedule'
import { FormEditCountry } from '../CommonComponentsInPages/FormEditCountry'
import { ListOfAddress } from '../CommonComponentsInPages/ListOfAddress'
import { ListOfBusiness } from './components/ListOfBusiness'

interface MatchParams {
  section: string
}

const EditCommerceData: React.FC = () => {
  const routerMatch = useRouteMatch<MatchParams>()
  const commerce = useAppSelector(store => store.commerce)

  const renderComponent = (id: string) => {
    switch (id) {
      case '1': return <FormEditNameCompany />
      case '2': return <ViewAccountWallet />
      case '3': return <ListOfIdentityDocuments title="Número RNC / RIF (DNI)" list={commerce.identities} />
      case '4': return <ListOfPhones title="Teléfonos" list={commerce.phones} />
      case '5': return <ListOfEmails title="Correos" list={commerce.emails} />
      case '6': return <ListOfSocialMedia title="Redes sociales" list={commerce.socialmedia}/>
      case '7': return <FormEditSchedule />
      case '8': return <FormEditCountry userType={commerce} />
      case '9': return <ListOfAddress title="Direcciones" list={commerce.direction} />
      case '10': return <ListOfBusiness />
      default: return <Redirect to="/perfil-comercio" />
    }
  }

  return (
		<WrapperApp
			pageName="Perfil Juridico"
			renderButtons={() =>
			<IonButtons slot="start">
				<IonBackButton defaultHref="/perfil-comercio" />
			</IonButtons>}
		>
			{renderComponent(routerMatch.params.section)}
		</WrapperApp>
  )
}

export default EditCommerceData
