import { IonCard, IonIcon, IonItem, IonLabel, IonList } from '@ionic/react'
import { addCircle } from 'ionicons/icons'
import { useState } from 'react'
import { TitleForm } from '../../../../components/Atoms/TitleForm'
import ModalSmall from '../../../../components/Organisms/ModalSmall/ModalSmall'
import { type FamilyMember } from '../../../../models/family-member'
import { useAppSelector } from '../../../../store/hooks'
import { FormMembersFamily } from '../FormMembersFamily'

interface HandleFamilyMemberParams {
  isEdit: boolean
  itemEdit?: FamilyMember
}

const ListOfMembersFamily: React.FC = () => {
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [itemEdit, setItemEdit] = useState<FamilyMember>()
  const user = useAppSelector(store => store.user)

  const handleEmail = ({ isEdit, itemEdit }: HandleFamilyMemberParams) => {
    setIsOpenModal(true)
    setIsEdit(isEdit)
    if (isEdit) setItemEdit(itemEdit)
  }

  return (
		<>
			<IonCard className="box ion-padding">
				<div>
					<TitleForm title="Familiares" customClass="ion-margin-start" />
					<IonList>
						{user.familyMember.map(member =>
							<IonItem key={member.id} button={true} detail={true} onClick={() => { handleEmail({ isEdit: true, itemEdit: member }) }}>
								<IonLabel>
									<h2>{ member.type }</h2>
									<p>{ member.name }</p>
								</IonLabel>
							</IonItem>
						)}
					</IonList>
				</div>
				<IonItem lines="none">
					<IonIcon icon={addCircle} slot="end" size="large" onClick={() => { handleEmail({ isEdit: false }) }}/>
				</IonItem>
			</IonCard>
			<ModalSmall isOpen={isOpenModal} onDidDismiss={() => { setIsOpenModal(false) }}>
				<FormMembersFamily isEdit={isEdit} itemEdit={itemEdit} closeModal={() => { setIsOpenModal(false) }} />
			</ModalSmall>
		</>
  )
}

export default ListOfMembersFamily
