import { IonButton, IonCard, IonIcon, IonTitle } from '@ionic/react'
import { saveOutline } from 'ionicons/icons'
import { useMemo, useState } from 'react'
import paymentIcon from '../../../../assets/payIcon.svg'
import { TitleForm } from '../../../../components/Atoms/TitleForm'
import ModalSmall from '../../../../components/Organisms/ModalSmall/ModalSmall'
import type { ProductCart } from '../../Pos'
import { ModalCreateNewTicket } from '../ModalCreateNewTicket/ModalCreateNewTicket'
import { ModalPaymentPeronalizeEnterAmount } from '../ModalPaymentPeronalizeEnterAmount/ModalPaymentPeronalizeEnterAmount'
import { ModalPaymentPersonalize } from '../ModalPaymentPersonalize/ModalPaymentPersonalize'
import { ModalRegisterPayment } from '../ModalRegisterPayment/ModalRegisterPayment'
import { ModalSelectPaymentMethodNew } from '../ModalSelectPaymentMethod/ModalSelectPaymentMethodNew'
import { ModalSelectPaymentType } from '../ModalSelectPaymentMethod/components/ModalSelectPaymentType/ModalSelectPaymentType'
import { ModalSuccessPayment } from '../ModalSuccessPayment/ModalSuccessPayment'
import './style.css'
interface Props {
  productsCart: ProductCart[]
}

export function CartTotal ({ productsCart }: Props) {
  const [isOpenModalPayment, setIsOpenModalPayment] = useState(false)
  const [isOpenModalSelectPayment, setIsOpenModalSelectPayment] = useState(false)
  const [isOpenModalPaymentPersonalize, setIsOpenModalPaymentPersonalize] = useState(false)
  const [isOpenModalPaymentPersonalizeEnterAmount, setIsOpenModalPaymentPersonalizeEnterAmount] = useState(false)
  const [isOpenModalRegisterPayment, setIsOpenModalRegisterPayment] = useState(false)
  const [isOpenModalSuccessPayment, setIsOpenModalSuccessPayment] = useState(false)
  const [isOpenModalNewTicket, setIsOpenModalNewTicket] = useState<boolean>(false)

  const totalSumProducts = useMemo(() => {
    return productsCart.reduce((accumulator, currentValue) => {
      return accumulator + (Number(currentValue.quantity) * currentValue.price)
    }, 0)
  }, [productsCart])

  return (
    <>
      <IonCard className="ion-padding">
        <TitleForm title='Pago total' />
        <div className='flex ion-justify-content-between ion-align-items-center' style={{ gap: '.4rem' }}>
          <IonTitle className="ion-text-letf ion-no-padding">R$ { totalSumProducts?.toFixed(2) }</IonTitle>
          <IonButton shape="round" onClick={() => { setIsOpenModalPayment(true) }}>
						Cobrar
						<IonIcon src={paymentIcon} style={{ paddingLeft: '.5rem' }}></IonIcon>
					</IonButton>
          <IonButton color="secondary" shape="round" onClick={() => { setIsOpenModalNewTicket(true) }} fill='outline'>
						<IonIcon icon={saveOutline}></IonIcon>
					</IonButton>
        </div>
      </IonCard>
      <ModalSmall isOpen={isOpenModalPayment} onDidDismiss={() => { setIsOpenModalPayment(false) }} customClass='large'>
				<ModalSelectPaymentMethodNew
					setIsOpenModalPayment={setIsOpenModalPayment}
					setIsOpenModalSelectPayment={setIsOpenModalSelectPayment}
					totalSumProducts={totalSumProducts}
				/>
        {/* <ModalSelectPaymentMethod seIisOpenModalSelectPaymentMethod={seIisOpenModalSelectPaymentMethod} totalSumProducts={totalSumProducts} /> */}
      </ModalSmall>
      <ModalSmall isOpen={isOpenModalSelectPayment} onDidDismiss={() => { setIsOpenModalSelectPayment(false) }} customClass='large'>
				<ModalSelectPaymentType
					setIsOpenModalSelectPayment={setIsOpenModalSelectPayment}
					setIsOpenModalPaymentPersonalize={setIsOpenModalPaymentPersonalize}
					setIsOpenModalRegisterPayment={setIsOpenModalRegisterPayment}
				/>
			</ModalSmall>
			<ModalSmall isOpen={isOpenModalPaymentPersonalize} onDidDismiss={() => { setIsOpenModalPaymentPersonalize(false) }} customClass='large'>
				<ModalPaymentPersonalize
					setIsOpenModalPaymentPersonalize={setIsOpenModalPaymentPersonalize}
					setIsOpenModalPaymentPersonalizeEnterAmount={setIsOpenModalPaymentPersonalizeEnterAmount}
				/>
      </ModalSmall>
			<ModalSmall isOpen={isOpenModalPaymentPersonalizeEnterAmount} onDidDismiss={() => { setIsOpenModalPaymentPersonalizeEnterAmount(false) }} customClass='large'>
				<ModalPaymentPeronalizeEnterAmount
					setIsOpenModalSuccessPayment={setIsOpenModalSuccessPayment}
					setIsOpenModalPaymentPersonalizeEnterAmount={setIsOpenModalPaymentPersonalizeEnterAmount}
				/>
      </ModalSmall>
			<ModalSmall isOpen={isOpenModalSuccessPayment} onDidDismiss={() => { setIsOpenModalSuccessPayment(false) }} customClass='large'>
				<ModalSuccessPayment
					setIsOpenModalSuccessPayment={setIsOpenModalSuccessPayment}
				/>
			</ModalSmall>

			<ModalSmall isOpen={isOpenModalRegisterPayment} onDidDismiss={() => { setIsOpenModalRegisterPayment(false) }} customClass='large'>
				<ModalRegisterPayment
					setIsOpenModalRegisterPayment={setIsOpenModalRegisterPayment}
					setIsOpenModalSuccessPayment={setIsOpenModalSuccessPayment}
				/>
			</ModalSmall>
			<ModalSmall customClass='large' isOpen={isOpenModalNewTicket} onDidDismiss={() => { setIsOpenModalNewTicket(false) }}>
				{/* <ModalTickets /> */}
				<ModalCreateNewTicket setIsOpenModalNewTicket={setIsOpenModalNewTicket} />
			</ModalSmall>
    </>
  )
}
