import { IonButton, IonCard, IonCol, IonText, useIonToast } from '@ionic/react'
import { useState } from 'react'
import { Input } from '../../../../components/Atoms/Input/Input'
import { TitleForm } from '../../../../components/Atoms/TitleForm'

export function CurrentDrawer () {
  const [present] = useIonToast()
  const [startingCash, setStartingCash] = useState(0)

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    present({
      message: 'Cash agregado con éxito',
      duration: 2500,
      position: 'bottom',
      color: 'success'
    })
  }

  const handleChange = (e: any) => {
    setStartingCash(e.target.value)
  }

  return (
    <IonCol size="12" size-md="8" size-lg="8" size-xl="9">
      <IonCard className="box ion-padding">
        <form onSubmit={e => { handleSubmit(e) }}>
          <TitleForm title="Starting Cash" customClass="ion-margin-start" />
          <Input label="Starting cash" type="number" name="startingCash" value={startingCash} onChange={e => { handleChange(e) }}/>
          <div className="ion-padding-bottom">
            <IonText>Agrege el efectivo inicial</IonText>
          </div>
          <div className="ion-text-end">
            <IonButton color="secondary" shape="round" type="submit" expand="block">Confirm start drawer</IonButton>
          </div>
        </form>
      </IonCard>
    </IonCol>
  )
}
