import { IonItem, IonIcon, IonText, IonButton, IonCheckbox, IonLabel } from '@ionic/react'
import { closeOutline } from 'ionicons/icons'
import { LineSeparator } from '../../../../components/Atoms/LineSeparator/LineSeparator'
import { TitleForm } from '../../../../components/Atoms/TitleForm'

interface Props {
  setIsOpenModalImport: React.Dispatch<React.SetStateAction<boolean>>
}

export function ModalImportProducts ({ setIsOpenModalImport }: Props) {
  const handleImport = () => {
    setIsOpenModalImport(false)
  }

  return (
    <>
      <IonItem className='ion-no-padding ion-no-margin' lines='none'>
        <TitleForm title="Importar producto por CSV" customClass="ion-no-padding ion-no-margin" />
        <IonIcon icon={closeOutline} size='large' slot='end' onClick={() => { setIsOpenModalImport(false) }} />
      </IonItem>
      <LineSeparator noSpaceTop />
      <div style={{ maxWidth: '700px' }} className="ion-padding-vertical">
        <IonText color='dark'>
          Descargue una
          <IonText color='secondary'> plantilla CSV de muestra </IonText>
          para ver un ejemplo del formato requerido
        </IonText>
      </div>
      <div className='ion-text-center ion-padding ion-margin-vertical' style={{ border: '1px dashed var(--ion-line-separator-color)', borderRadius: '10px', cursor: 'pointer', display: 'flex', justifyContent: 'center' }}>
        <IonButton color="secondary" shape="round" onClick={() => null}>Agregar archivo</IonButton>
      </div>
      <IonItem lines='none' style={{ maxWidth: '700px' }}>
        <IonCheckbox name="terms" defaultChecked={true} slot="start" />
        <IonLabel className="ion-text-wrap" id="terms">Sobrescriba cualquier producto actual que tenga el mismo identificador. Los valores existentes se utilizarán para las columnas que falten. <IonText color='secondary'> Aprende más</IonText></IonLabel>
      </IonItem>
      <LineSeparator />
      <div className="flex ion-justify-content-between ion-align-items-center">
        <div>
          <IonText color='secondary'>¿Necesitas ayuda para importar productos?</IonText>
        </div>
        <div>
          <IonButton color="secondary" shape="round" fill="clear" type="button" onClick={() => { setIsOpenModalImport(false) }}>Cancelar</IonButton>
          <IonButton color="secondary" shape="round" onClick={() => { handleImport() }}>Subir y continuar</IonButton>
        </div>
      </div>
    </>
  )
}
