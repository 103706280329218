import { IonButton, IonCard, IonItem, IonSelect, IonSelectOption, IonText, IonLabel, useIonToast } from '@ionic/react'
import { useState } from 'react'
import { useHistory } from 'react-router'
import { TitleForm } from '../../../../components/Atoms/TitleForm'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import { editSex } from '../../../../store/states/user'

const FormEditSex: React.FC = () => {
  const [present] = useIonToast()
  const user = useAppSelector(store => store.user)
  const [sex, setSex] = useState<string>(user.sex)
  const dispatch = useAppDispatch()
  const history = useHistory()

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    // run services edit here!
    present({
      message: 'Datos guardados',
      duration: 2500,
      position: 'bottom',
      color: 'success'
    })
    dispatch(editSex({ sex }))
    history.replace('/perfil', { replace: true })
  }

  const handleChange = (e: any) => {
    setSex(e.detail.value)
  }

  return (
		<IonCard className="box ion-padding">
			<form onSubmit={e => { handleSubmit(e) }}>
				<TitleForm title="Sexo" customClass="ion-padding-bottom" />
				<IonItem className="input-item" >
					<IonLabel position="floating">Sexo</IonLabel>
					<IonSelect value={sex} placeholder="Selecciona tu sexo" name="sex" onIonChange={e => { handleChange(e) }}>
						<IonSelectOption value="Masculino">Masculino</IonSelectOption>
						<IonSelectOption value="Femenino">Femenino</IonSelectOption>
						<IonSelectOption value="Otros">Otros</IonSelectOption>
					</IonSelect>
				</IonItem>
				<div className="ion-padding">
					<IonText>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</IonText>
				</div>
				<div className="ion-text-end">
					<IonButton color="secondary" shape="round" type="submit">Guardar</IonButton>
					<IonButton color="secondary" shape="round" fill="clear" type="button" onClick={() => { history.replace('/perfil', { replace: true }) }}>Cancelar</IonButton>
				</div>
			</form>
		</IonCard>
  )
}

export default FormEditSex
