import { IonCard, IonIcon, IonItem, IonList } from '@ionic/react'
import { addCircle } from 'ionicons/icons'
import { useState } from 'react'
import { TitleForm } from '../../../components/Atoms/TitleForm'
import ModalSmall from '../../../components/Organisms/ModalSmall/ModalSmall'
import { type Phone } from '../../../models/phone'
import { FormPhones } from '../FormPhones'

export interface Props {
  title: string
  list: Phone[]
}
interface HandlePhonesParams {
  isEdit: boolean
  itemEdit?: Phone
}

const ListOfPhones: React.FC<Props> = ({ title, list }) => {
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [itemEdit, setItemEdit] = useState<Phone>()

  const handlePhones = ({ isEdit, itemEdit }: HandlePhonesParams) => {
    setIsOpenModal(true)
    setIsEdit(isEdit)
    if (isEdit) setItemEdit(itemEdit)
  }

  return (
		<>
		<IonCard className="box ion-padding">
      <div>
        <TitleForm title={title} customClass="ion-margin-start" />
				<IonList>
					{list.map(phone =>
						<IonItem key={phone.id} button={true} detail={true} onClick={() => { handlePhones({ isEdit: true, itemEdit: phone }) }}>{ phone.phone }</IonItem>
					)}
				</IonList>
      </div>
			<IonItem lines="none">
				<IonIcon icon={addCircle} slot="end" size="large" onClick={() => { handlePhones({ isEdit: false }) }}/>
			</IonItem>
    </IonCard>
		<ModalSmall isOpen={isOpenModal} onDidDismiss={() => { setIsOpenModal(false) }}>
			<FormPhones isEdit={isEdit} itemEdit={itemEdit} closeModal={() => { setIsOpenModal(false) }} />
		</ModalSmall>
		</>
  )
}

export default ListOfPhones
