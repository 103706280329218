import { IonContent, IonPage, IonText } from '@ionic/react'
import fondo from '../../../assets/fondo-login.png'
import WrapperCenterVertically from '../WrapperCenterVertically/WrapperCenterVertically'
import './WrapperFormBackground.css'

interface WrapperFormBackgroundProps {
  children: React.ReactNode
  title: string
}

const WrapperFormBackground: React.FC<WrapperFormBackgroundProps> = ({ children, title }) => {
  return (
    <IonPage>
      <IonContent className="wrapper">
        <div className="wrapper-content">
          <div className="fondo_top"><img src={fondo} alt="fondo"/></div>
            <WrapperCenterVertically>
              <IonText color="dark" className="ion-text-wrap ion-padding-end"><h1 className="wrapper-title ion-text-center">{ title }</h1></IonText>
              { children }
            </WrapperCenterVertically>
          <div className="fondo_bottom"></div>
        </div>
      </IonContent>
    </IonPage>
  )
}

export default WrapperFormBackground
