import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { getLocalStorage, setLocalStorage } from '../../utilities/localStorage.utility'

const initialState = true

export const userThemeSlice = createSlice({
  name: 'user-dark-mode',
  initialState: getLocalStorage('user-dark-mode') ? JSON.parse(getLocalStorage('user-dark-mode') as string) : initialState,
  reducers: {
    changeTheme (state, action: PayloadAction<boolean>) {
      setLocalStorage('user-dark-mode', action.payload)
      return action.payload
    }
  }
})

export const {
  changeTheme
} = userThemeSlice.actions
export default userThemeSlice.reducer
