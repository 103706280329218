import { createSlice, type PayloadAction, current } from '@reduxjs/toolkit'
import { getLocalStorage, setLocalStorage } from '../../utilities/localStorage.utility'
import { card, bagCheck, cube, phonePortrait, receipt, fileTrayFull, wallet, gitMerge } from 'ionicons/icons'

export interface MenuItem {
  id: number
  title: string
  url: string
  mdIcon: string
  show: boolean
  fixed?: boolean
}

const initialState: MenuItem[] = [
  {
    id: 1,
    title: 'Caja',
    url: '/punto-de-venta',
    mdIcon: card,
    show: true,
    fixed: true
  },
  {
    id: 2,
    title: 'Cobro',
    url: '/checkout',
    mdIcon: bagCheck,
    show: true,
    fixed: true
  },
  {
    id: 3,
    title: 'Productos',
    url: '/productos',
    mdIcon: cube,
    show: false
  },
  {
    id: 4,
    title: 'Pedidos',
    url: '/pedidos',
    mdIcon: cube,
    show: false
  },
  {
    id: 5,
    title: 'Actividad',
    url: '/actividad',
    mdIcon: phonePortrait,
    show: false
  },
  {
    id: 6,
    title: 'Reporte',
    url: '/reporte',
    mdIcon: receipt,
    show: false
  },
  {
    id: 7,
	  title: 'Dispositivos',
	  url: '/dispositivos',
	  mdIcon: fileTrayFull,
    show: false
  },
  {
    id: 8,
	  title: 'Retiro',
	  url: '/retiro',
	  mdIcon: wallet,
    show: false
  },
  {
    id: 9,
	  title: 'Complementos',
	  url: '/complementos',
	  mdIcon: gitMerge,
    show: false
  }
]

export const mainMenuSlice = createSlice({
  name: 'main-menu',
  initialState: getLocalStorage('main-menu') ? JSON.parse(getLocalStorage('main-menu') as string) : initialState,
  reducers: {
    changeShowMenuItem (state, action: PayloadAction<MenuItem>) {
      const filtersMenuItem = [...current(state)].filter(menuItem => menuItem.id !== action.payload.id)
      const newMenu = [...filtersMenuItem, action.payload]
      setLocalStorage('main-menu', newMenu)
      return newMenu
    }
  }
})

export const {
  changeShowMenuItem
} = mainMenuSlice.actions

export default mainMenuSlice.reducer
