import { IonButton, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonList, IonSegment, IonSegmentButton } from '@ionic/react'
import { search } from 'ionicons/icons'
import { useMemo, useState } from 'react'
import { TitleForm } from '../../../../components/Atoms/TitleForm'

interface Ticket {
  id?: number
  name: string
  total: number
  date: string
}

const initiaTickets: Ticket[] = [
  {
    id: 1,
    name: 'Colmado',
    total: 158.22,
    date: 'Hace 1 día'
  },
  {
    id: 1,
    name: 'Colmado 2',
    total: 1233258.22,
    date: 'Hace 1 día'
  }
]

interface Props {
  setIsOpenModalNewTicket: React.Dispatch<React.SetStateAction<boolean>>
}

export function ModalTickets ({ setIsOpenModalNewTicket }: Props) {
  const [searchValue, setSearchValue] = useState<string>('')
  const [segment, setSegment] = useState<string>('name')

  const filteredTickets = useMemo(() => {
    return searchValue !== null
      ? initiaTickets.filter(ticket => ticket.name.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()))
      : initiaTickets
  }, [searchValue, initiaTickets])

  const handleNewTicket = () => {
    setIsOpenModalNewTicket(true)
  }

  return (
		<>
		<div className="ion-padding">
			<TitleForm title='Guardar articlos en' />
			<IonHeader className='ion-margin-bottom'>
				<IonItem fill='solid' style={{ width: '100%' }} lines='none' className="ion-no-margin">
					<IonIcon slot="start" icon={search} />
					<IonInput name='search' placeholder='Buscar' type='search' value={searchValue} onIonChange={(e: any) => { setSearchValue(e.target.value) }} />
				</IonItem>
			</IonHeader>
			<IonSegment value={segment} scrollable onIonChange={e => { setSegment(e.detail.value as string) }}>
        <IonSegmentButton value="name"><IonLabel>Nombre</IonLabel></IonSegmentButton>
        <IonSegmentButton value="total"><IonLabel>Total</IonLabel></IonSegmentButton>
        <IonSegmentButton value="recent"><IonLabel>Reciente</IonLabel></IonSegmentButton>
			</IonSegment>
			<div className='ion-margin-top' onClick={() => { handleNewTicket() }}>
				<IonButton shape="round" color="light" expand="block">Pedido nuevo</IonButton>
			</div>
			<IonList>
        {filteredTickets.map(divice =>
          <IonItem button={true} detail={false} lines='full' key={divice.id}>
            <IonLabel slot='start'>
              <h2>{divice.name}</h2>
            </IonLabel>
            <IonLabel slot='end'>
              <h2>${divice.total}</h2>
              <h3>{divice.date} bs</h3>
            </IonLabel>
          </IonItem>
        )}
      </IonList>
		</div>
		</>
  )
}
