import { FormForgotPassword } from './components/FormForgotPassword/FormForgotPassword'
import WrapperFormBackground from '../../components/Organisms/WrapperFormBackground/WrapperFormBackground'

export const ForgotPassword = () => {
  return (
    <WrapperFormBackground title="Recuperación de contraseña">
      <FormForgotPassword />
    </WrapperFormBackground>
  )
}
