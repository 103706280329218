import { IonButton, IonButtons, IonCard, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonImg, IonInput, IonItem, IonLabel, IonList, IonListHeader, IonMenuButton, IonRow, IonTitle } from '@ionic/react'
import { newspaper, search, wallet } from 'ionicons/icons'
import { useState } from 'react'
import { LineSeparator } from '../../components/Atoms/LineSeparator/LineSeparator'
import { TitleForm } from '../../components/Atoms/TitleForm'
import { WrapperApp } from '../../components/Organisms/WrapperApp/WrapperApp'
import { HeaderDashboard } from '../Dashboard/components/HeaderDashboard'
import './styles.css'

interface ActivityType {
  id: number
  cash: string
  description: string
  time: string
  date: string
}

interface Activities {
  id: number
  date: string
  listActivities: ActivityType[]
}

const activities: Activities[] = [
  {
    id: 1,
    date: '11-09-2023',
    listActivities: [
      {
        id: 1,
        cash: '2.55',
        description: 'Bagle wl cream cheese (regular)',
        time: '10:55pm',
        date: '11-09-2023'
      },
      {
        id: 2,
        cash: '23.87',
        description: 'Comida italiana',
        time: '11:55pm',
        date: '11-09-2023'
      },
      {
        id: 3,
        cash: '32.837',
        description: 'Pc laptop',
        time: '11:55pm',
        date: '11-09-2023'
      }
    ]
  },
  {
    id: 2,
    date: '03-10-2023',
    listActivities: [
      {
        id: 4,
        cash: '2.55',
        description: 'Bagle wl cream cheese (regular)',
        time: '10:55am',
        date: '03-10-2023'
      },
      {
        id: 5,
        cash: '2.87',
        description: 'Bagle wl cream cheese (regular)',
        time: '11:22am',
        date: '03-10-2023'
      },
      {
        id: 6,
        cash: '2.87',
        description: 'Bagle wl cream cheese (regular)',
        time: '1:55pm',
        date: '03-10-2023'
      },
      {
        id: 7,
        cash: '2.87',
        description: 'Bagle wl cream cheese (regular)',
        time: '3:00pm',
        date: '03-10-2023'
      },
      {
        id: 8,
        cash: '2.87',
        description: 'Bagle wl cream cheese (regular)',
        time: '09:22pm',
        date: '03-10-2023'
      },
      {
        id: 9,
        cash: '2.87',
        description: 'Bagle wl cream cheese (regular)',
        time: '11:33pm',
        date: '03-10-2023'
      },
      {
        id: 10,
        cash: '11.87',
        description: 'Bagle wl cream cheese (regular)',
        time: '11:55pm',
        date: '03-10-2023'
      },
      {
        id: 11,
        cash: '5.87',
        description: 'Bagle wl cream cheese (regular)',
        time: '11:55pm',
        date: '03-10-2023'
      }
    ]
  }
]

export function Activity () {
  const [activitySelected, setActivitySelected] = useState<ActivityType>(activities[0].listActivities[0])
  const [searchValue, setSearchValue] = useState<string>('')

  return (
    <>
    <WrapperApp
      pageName="Actividad"
      hiddenAvatar={true}
      renderButtons={() => (
        <>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <HeaderDashboard />
        </>
      )}
    >
      <IonGrid>
        <IonRow>
          <IonCol size="12" size-md="6" size-lg="5" size-xl="4">
            <IonHeader>
              <IonItem fill='solid' style={{ width: '100%' }} lines='none' className="ion-no-margin">
                <IonIcon slot="start" icon={search} />
                <IonInput name='search' placeholder='Buscar' type='search' value={searchValue} onIonChange={(e: any) => { setSearchValue(e.target.value) }} />
              </IonItem>
            </IonHeader>
            <IonContent scrollEvents={true} style={{ minHeight: '500px', height: '76vh' }}>
            <IonList lines="full">
              {activities.map(activity =>
                <div key={activity.id}>
                  <IonListHeader lines='full'>
                    <IonLabel>{ activity.date }</IonLabel>
                  </IonListHeader>
                  {
                    activity.listActivities.filter(actt => actt.description.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())).map(act => {
                      const itemSelected = activitySelected.id === act.id
                      return (
                      <IonItem button detail={false} key={act.id} color={itemSelected ? 'secondary' : ''} onClick={() => { setActivitySelected(act) }}>
                        <IonIcon icon={wallet} slot="start"></IonIcon>
                        <IonLabel>
                          <IonLabel>${act.cash}</IonLabel>
                          <IonLabel style={{ fontSize: '14px' }}>{act.description}</IonLabel>
                        </IonLabel>
                        <IonLabel slot='end'>{act.time}</IonLabel>
                      </IonItem>
                      )
                    })
                  }
                </div>
              )}
            </IonList>
            </IonContent>
          </IonCol>
          <IonCol size="12" size-md="6" size-lg="7" size-xl="8">
            <IonCard className="ion-padding">
              <IonHeader className="ion-padding">
                <IonTitle className="ion-text-center">${activitySelected.cash}</IonTitle>
                <div className="flex ion-justify-content-around ion-align-items-center ion-padding">
                  <IonButton color="light" shape="round" onClick={() => null}>Send receipt</IonButton>
                  <IonButton color="medium" shape="round" onClick={() => null}>Issue refund</IonButton>
                </div>
              </IonHeader>
              <LineSeparator />
              <IonContent scrollEvents={true} style={{ minHeight: '380px', height: '50vh' }}>
                <div className="flex ion-justify-content-between ion-align-items-center ion-padding">
                  <TitleForm title="Pago con tarjeta" customClass="ion-no-padding ion-no-margin" />
                  <TitleForm title={activitySelected.date} customClass="ion-no-padding ion-no-margin" />
                </div>
                <IonList lines='none'>
                  <IonItem>
                    <IonIcon icon={wallet} slot="start"></IonIcon>
                    <IonLabel>Master card 003</IonLabel>
                  </IonItem>
                  <IonItem>
                    <IonIcon icon={newspaper} slot="start"></IonIcon>
                    <IonLabel>Receipt number DNny</IonLabel>
                  </IonItem>
                </IonList>
                <LineSeparator />
                <IonList lines='none'>
                  <IonItem>
                    <IonImg slot='start' src='https://http2.mlstatic.com/D_NQ_NP_850654-MCO69550492881_052023-O.webp' alt='name' style={{ width: 40, height: 40 }} className="ion-align-self-center" />
                    <IonLabel>Master card 003</IonLabel>
                    <IonLabel slot='end'>3.71$</IonLabel>
                  </IonItem>
                  <IonItem>
                    <IonImg slot='start' src='https://http2.mlstatic.com/D_NQ_NP_850654-MCO69550492881_052023-O.webp' alt='name' style={{ width: 40, height: 40 }} className="ion-align-self-center" />
                    <IonLabel>Master card 003</IonLabel>
                    <IonLabel slot='end'>3.71$</IonLabel>
                  </IonItem>
                  <IonItem>
                    <IonImg slot='start' src='https://http2.mlstatic.com/D_NQ_NP_850654-MCO69550492881_052023-O.webp' alt='name' style={{ width: 40, height: 40 }} className="ion-align-self-center" />
                    <IonLabel>Master card 003</IonLabel>
                    <IonLabel slot='end'>3.71$</IonLabel>
                  </IonItem>
                  <IonItem>
                    <IonIcon slot='start' icon={wallet} />
                    <IonLabel>State sales tax</IonLabel>
                    <IonLabel slot='end'>0.22$</IonLabel>
                  </IonItem>
                  <IonItem>
                    <IonIcon slot='start' icon={newspaper} />
                    <IonLabel>Total</IonLabel>
                    <IonLabel slot='end'>4.22$</IonLabel>
                  </IonItem>
                </IonList>
              </IonContent>
            </IonCard>
          </IonCol>
        </IonRow>
      </IonGrid>
    </WrapperApp>
    </>
  )
}
