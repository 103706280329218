import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip
} from 'chart.js'
import { Bar } from 'react-chartjs-2'

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
)

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const
    },
    title: {
      display: true,
      text: 'Resultado de todo el mes'
    }
  }
}

const data = {
  labels: [
    ['Gross Sales', '11'],
    ['Sales', '12'],
    ['Average Sales', '13']
  ],
  datasets: [
    {
      categoryPercentage: 1,
      label: '',
      data: [65, 59, 80],
      backgroundColor: [
        'rgba(99, 99, 234, 1)',
        'rgba(99, 99, 234, 0.7)',
        'rgba(99, 99, 234, 0.4)'
      ],
      borderWidth: 0
    }
  ]
}

export function Chart () {
  return (
    <div className='flex ion-justify-content-center ion-align-items-center' style={{ minHeight: '300px', maxHeight: '300px' }}>
      <Bar options={options} data={data} />
    </div>
  )
}
