import { IonCol, IonGrid, IonIcon, IonInput, IonItem, IonRow } from '@ionic/react'
import { search } from 'ionicons/icons'
import { useMemo, useState } from 'react'
import { ItemStatus } from '../ItemStatus/ItemStatus'
import { LineSeparator } from '../../../../components/Atoms/LineSeparator/LineSeparator'

export enum Status {
  ACTIVE = 'Activo',
  COMPLETE = 'Completo',
  EXCESS = 'Pago en exceso'
}

export const StatusColor = {
  [Status.ACTIVE]: '#FFCE31',
  [Status.COMPLETE]: '#52C42B',
  [Status.EXCESS]: '#FF1010'
}

export const generateColorStatus = (status: keyof typeof StatusColor) => {
  const color = StatusColor[status]
  return color
}

export interface TypeRequest {
  id: number
  date: string
  time: string
  type: string
  status: Status
  clientName: string
  clientEmail: string
  priceE4coin: string
  priceBs: string
}

const listofRequest: TypeRequest[] = [
  {
    id: 1,
    date: 'Sep, 20, 2023',
    time: '2:45 PM -4 GTM',
    type: 'Reatilies Artwork',
    status: Status.ACTIVE,
    clientName: 'Michel Ottinger',
    clientEmail: 'mchael12345@correo.com',
    priceE4coin: '300,00',
    priceBs: '200,00'
  },
  {
    id: 2,
    date: 'Sep, 20, 2023',
    time: '2:45 PM -4 GTM',
    type: 'Reatilies Artwork',
    status: Status.COMPLETE,
    clientName: 'José Muñóz',
    clientEmail: 'joseM@correo.com',
    priceE4coin: '130,00',
    priceBs: '100,00'
  },
  {
    id: 3,
    date: 'Sep, 29, 2023',
    time: '1:23 PM -4 GTM',
    type: 'Reatilies Artwork',
    status: Status.EXCESS,
    clientName: 'María Calderón',
    clientEmail: 'Mcalderonp@correo.com',
    priceE4coin: '250,00',
    priceBs: '150,00'
  }
]

export function ListStatus () {
  const [searchValue, setSearchValue] = useState('')

  const handleSearchValue = (e: any) => {
    setSearchValue(e?.target?.value)
  }

  const filteredRequest = useMemo(() => {
    return searchValue !== null
      ? listofRequest.filter(request => request.type.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) || request.clientName.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()))
      : listofRequest
  }, [searchValue])

  return (
    <div className='ion-padding-top'>
      <IonItem fill='solid' style={{ width: '100%' }}>
        <IonIcon slot="start" icon={search} />
        <IonInput name='search' placeholder='Buscar' type='search' value={searchValue} onIonChange={handleSearchValue} />
      </IonItem>
      <IonGrid style={{ overflowX: 'auto' }}>
        <IonRow className='ion-justify-content-start ion-align-items-center ion-padding-top ion-text-center'>
          <IonCol size='2'>Fecha</IonCol>
          <IonCol size='2'>Tipo</IonCol>
          <IonCol size='2'>Status</IonCol>
          <IonCol size='2'>Cliente</IonCol>
          <IonCol size='2'>Precio</IonCol>
          <IonCol size='2'>Accion</IonCol>
        </IonRow>
        <LineSeparator noSpaceBottom />
        {filteredRequest.map(request =>
          <>
            <ItemStatus key={request.id} request={request} />
            <LineSeparator noSpaceBottom />
          </>
        )}
      </IonGrid>
    </div>
  )
}
