import { IonButton, IonCol, IonDatetime, IonGrid, IonRow, useIonToast } from '@ionic/react'
import { useState } from 'react'
import { CardBankUI } from '../../../../components/Atoms/CardBankUI'
import { Input } from '../../../../components/Atoms/Input/Input'
import { TitleForm } from '../../../../components/Atoms/TitleForm'
import ModalSmall from '../../../../components/Organisms/ModalSmall/ModalSmall'
import type { CardBank } from '../../../../models/user-card-bank'
import { useAppDispatch } from '../../../../store/hooks'
import { addCardBank, editCardBank } from '../../../../store/states/userCardsBank'

export interface Props {
  isEdit: boolean
  itemEdit?: CardBank
  closeModal: () => void
}

const FormCardsBank: React.FC<Props> = ({ isEdit, itemEdit, closeModal }) => {
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [dateCard, setDateCard] = useState<string>(isEdit ? itemEdit?.cardDate as string : '')
  const [present] = useIonToast()
  const initialValue = isEdit
    ? {
        ...itemEdit,
        cardNumber: itemEdit?.cardNumber,
        cardType: itemEdit?.cardType,
        cardDate: itemEdit?.cardDate,
        cardCodeSegurity: itemEdit?.cardCodeSegurity
      }
    : { cardNumber: '', cardType: '', cardDate: '', cardCodeSegurity: '' }
  const [cardBank, setCardBank] = useState<CardBank>(initialValue as CardBank)
  const dispatch = useAppDispatch()

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    const card = { ...cardBank, cardDate: dateCard }
    e.preventDefault()
    closeModal()

    // run services edit here!
    if (isEdit) {
      dispatch(editCardBank(card))
    } else {
      dispatch(addCardBank({ ...card, id: Math.ceil(Math.random() * 1000) }))
    }
    present({
      message: isEdit ? 'Datos guardados' : 'Dato creado',
      duration: 2500,
      position: 'bottom',
      color: 'success'
    })
  }

  const handleChange = (e: any) => {
    setCardBank(prevValue => ({
      ...prevValue,
      [e.target.name]: e.target.value
    }))
  }

  const handleChangeDate = (e: any) => {
    setDateCard(e.detail.value)
  }

  return (
		<div className="box ion-padding">
			<form className="form" onSubmit={e => { handleSubmit(e) }}>
				<TitleForm title={`${isEdit ? 'Editar' : 'Agregar'} tarjeta`} />
				<CardBankUI cardNumber={cardBank.cardNumber} cardDate={dateCard} cardType={cardBank.cardType}/>
				<IonGrid className="ion-no-padding">
          <IonRow>
            <IonCol size="6" className="ion-padding-end">
							<Input label="Número de tarjeta" type="number" name="cardNumber" value={cardBank.cardNumber} onChange={e => { handleChange(e) }}/>
            </IonCol>
            <IonCol size="6">
							<Input label="Tipo de tarjeta" type="text" name="cardType" value={cardBank.cardType} onChange={e => { handleChange(e) }}/>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="6" className="ion-padding-end">
							<div onClick={() => { setIsOpenModal(true) }}><Input label="Fecha de vencimiento" type="text" name="dateCard" value={dateCard} /></div>
							<ModalSmall isOpen={isOpenModal} onDidDismiss={() => { setIsOpenModal(false) }}>
								<IonDatetime
									locale="es-ES"
									value={dateCard?.toString().slice(0, 10)}
									onIonChange={e => { handleChangeDate(e) }}
									presentation="date"
								/>
								<div className="ion-text-end">
									<IonButton color="secondary" shape="round" type="submit" onClick={() => { setIsOpenModal(false) }}>Guardar</IonButton>
								</div>
							</ModalSmall>
            </IonCol>
            <IonCol size="6">
							<Input label="Código de seguridad" type="number" name="cardCodeSegurity" value={cardBank.cardCodeSegurity} onChange={e => { handleChange(e) }}/>
            </IonCol>
          </IonRow>
        </IonGrid>
				<div className="ion-text-end">
					<IonButton color="secondary" shape="round" type="submit">{isEdit ? 'Guardar' : 'Crear'}</IonButton>
					<IonButton color="secondary" shape="round" fill="clear" type="button" onClick={() => { closeModal() }}>Cancelar</IonButton>
				</div>
			</form>
		</div>
  )
}
export default FormCardsBank
