import { IonText } from '@ionic/react'
import './IonTextError.css'

export interface Props {
  children: string | false | undefined
}

const IonTextError: React.FC<Props> = ({ children }) => {
  return (
		<IonText color="danger">
			<p className="text-error-form">{ children }</p>
		</IonText>
  )
}

export default IonTextError
