import { IonButton, IonCol, IonGrid, IonItem, IonLabel, IonRow, IonSelect, IonSelectOption, useIonToast } from '@ionic/react'
import { useState } from 'react'
import { Input } from '../../../../components/Atoms/Input/Input'
import { TitleForm } from '../../../../components/Atoms/TitleForm'

export interface Props {
  closeModal: () => void
}

const FormSecurityQuestions: React.FC<Props> = ({ closeModal }) => {
  const [present] = useIonToast()
  // const history = useHistory()
  // const user = useAppSelector(store => store.user)
  // const dispatch = useAppDispatch()
  const [editData, setEditData] = useState({
    response1: 'Dog',
    response2: 'Rodriguez',
    response3: 'Spiderman'
  })

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    // run services edit here!
    present({
      message: 'Datos guardados',
      duration: 2500,
      position: 'bottom',
      color: 'success'
    })
    closeModal()
    // dispatch(editFullName(editData))
    // history.replace('/perfil', { replace: true })
  }

  const handleChange = (e: any) => {
    setEditData(values => ({
      ...values,
      [e.target.name]: e.target.value
    }))
  }

  return (
		<div className="box ion-padding">
			<form className="form" onSubmit={e => { handleSubmit(e) }}>
				<TitleForm title="Nombre completo" customClass="ion-margin-start" />
				<IonGrid>
          <IonRow>
            <IonCol>
						<IonItem className="input-item" >
							<IonLabel position="floating">Primera peregunta</IonLabel>
							<IonSelect style={{ minWidth: '300px' }} value="firstpet" name="questions1" onIonChange={e => { handleChange(e) }}>
								<IonSelectOption value="firstpet">Nombre de su primera mascota</IonSelectOption>
								<IonSelectOption value="firstnamemother">Nombre de soltera de su madre</IonSelectOption>
								<IonSelectOption value="firsthero">Nombre del heroe de su infancia</IonSelectOption>
							</IonSelect>
						</IonItem>
            </IonCol>
            <IonCol>
							<Input label="Respuesta" type="text" name="response1" value={editData.response1} onChange={e => { handleChange(e) }} />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
						<IonItem className="input-item" >
							<IonLabel position="floating">Segunda peregunta</IonLabel>
							<IonSelect style={{ minWidth: '300px' }} value="firstnamemother" name="questions2" onIonChange={e => { handleChange(e) }}>
								<IonSelectOption value="firstpet">Nombre de su primera mascota</IonSelectOption>
								<IonSelectOption value="firstnamemother">Nombre de soltera de su madre</IonSelectOption>
								<IonSelectOption value="firsthero">Nombre del heroe de su infancia</IonSelectOption>
							</IonSelect>
						</IonItem>
            </IonCol>
            <IonCol>
							<Input label="Respuesta" type="text" name="response2" value={editData.response2} onChange={e => { handleChange(e) }} />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
						<IonItem className="input-item" >
							<IonLabel position="floating">Tercera peregunta</IonLabel>
							<IonSelect style={{ minWidth: '300px' }} value="firsthero" name="questions3" onIonChange={e => { handleChange(e) }}>
								<IonSelectOption value="firstpet">Nombre de su primera mascota</IonSelectOption>
								<IonSelectOption value="firstnamemother">Nombre de soltera de su madre</IonSelectOption>
								<IonSelectOption value="firsthero">Nombre del heroe de su infancia</IonSelectOption>
							</IonSelect>
						</IonItem>
            </IonCol>
            <IonCol>
							<Input label="Respuesta" type="text" name="response3" value={editData.response3} onChange={e => { handleChange(e) }} />
            </IonCol>
          </IonRow>
        </IonGrid>
				<div className="ion-text-end">
					<IonButton color="secondary" shape="round" type="submit">Guardar</IonButton>
					<IonButton color="secondary" shape="round" fill="clear" type="button" onClick={() => { closeModal() }}>Cancelar</IonButton>
				</div>
			</form>
		</div>
  )
}

export default FormSecurityQuestions
