import { IonButton, IonCard, IonList, IonText } from '@ionic/react'
import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Input } from '../../../../components/Atoms/Input/Input'

export const FormForgotPassword = () => {
  const [userValue, setUserValue] = useState<string | null>('')
  const history = useHistory()

  const handleLogin = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    history.push('/forgot-password-code')
  }

  const handleChange = (e: any) => {
    setUserValue(e.target.value as string)
  }

  return (
    <form className="form" onSubmit={e => { handleLogin(e) }}>
     <IonCard className="login-card ion-padding">
        <IonList className="login-list">
          <IonText color="dark"><h2 className="login-card-title">Recuperación de contraseña</h2></IonText>
          <Input
            label="Usuario / Correo"
            type="text"
            name="text"
            value={userValue}
            onChange={e => { handleChange(e) }}
          />
          <IonText color="dark"><p className="ion-text-start">Olvidaste tu usuario? <Link to="/forgot-user"> Click aquí</Link></p></IonText>
          <IonButton color="secondary" shape="round" expand="block" type="submit">Enviar código</IonButton>
          <p className="ion-text-center">¿Recordaste tu contraseña? <Link to="/login">Ingresa aquí</Link></p>
        </IonList>
      </IonCard>
    </form>
  )
}
