import { IonButtons, IonLabel, IonMenuButton, IonSegment, IonSegmentButton } from '@ionic/react'
import { atSharp, businessSharp, cardSharp, cashSharp, earthSharp, globeSharp, idCardSharp, languageSharp, laptopSharp, mapSharp, notificationsSharp, peopleSharp, personCircleSharp, personSharp, phonePortraitSharp, timeSharp } from 'ionicons/icons'
import { useEffect, useState } from 'react'
import { ProfileUserAvatar } from '../../components/Molecules/ProfileUserAvatar/ProfileUserAvatar'
import { SectionListOfData } from '../../components/Organisms/SectionListOfData/SectionListOfData'
import { WrapperApp } from '../../components/Organisms/WrapperApp/WrapperApp'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { saveRolUserActive } from '../../store/states/roleUserActive'

export const ProfileCommerce = () => {
  const dispatch = useAppDispatch()
  const commerce = useAppSelector(store => store.commerce)
  const roleUser = useAppSelector(store => store.roleUserActive)
  const [segment, setSegment] = useState<string>('profile')

  const dataPersonalCommerce = [
    {
      title: 'Nombre de usuario',
      result: commerce.username,
      icon: personCircleSharp,
      disabled: true
    },
    {
      title: 'Nombre de la compañia',
      result: `${commerce.companyName}`,
      icon: personSharp,
      routerLink: '1',
      typeProfile: 'comercio/datos-comercio'
    },
    {
      title: 'ID de cuenta',
      result: commerce.accountId,
      icon: idCardSharp,
      disabled: true,
      routerLink: '2',
      typeProfile: 'comercio/datos-comercio'
    },
    {
      title: 'Número RNC / RIF (DNI)',
      result: `${commerce.identities.map(identity => `${identity.type}-${identity.document}`).join(', ')}`,
      icon: cardSharp,
      routerLink: '3',
      typeProfile: 'comercio/datos-comercio'
    },
    {
      title: 'Teléfono(s)',
      result: `${commerce.phones.map(phone => ` ${phone.phone}`).join(', ')}`,
      icon: phonePortraitSharp,
      routerLink: '4',
      typeProfile: 'comercio/datos-comercio'
    },
    {
      title: 'Correo(s)',
      result: `${commerce.emails.map(email => ` ${email.email}`).join(', ')}`,
      icon: atSharp,
      routerLink: '5',
      typeProfile: 'comercio/datos-comercio'
    },
    {
      title: 'Redes sociales',
      result: `${commerce.socialmedia.map(social => ` ${social.social}`).join(', ')}`,
      icon: globeSharp,
      routerLink: '6',
      typeProfile: 'comercio/datos-comercio'
    },
    {
      title: 'Horario',
      result: commerce.shedules,
      icon: timeSharp,
      routerLink: '7',
      typeProfile: 'comercio/datos-comercio'
    },
    {
      title: 'País',
      result: `Origen: ${commerce.country.origin.label}, Residencia: ${commerce.country.residence.label}`,
      icon: earthSharp,
      routerLink: '8',
      typeProfile: 'comercio/datos-comercio'
    },
    {
      title: 'Dirección',
      result: commerce.direction.map(address => address.address).join(' / '),
      icon: mapSharp,
      routerLink: '9',
      typeProfile: 'comercio/datos-comercio'
    },
    {
      title: 'Empresas',
      result: commerce.business.map(businessItem => businessItem.username).join(' / '),
      icon: businessSharp,
      routerLink: '10',
      typeProfile: 'comercio/datos-comercio'
    }
  ]

  const dataCommerce = [
    {
      title: 'Empleados',
      icon: peopleSharp,
      routerLink: '1',
      typeProfile: 'comercio/business'
    },
    {
      title: 'Agentes',
      icon: personSharp,
      routerLink: '2',
      typeProfile: 'comercio/business'
    },
    {
      title: 'Dispositivos',
      icon: laptopSharp,
      routerLink: '3',
      typeProfile: 'comercio/business'
    }
  ]

  const dataUserPreference = [
    {
      title: 'Notificaciones',
      icon: notificationsSharp,
      routerLink: '1',
      typeProfile: 'comercio/preference'
    },
    {
      title: 'Canal de uso preferido',
      icon: cardSharp,
      routerLink: '2',
      typeProfile: 'comercio/preference'
    },
    {
      title: 'Moneda determinada',
      icon: cashSharp,
      routerLink: '3',
      typeProfile: 'comercio/preference'
    },
    {
      title: 'Idioma',
      icon: languageSharp,
      routerLink: '4',
      typeProfile: 'comercio/preference'
    }
  ]

  const dataUserBanksAndCards = [
    {
      title: 'Cuentas bancarias',
      icon: businessSharp,
      routerLink: '1',
      typeProfile: 'comercio/bancos-y-tarjetas'
    },
    {
      title: 'Tarjetas',
      icon: cardSharp,
      routerLink: '2',
      typeProfile: 'comercio/bancos-y-tarjetas'
    }
  ]

  useEffect(() => {
    if (roleUser.id.toString() === '1') {
      dispatch(saveRolUserActive({ id: '2', type: 'commerce' }))
    }
  }, [])

  return (
    <WrapperApp pageName="Perfil Juridico" renderButtons={() =>
      <IonButtons slot="start">
        <IonMenuButton />
      </IonButtons>}
    >
      <ProfileUserAvatar avatar={commerce.avatar} portrait={commerce.portraitPhoto} />
      <IonSegment value={segment} scrollable onIonChange={e => { setSegment(e.detail.value as string) }}>
        <IonSegmentButton value="profile"><IonLabel>Datos Basicos</IonLabel></IonSegmentButton>
        <IonSegmentButton value="preference"><IonLabel>Preferencia</IonLabel></IonSegmentButton>
        <IonSegmentButton value="commerce"><IonLabel>Comercio</IonLabel></IonSegmentButton>
        <IonSegmentButton value="banks"><IonLabel>Bancos y Tarjetas</IonLabel></IonSegmentButton>
      </IonSegment>
      {segment === 'profile' && <SectionListOfData titleSection="Datos del usuario jurídico" dataList={dataPersonalCommerce} />}
      {segment === 'commerce' && <SectionListOfData titleSection="Datos Comercios" dataList={dataCommerce} />}
      {segment === 'preference' && <SectionListOfData titleSection="Preferencia" dataList={dataUserPreference} />}
      {segment === 'banks' && <SectionListOfData titleSection="Bancos y Tarjetas" dataList={dataUserBanksAndCards} />}
    </WrapperApp>
  )
}
