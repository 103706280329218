import { FormRecoverPassword } from './components/FormRecoverPassword/FormRecoverPassword'
import WrapperFormBackground from '../../components/Organisms/WrapperFormBackground/WrapperFormBackground'

export const RecoverPassword = () => {
  return (
    <WrapperFormBackground title="Recuperación de contraseña">
      <FormRecoverPassword />
    </WrapperFormBackground>
  )
}
