import { FormForgotPasswordCode } from './components/FormForgotPasswordCode/FormForgotPasswordCode'
import WrapperFormBackground from '../../components/Organisms/WrapperFormBackground/WrapperFormBackground'

export const ForgotPasswordCode = () => {
  return (
    <WrapperFormBackground title="Recuperación de contraseña">
      <FormForgotPasswordCode />
    </WrapperFormBackground>
  )
}
