import { IonAvatar, IonCard, IonIcon, IonImg, IonItem, IonLabel, IonList, IonText } from '@ionic/react'
import { addCircle, bagSharp } from 'ionicons/icons'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { TitleForm } from '../../../../components/Atoms/TitleForm'
import ModalSmall from '../../../../components/Organisms/ModalSmall/ModalSmall'
import type { AccountBank } from '../../../../models/user-banks'
import { useAppSelector } from '../../../../store/hooks'
import { FormBanksAccount } from '../FormBanksAccount'

interface HandleBanksParams {
  isEdit: boolean
  itemEdit?: AccountBank
}

const ListOfBankAccount: React.FC = () => {
  const userBanks = useAppSelector(store => store.userBanks)
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [itemEdit, setItemEdit] = useState<AccountBank>()

  const handleBanks = ({ isEdit, itemEdit }: HandleBanksParams) => {
    setIsOpenModal(true)
    setIsEdit(isEdit)
    if (isEdit) setItemEdit(itemEdit)
  }

  return (
		<>
			<IonCard className="box ion-padding">
				<div>
					<TitleForm title="Cuentas bancarias" customClass="ion-margin-start" />
					<IonList>
						{userBanks.banks.map(bank =>
							<IonItem button={true} detail={true} key={bank.id} onClick={() => { handleBanks({ isEdit: true, itemEdit: bank }) }}>
								<IonAvatar slot="start" className="flex ion-justify-content-center ion-align-items-center">
									<IonImg alt={bank.name} src={bank.icon} style={{ width: '30px', height: '30px' }} />
								</IonAvatar>
								<IonLabel>
									<h2>{bank.name}</h2>
									<p>{ bank.accountNumber}</p>
								</IonLabel>
							</IonItem>
						)}
					</IonList>

					<IonItem lines="none">
						<IonText color="secondary" ><p className="ion-padding-end"><Link to="/aperturar-cuenta">Aperturar una cuenta </Link></p></IonText>
						<IonIcon icon={bagSharp}size="small " />
					</IonItem>
				</div>
				<IonItem lines="none">
					<IonIcon icon={addCircle} slot="end" size="large" onClick={() => { handleBanks({ isEdit: false }) }}/>
				</IonItem>
			</IonCard>
			<ModalSmall isOpen={isOpenModal} onDidDismiss={() => { setIsOpenModal(false) }}>
				<FormBanksAccount isEdit={isEdit} itemEdit={itemEdit} closeModal={() => { setIsOpenModal(false) }} />
			</ModalSmall>
		</>
  )
}

export default ListOfBankAccount
