import { Geolocation, type Position } from '@capacitor/geolocation'
import { useEffect, useState } from 'react'

const useGetCurrentPosition = () => {
  const [currentLocation, setCurrentLocation] = useState<Position>()
  const [loadingCurrentLocation, setLoadingCurrentLocation] = useState(true)

  useEffect(() => {
    const printCurrentPosition = async () => {
      const coordinates = await Geolocation.getCurrentPosition()
      setCurrentLocation(coordinates)
      setLoadingCurrentLocation(false)
    }

    printCurrentPosition()
  }, [])

  return { loadingCurrentLocation, currentLocation, setLoadingCurrentLocation }
}

export default useGetCurrentPosition
