export enum StatusProduct {
  ALL = 'all',
  ACTIVE = 'active',
  RESERVED = 'reserve',
  ARCHIVED = 'archived'
}

export interface Product {
  id: number
  image: string
  name: string
  status: StatusProduct
  inventary: string
  type: string
  seller: string
}
