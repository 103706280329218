import { IonButtons, IonCol, IonGrid, IonLabel, IonMenuButton, IonRow, IonSegment, IonSegmentButton } from '@ionic/react'
import { useState } from 'react'
import { WrapperApp } from '../../components/Organisms/WrapperApp/WrapperApp'
import { HeaderDashboard } from '../Dashboard/components/HeaderDashboard'
import { SalesSummary } from './components/SalesSummary/SalesSummary'
import { CurrentDrawer } from './components/CurrentDrawer/CurrentDrawer'
import { DrawerHistory } from './components/DrawerHistory/DrawerHistory'

export function Reports () {
  const [segment, setSegment] = useState('sales_summary')

  return (
    <>
    <WrapperApp
      pageName="Reportes"
      hiddenAvatar={true}
      renderButtons={() => (
        <>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <HeaderDashboard />
        </>
      )}
    >
      <IonGrid>
        <IonRow>
          <IonCol size="12" size-md="4" size-lg="4" size-xl="3">
            <IonSegment color='secondary' value={segment} scrollable onIonChange={e => { setSegment(e.detail.value as string) }} className='ion-padding-horizontal' style={{ width: '100%', display: 'block' }}>
              <IonSegmentButton value={'sales_summary'}><IonLabel>Sales Summary</IonLabel></IonSegmentButton>
              <IonSegmentButton value={'current_drawer'}><IonLabel>Current drawer</IonLabel></IonSegmentButton>
              <IonSegmentButton value={'drawer_history'}><IonLabel>Drawer history</IonLabel></IonSegmentButton>
            </IonSegment>
          </IonCol>
          { segment === 'sales_summary' &&
            <SalesSummary />
          }
          { segment === 'current_drawer' &&
            <CurrentDrawer />
          }
          { segment === 'drawer_history' &&
            <DrawerHistory />
          }
        </IonRow>
      </IonGrid>
    </WrapperApp>
    </>
  )
}
