import { IonButton, IonCard, IonIcon, IonList, IonText } from '@ionic/react'
import { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Input } from '../../../../components/Atoms/Input/Input'
import ModalSmall from '../../../../components/Organisms/ModalSmall/ModalSmall'
import { mailOpenSharp } from 'ionicons/icons'
import './FormForgotUser.css'

export const FormForgotUser = () => {
  const [userValue, setUserValue] = useState<string | null>('')
  const [openModal, setOpenModal] = useState(false)
  const history = useHistory()

  const handleLogin = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setOpenModal(true)
  }

  const handleChange = (e: any) => {
    setUserValue(e.target.value as string)
  }

  const dismissModal = () => {
    history.push('/login')
    setOpenModal(false)
  }

  return (
    <>
    <form className="form" onSubmit={e => { handleLogin(e) }}>
     <IonCard className="login-card ion-padding">
        <IonList className="login-list">
          <IonText color="dark"><h2 className="login-card-title">Recuperación de usuario</h2></IonText>
          <Input
            label="Correo"
            type="text"
            name="text"
            value={userValue}
            onChange={e => { handleChange(e) }}
          />
          <IonButton color="secondary" shape="round" expand="block" type="submit">Recuperar</IonButton>
          <p className="ion-text-center">¿Recordaste tu usuario? <Link to="/login">Ingresa aquí</Link></p>
        </IonList>
      </IonCard>
    </form>
    <ModalSmall isOpen={openModal} onDidDismiss={() => { dismissModal() }}>
      <IonList className="ion-text-center">
        <IonIcon icon={mailOpenSharp} className="icon-modal-user"></IonIcon>
        <IonText><h3>Recuperación de usuario</h3></IonText>
        <IonText><p className="text-modal-user">Se ha enviado su nombre de ususario al correo abc@mail.com</p></IonText>
        <IonText color="dark"><p className="ion-text-center" onClick={() => { dismissModal() }}>Aceptar</p></IonText>
      </IonList>
    </ModalSmall>
    </>
  )
}
