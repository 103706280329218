import { IonButton, IonCard, useIonToast } from '@ionic/react'
import { Input } from '../../../../components/Atoms/Input/Input'
import { TitleForm } from '../../../../components/Atoms/TitleForm'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import { saveCustomGrid } from '../../../../store/states/customGrid'

export function CustomGrid () {
  const dispatch = useAppDispatch()
  const customGridValue = useAppSelector(store => store.customGrid)
  const [present] = useIonToast()

  console.log({ customGridValue })
  const handleSaveGrid = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    dispatch(saveCustomGrid(2))
    present({
      message: 'Grid actualizado',
      duration: 2500,
      position: 'bottom',
      color: 'success'
    })
  }

  return (
		<form onSubmit={e => { handleSaveGrid(e) }}>
		<IonCard className="box ion-padding">
			<TitleForm title='Custumizar grid de punto de venta' customClass='ion-margin-bottom'/>
			<div style={{ flex: 1, display: 'flex', gap: '1rem', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
				<div style={{ flex: 1 }}>
					<Input label="Numero de grid" type="number" name="grid" noSpace maxlength={2} />
				</div>
				<IonButton color="secondary" shape="round" type='submit'>
					Guardar
				</IonButton>
			</div>
			</IonCard>
			</form>
  )
}
