import { IonAvatar, IonButtons, IonContent, IonHeader, IonIcon, IonImg, IonTitle, IonToolbar } from '@ionic/react'
import { menuOutline } from 'ionicons/icons'
import { useEffect, useRef, useState } from 'react'
import { useWindowSize } from '../../../hooks/useWindowSize'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { changeToggleMenu } from '../../../store/states/toggleMenu'
import { PopoverChangeCommerce } from '../PopoverChangeCommerce'

interface PageHeaderProps {
  pageName: string
  hiddenAvatar?: boolean
  children?: React.ReactNode
  renderButtons?: () => React.ReactNode
  renderFooter?: () => React.ReactNode
}

export const PageHeader: React.FC<PageHeaderProps> = ({ pageName, children, renderButtons, renderFooter, hiddenAvatar }) => {
  const commerce = useAppSelector(store => store.commerce)
  const popover = useRef<HTMLIonPopoverElement | null>(null)
  const [popoverOpen, setPopoverOpen] = useState(false)
  const isMenuSmall = useAppSelector(store => store.toggleMenu)
  const dispatch = useAppDispatch()
  const windowSize = useWindowSize()

  const openPopover = (e: React.MouseEvent<HTMLIonAvatarElement, MouseEvent>) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    popover.current!.event = e
    setPopoverOpen(true)
  }

  useEffect(() => {
    return () => { setPopoverOpen(false) }
  }, [])

  const handleChangeMenuMode = () => {
    document.body.classList.toggle('menu-small')
    dispatch(changeToggleMenu(!isMenuSmall))
  }

  return (
    <>
      <IonHeader>
        <IonToolbar>
        {(windowSize?.width) > 992 &&
        <IonButtons slot="start">
          <div className='toggle-menu' onClick={() => { handleChangeMenuMode() }}>
            <IonIcon slot="start" icon={menuOutline} />
          </div>
        </IonButtons>}
          {renderButtons?.()}
          <IonTitle className="ion-text-center">{ pageName }</IonTitle>
          {!hiddenAvatar &&
          <IonAvatar slot="end" className="flex" onClick={openPopover}>
            <IonImg src={commerce.avatar} alt={commerce.companyName} style={{ width: 40, height: 40 }} className="ion-align-self-center" />
          </IonAvatar>}

          <PopoverChangeCommerce
            popover={popover}
            popoverOpen={popoverOpen}
            setPopoverOpen={setPopoverOpen}
          />
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen >
        { children }
      </IonContent>
      {renderFooter?.()}
    </>
  )
}
