import { Redirect } from 'react-router'
import { useAppSelector } from '../../store/hooks'
import { type PropsRoute } from './Routes'

export const PublicRouter = ({ children }: PropsRoute) => {
  const user = useAppSelector(store => store.user)
  const isLogged = Boolean(user?.id)

  return isLogged ? <Redirect to="/" /> : children
}
