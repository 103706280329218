import { IonCard, IonCol, IonGrid, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonList, IonRow } from '@ionic/react'
import { book, card, fastFood, laptop, medkit, pricetags, search, shirt } from 'ionicons/icons'
import { useState } from 'react'
import { TitleForm } from '../../../../components/Atoms/TitleForm'
import { useAppSelector } from '../../../../store/hooks'
import { Calculator } from '../../../Dashboard/components/Calculator'

interface ItemLibrary {
  id: number
  title: string
  icon: string
  link: string
}

const listLibrary: ItemLibrary[] = [
  {
    id: 1,
    title: 'Descuento',
    icon: pricetags,
    link: ''
  },
  {
    id: 2,
    title: 'Gift card',
    icon: card,
    link: ''
  },
  {
    id: 3,
    title: 'Libros',
    icon: book,
    link: ''
  },
  {
    id: 4,
    title: 'Comida',
    icon: fastFood,
    link: ''
  },
  {
    id: 5,
    title: 'Ropa',
    icon: shirt,
    link: ''
  },
  {
    id: 6,
    title: 'Tecnología',
    icon: laptop,
    link: ''
  },
  {
    id: 7,
    title: 'Cuidado personal',
    icon: medkit,
    link: ''
  }
]

export function Library () {
  const [searchValue, setSearchValue] = useState<string>('')
  const posState = useAppSelector(store => store.pos)

  return (
    <IonGrid>
      <IonRow>
				{ !posState.showCalculator &&
					<IonCol size="12" size-md="6" size-lg="7" size-xl="7">
						<IonCard className='card ion-padding'>
							<IonHeader>
								<IonItem fill='solid' style={{ width: '100%' }} lines='none' className="ion-no-margin">
									<IonIcon slot="start" icon={search} />
									<IonInput name='search' placeholder='Buscar' type='search' value={searchValue} onIonChange={(e: any) => { setSearchValue(e.target.value) }} />
								</IonItem>
							</IonHeader>
							<IonList>
								{listLibrary.map(library =>
									<IonItem button detail key={library.id}>
										<IonIcon slot="start" icon={library.icon} />
										<IonLabel>{library.title}</IonLabel>
									</IonItem>
								)}
							</IonList>
						</IonCard>
					</IonCol>
      	}
				{ posState.showCalculator &&
         	<IonCol size="12" size-md="6" size-lg="7" size-xl="7">
          	<Calculator />
        	</IonCol>
        }
        <IonCol size="12" size-md="6" size-lg="5" size-xl="5">
          <IonCard className='card ion-padding'>
            <TitleForm customClass="" title='Sin ventas' />
          </IonCard>
        </IonCol>
      </IonRow>
    </IonGrid>
  )
}
