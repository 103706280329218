import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { type RoleUserActive } from '../../models'
import { getLocalStorage, setLocalStorage } from '../../utilities/localStorage.utility'

const initialState: RoleUserActive = {
  id: '1',
  type: 'natural'
}

export const roleUserActiveSlice = createSlice({
  name: 'role-user-active',
  initialState: getLocalStorage('role-user-active') ? JSON.parse(getLocalStorage('role-user-active') as string) : initialState,
  reducers: {
    saveRolUserActive (state: RoleUserActive, action: PayloadAction<RoleUserActive>) {
      setLocalStorage('role-user-active', action.payload)
      return { ...state, ...action.payload }
    }
  }
})

export const {
  saveRolUserActive
} = roleUserActiveSlice.actions
export default roleUserActiveSlice.reducer
