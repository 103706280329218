import chip from '../../../assets/card-chip.png'
import visa from '../../../assets/card-visa.png'
import { useAppSelector } from '../../../store/hooks'
import './CardBankUI.css'

export interface Props {
  cardNumber: string | number
  cardDate: string | number
  cardType: string
}

const CardBankUI: React.FC<Props> = ({ cardNumber, cardDate, cardType }) => {
  const user = useAppSelector(store => store.user)

  return (
    <div className="container">
      <div className="card-container">
        <div className="front">
          <div className="image">
            <img src={chip} alt="" />
            <img src={visa} alt="" />
          </div>
          <div className="card-number-box">{ cardNumber || '################' }</div>
          <div className="flexbox">
            <div className="box">
              <span>{ cardType || '################' }</span>
              <div className="card-holder-name">{ user.firstName.toUpperCase() } { user.lastName.toUpperCase() }</div>
            </div>
            <div className="box">
              <span>valida hasta:</span>
              <div className="expiration">
                <span className="exp-year"> { cardDate || 'yy-mm-dd' }</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CardBankUI
