import { IonAvatar, IonButton, IonCard, IonImg, IonList, IonText, useIonToast } from '@ionic/react'
import { useState } from 'react'
import { BusinessItem } from '../../../../components/Atoms/BusinessItem'
import { TitleForm } from '../../../../components/Atoms/TitleForm'
import ModalSmall from '../../../../components/Organisms/ModalSmall/ModalSmall'
import { type Commerce } from '../../../../models/commerce'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import { saveCommerce } from '../../../../store/states/commerce'

const ListOfBusiness: React.FC = () => {
  const commerce = useAppSelector(store => store.commerce)
  const dispatch = useAppDispatch()
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [commerceSelect, setCommerceSelect] = useState<Commerce>(commerce)
  const [present] = useIonToast()

  const handleOpenModal = (commerceSelect: Commerce) => {
    if (commerce.id === commerceSelect.id) return
    setCommerceSelect(commerceSelect)
    setIsOpenModal(true)
  }

  const handleClick = () => {
    const itemCommerce = { ...commerceSelect, business: commerce.business }
    dispatch(saveCommerce(itemCommerce))
    setIsOpenModal(false)
    present({
      message: `Sesión iniciada con: ${commerceSelect.companyName}`,
      duration: 1500,
      position: 'top'
    })
  }

  return (
		<>
			<IonCard className="box ion-padding">
				<div>
					<TitleForm title="Empresas" customClass="ion-margin-start" />
					<IonList>
						{commerce.business.map(commerceItem =>
							<BusinessItem key={Number(commerceItem.id)} businessItem={commerceItem} handleClick={() => { handleOpenModal(commerceItem) }} />
						)}
					</IonList>
				</div>
			</IonCard>
			<ModalSmall isOpen={isOpenModal} onDidDismiss={() => { setIsOpenModal(false) }}>
				<div className="form ion-text-center ion-padding">
					<div className="flex ion-justify-content-center">
						<IonAvatar className="flex ion-justify-content-center">
							<IonImg src={commerceSelect?.avatar} alt={commerceSelect?.companyName} style={{ width: '65px', height: '65px', boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)' }} className="ion-align-self-center" />
						</IonAvatar>
					</div>
					<IonText color="dark"><p className="ion-padding">{`Estás iniciando sesión en ${commerceSelect?.companyName}`}</p></IonText>
					<div>
						<IonButton color="secondary" shape="round" onClick={() => { handleClick() }}>Aceptar</IonButton>
						<IonButton color="secondary" shape="round" fill="clear" onClick={() => { setIsOpenModal(false) }}>Cancelar</IonButton>
					</div>
				</div>
			</ModalSmall>
		</>
  )
}

export default ListOfBusiness
