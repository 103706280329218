import { IonActionSheet, IonCard, IonItem, IonLabel, IonList, IonToggle } from '@ionic/react'
import { useState } from 'react'
import { TitleForm } from '../../../../components/Atoms/TitleForm'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import { disabledNotificationsPreference, resetPreference } from '../../../../store/states/userPreference'
import { ListToggleNotificationsEmail } from './components/ListToggleNotificationsEmail'
import { ListToggleNotificationsInApp } from './components/ListToggleNotificationsInApp'
import { ListToggleNotificationsPush } from './components/ListToggleNotificationsPush'
import { ListToggleNotificationsSms } from './components/ListToggleNotificationsSms'

const ListNotifications: React.FC = () => {
  const userPreference = useAppSelector(store => store.userPreference)
  const dispatch = useAppDispatch()
  const [showAllNotifications, setShowAllNotifications] = useState<boolean>(userPreference.notifications.showAllNotifications)
  const [showActionSheet, setShowActionSheet] = useState<boolean>(false)

  const onChangeShowNotification = (e: any) => {
    setShowAllNotifications(e.detail.checked)
    if (e.detail.checked) {
      dispatch(resetPreference())
    } else {
      dispatch(disabledNotificationsPreference())
    }
  }

  const handleResetSetting = () => {
    dispatch(resetPreference())
  }

  return (
		<>
			<IonCard className="box ion-padding">
				<div>
					<TitleForm title="Notificaciones" customClass="ion-margin-start" />
					<IonList>
						<IonItem>
							<IonLabel>Mostrar notificaciones</IonLabel>
							<IonToggle color="secondary" checked={showAllNotifications} slot="end" onIonChange={e => { onChangeShowNotification(e) }}></IonToggle>
						</IonItem>
						<IonItem disabled={!showAllNotifications} button={true} detail={showAllNotifications} id="open-modal-in-app">In-App</IonItem>
						<IonItem disabled={!showAllNotifications} button={true} detail={showAllNotifications} id="open-modal-push">Push</IonItem>
						<IonItem disabled={!showAllNotifications} button={true} detail={showAllNotifications} id="open-modal-email">Email</IonItem>
						<IonItem disabled={!showAllNotifications} button={true} detail={showAllNotifications} id="open-modal-sms">SMS</IonItem>
						<IonItem lines="none" disabled={!showAllNotifications} button={true} detail={showAllNotifications} onClick={() => { setShowActionSheet(true) }}>Reset notification setting</IonItem>
					</IonList>
				</div>
			</IonCard>
			<ListToggleNotificationsInApp />
			<ListToggleNotificationsPush />
			<ListToggleNotificationsEmail />
			<ListToggleNotificationsSms />
			<IonActionSheet
        isOpen={showActionSheet}
        onDidDismiss={() => { setShowActionSheet(false) }}
				header='Se restablecerá la configuración inicial de notifiaciones'
        buttons={[
          {
            text: 'Restablecer Notificaciones',
            role: 'destructive',
            handler: handleResetSetting
          },
          {
            text: 'Cancelar',
            role: 'cancel'
          }]}
      >
      </IonActionSheet>
		</>
  )
}

export default ListNotifications
