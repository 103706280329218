import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { setLocalStorage } from '../../utilities'

const initialState = 0

export const totalBalanceSlice = createSlice({
  name: 'total-balance',
  initialState,
  reducers: {
    changeTotalBalance (_state, action: PayloadAction<number>) {
      setLocalStorage('total-balance', action.payload)
      return action.payload
    }
  }
})

export const {
  changeTotalBalance
} = totalBalanceSlice.actions

export default totalBalanceSlice.reducer
