import {
  IonList,
  IonItem,
  IonLabel,
  IonImg,
  IonIcon,
  IonBadge
} from '@ionic/react'
import { notificationsSharp } from 'ionicons/icons'
import iconE4coin from '../../../../assets/iconE4coin.svg'
import { useRef, useState } from 'react'
import { PopoverNotification } from '../PopoverNotification'
import { useAppSelector } from '../../../../store/hooks'

const HeaderDashboard: React.FC = () => {
  const popover = useRef<HTMLIonPopoverElement>(null)
  const [popoverOpen, setPopoverOpen] = useState(false)
  const totalBalance = useAppSelector(store => store.totalBalance)
  const openPopover = (e: any) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    popover.current!.event = e
    setPopoverOpen(true)
  }

  return (
    <>
      <IonList slot="end">
        <IonItem lines="none">
          <IonLabel>
            <span>Disponible</span>
            <p className="flex" style={{ gap: '10px' }}>
              <span>{ totalBalance }</span>{' '}
              <IonImg
                alt="icon e4coin"
                src={iconE4coin}
                style={{ width: '16px' }}
              />
            </p>
          </IonLabel>
          <IonItem
            style={{ position: 'relative' }}
            lines="none"
            className="ion-no-padding"
            onClick={openPopover}
          >
            <IonIcon slot="end" md={notificationsSharp} />
            <div style={{ position: 'absolute', top: 0, right: '10px' }}>
              <IonBadge color="danger">2</IonBadge>
            </div>
          </IonItem>
        </IonItem>
      </IonList>
      <PopoverNotification
        popover={popover}
        popoverOpen={popoverOpen}
        setPopoverOpen={setPopoverOpen}
      />
    </>
  )
}

export default HeaderDashboard
