import { IonBackButton, IonButtons } from '@ionic/react'
import { Redirect, useRouteMatch } from 'react-router'
import { WrapperApp } from '../../components/Organisms/WrapperApp/WrapperApp'
import { ListOfAgent } from './components/ListOfAgent'
import { ListOfDispositives } from './components/ListOfDispositives'
import { ListOfEmployees } from './components/ListOfEmployees'

interface MatchParams {
  section: string
}

const EditCommerceBusiness: React.FC = () => {
  const routerMatch = useRouteMatch<MatchParams>()

  const renderComponent = (id: string) => {
    switch (id) {
      case '1': return <ListOfEmployees />
      case '2': return <ListOfAgent />
      case '3': return <ListOfDispositives />
      default: return <Redirect to="/perfil-comercio" />
    }
  }

  return (
		<WrapperApp
			pageName="Perfil Juridico"
			renderButtons={() =>
			<IonButtons slot="start">
				<IonBackButton defaultHref="/perfil-comercio" />
			</IonButtons>}
		>
			{renderComponent(routerMatch.params.section)}
		</WrapperApp>
  )
}

export default EditCommerceBusiness
