import { IonButton, IonText, useIonToast } from '@ionic/react'
import { useState } from 'react'
import { Input } from '../../../../components/Atoms/Input/Input'
import { TitleForm } from '../../../../components/Atoms/TitleForm'

export interface Props {
  closeModal: () => void
}

const FormInviteEmployee: React.FC<Props> = ({ closeModal }) => {
  const [present] = useIonToast()
  const [inviteEmployee, setInviteEmployee] = useState('')

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    closeModal()
    // run services edit here!
    present({
      message: 'Invitado',
      duration: 2500,
      position: 'bottom',
      color: 'success'
    })
  }

  const handleChange = (e: any) => {
    setInviteEmployee(e.target.value)
  }

  return (
		<div className="box ion-padding">
			<form className="form" onSubmit={e => { handleSubmit(e) }}>
				<TitleForm title="Invitar empleado" />
				<Input label="Teléfono o correo electronico" type="text" name="inviteEmployee" value={inviteEmployee} onChange={e => { handleChange(e) }}/>
				<div className="ion-padding">
					<IonText>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</IonText>
				</div>
				<div className="ion-text-end">
					<IonButton color="secondary" shape="round" type="submit">Invitar</IonButton>
					<IonButton color="secondary" shape="round" fill="clear" type="button" onClick={() => { closeModal() }}>Cancelar</IonButton>
				</div>
			</form>
		</div>
  )
}

export default FormInviteEmployee
