import { IonButton, IonIcon, IonItem, IonLabel, IonList } from '@ionic/react'
import { closeOutline } from 'ionicons/icons'
import { TitleForm } from '../../../../components/Atoms/TitleForm'
import { Input } from '../../../../components/Atoms/Input/Input'

interface Props {
  setIsOpenModalPaymentPersonalize: React.Dispatch<React.SetStateAction<boolean>>
  setIsOpenModalPaymentPersonalizeEnterAmount: React.Dispatch<React.SetStateAction<boolean>>
}

export function ModalPaymentPersonalize ({ setIsOpenModalPaymentPersonalize, setIsOpenModalPaymentPersonalizeEnterAmount }: Props) {
  const handleContinue = () => {
    setIsOpenModalPaymentPersonalize(false)
    setIsOpenModalPaymentPersonalizeEnterAmount(true)
  }
  return (
		<div style={{ minWidth: '100%' }}>
			<IonItem className='ion-no-padding ion-no-margin' lines='none'>
        <IonIcon icon={closeOutline} size='small' slot='start' onClick={() => { setIsOpenModalPaymentPersonalize(false) }} className='ion-padding-start' />
      </IonItem>
			<div>
				<IonItem className='ion-no-padding ion-no-margin ion-text-center ion-padding-start' lines='none'>
					<IonLabel>
						Después de una transacción, quedará un monento de $0.00 de un total de $3.551.00
					</IonLabel>
				</IonItem>
				<div>
					<TitleForm title='Dividir por monto' customClass='ion-padding-start' />
					<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '0', padding: '0 1rem' }}>
						<div style={{ flex: 1 }}>
							<Input noSpace label="Monto del pago" type="text" name="ammout" value={'1357.00'} onChange={e => { console.log(e) }} />
						</div>
						<IonButton color="secondary" expand="block" fill='outline' onClick={() => { handleContinue() }} style={{ minHeight: '100%' }}>Continuar</IonButton>
					</div>
				</div>
				<IonList>
					<TitleForm title='Dividir en pagos iguales' customClass='ion-padding-start' />
					<div>
						<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '1rem', marginTop: '2rem', marginBottom: '1rem', padding: '0 1rem' }}>
							<IonButton color="secondary" expand="block" fill='outline' onClick={() => null } style={{ flex: 1 }}>2</IonButton>
							<IonButton color="secondary" expand="block" fill='outline' onClick={() => null } style={{ flex: 1 }}>3</IonButton>
							<IonButton color="secondary" expand="block" fill='outline' onClick={() => null } style={{ flex: 1 }}>4</IonButton>
						</div>
					</div>
					<div style={{ padding: '0 1rem' }}>
						<IonButton color="secondary" expand="block" fill='outline' onClick={() => null } style={{ flex: 1 }}>Personalizar</IonButton>
					</div>
				</IonList>
			</div>
    </div>
  )
}
