import { IonButton, IonText, useIonToast } from '@ionic/react'
import { useState } from 'react'
import { Input } from '../../../../components/Atoms/Input/Input'
import { TitleForm } from '../../../../components/Atoms/TitleForm'
import type { Agent } from '../../../../models'

export interface Props {
  isEdit: boolean
  itemEdit?: Agent
  closeModal: () => void
}

const FormAgent: React.FC<Props> = ({ isEdit, itemEdit, closeModal }) => {
  const [present] = useIonToast()
  const initialValue = isEdit ? { ...itemEdit, name: itemEdit?.name } : { name: '' }
  const [agent, setAgent] = useState<Agent>(initialValue as Agent)

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    closeModal()

    // run services edit here!
    present({
      message: isEdit ? 'Datos guardados' : 'Dato creado',
      duration: 2500,
      position: 'bottom',
      color: 'success'
    })
  }

  const handleChange = (e: any) => {
    setAgent(prevValue => ({
      ...prevValue,
      [e.target.name]: e.target.value
    }))
  }

  return (
		<div className="box ion-padding">
			<form className="form" onSubmit={e => { handleSubmit(e) }}>
				<TitleForm title={`${isEdit ? 'Editar' : 'Agregar'} agente`} />
				{isEdit && <Input disabled label="ID" type="text" name="id" value={agent.id} />}
				<Input label="Nombre" type="text" name="name" value={agent.name} onChange={e => { handleChange(e) }}/>
				{!isEdit &&
				<div className="ion-padding-bottom">
					<IonText>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</IonText>
				</div>}
				<div className="ion-text-end">
					<IonButton color="secondary" shape="round" type="submit">{isEdit ? 'Guardar' : 'Agregar'}</IonButton>
					<IonButton color="secondary" shape="round" fill="clear" type="button" onClick={() => { closeModal() }}>Cancelar</IonButton>
				</div>
			</form>
		</div>
  )
}

export default FormAgent
