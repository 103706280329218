import { Redirect } from 'react-router'
import { useAppSelector } from '../../store/hooks'
import { type PropsRoute } from './Routes'

export const PrivateRouter = ({ children }: PropsRoute) => {
  const user = useAppSelector(store => store.user)
  const isLogged = Boolean(user?.id)

  return isLogged ? children : <Redirect to="/login" />
}
