import { IonAvatar, IonButton, IonCard, IonIcon, IonImg, IonItem, IonLabel, IonList, IonModal, IonNote, IonText } from '@ionic/react'
import { Form, Formik, type FormikHelpers } from 'formik'
import { fingerPrintSharp, scanSharp } from 'ionicons/icons'
import { useRef, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import * as yup from 'yup'
import { Input } from '../../../../components/Atoms/Input/Input'
import { IonTextError } from '../../../../components/Atoms/IonTextError'
import { LoginSocialMedia } from '../../../../components/Molecules/LoginSocialMedia/LoginSocialMedia'
import ModalSmall from '../../../../components/Organisms/ModalSmall/ModalSmall'
import type { Commerce } from '../../../../models/commerce'
import type { User } from '../../../../models/user'
import { useAppDispatch } from '../../../../store/hooks'
import { saveCommerce } from '../../../../store/states/commerce'
import { saveUser } from '../../../../store/states/user'
import './FormLogin.css'

interface LoginUser {
  username: string | null
  password: string | null
}

const allBusinessAsociateOfUser: Commerce[] = [
  {
    id: 1,
    avatar: 'https://pbs.twimg.com/profile_images/1136729078575370240/KxGV0eCI_400x400.png',
    portraitPhoto: 'https://i.ytimg.com/vi/a6AGqnth-cQ/maxresdefault.jpg',
    username: 'Daka',
    companyName: 'Daka Sociedad de arabes de coro, S.R.L.',
    accountId: '7f41a26a17972cca29080eaf7e58c488a76c7250aae51465fae26v8cd740a91',
    identities: [
      {
        id: 1,
        type: 'RIF',
        document: 'J-10515336'
      }
    ],
    phones: [
      {
        id: 1,
        phone: '04129625261'
      }
    ],
    emails: [
      {
        id: 1,
        email: 'nelson@gmail.es'
      }
    ],
    socialmedia: [
      {
        id: 1,
        social: 'www.facebook.com/nelson15'
      }
    ],
    shedules: 'Lunes a Domingo	8:00–20:00',
    country: {
      origin: {
        value: 've',
        label: 'Venezuela'
      },
      residence: {
        value: 've',
        label: 'Venezuela'
      }
    },
    direction: [
      {
        id: 1,
        city: 'Caracas',
        municipality: 'Baruta',
        address: '3 Av. Principal Colinas de Bello Monte, Caracas 1080, Distrito Capital'
      }
    ],
    business: []
  },
  {
    id: 2,
    avatar: 'https://trabajosinexperiencia.net/wp-content/uploads/2021/09/makro-logo-vector.png',
    portraitPhoto: 'https://upload.wikimedia.org/wikipedia/commons/thumb/1/1a/Makro_logo_la.svg/2560px-Makro_logo_la.svg.png',
    username: 'Makro',
    companyName: 'Supermercados Makro, C.A.',
    accountId: '7f41a26a17972cca29080eaf7e58c488a76c7250aae51465fae26v8cd740a91',
    identities: [
      {
        id: 1,
        type: 'RIF',
        document: 'J-10515336'
      }
    ],
    phones: [
      {
        id: 1,
        phone: '04129625261'
      }
    ],
    emails: [
      {
        id: 1,
        email: 'nelson@gmail.es'
      }
    ],
    socialmedia: [
      {
        id: 1,
        social: 'www.facebook.com/nelson15'
      }
    ],
    shedules: 'Lunes a Domingo	8:00–20:00',
    country: {
      origin: {
        value: 've',
        label: 'Venezuela'
      },
      residence: {
        value: 've',
        label: 'Venezuela'
      }
    },
    direction: [
      {
        id: 1,
        city: 'Caracas',
        municipality: 'Baruta',
        address: '3 Av. Principal Colinas de Bello Monte, Caracas 1080, Distrito Capital'
      }
    ],
    business: []
  }
]

const comerciosLogin: Commerce[] = [
  {
    id: 1,
    avatar: 'https://pbs.twimg.com/profile_images/1136729078575370240/KxGV0eCI_400x400.png',
    portraitPhoto: 'https://i.ytimg.com/vi/a6AGqnth-cQ/maxresdefault.jpg',
    username: 'Daka',
    companyName: 'Daka Sociedad de arabes de coro, S.R.L.',
    accountId: '7f41a26a17972cca29080eaf7e58c488a76c7250aae51465fae26v8cd740a91',
    identities: [
      {
        id: 1,
        type: 'RIF',
        document: 'J-10515336'
      }
    ],
    phones: [
      {
        id: 1,
        phone: '04129625261'
      }
    ],
    emails: [
      {
        id: 1,
        email: 'nelson@gmail.es'
      }
    ],
    socialmedia: [
      {
        id: 1,
        social: 'www.facebook.com/nelson15'
      }
    ],
    shedules: 'Lunes a Domingo	8:00–20:00',
    country: {
      origin: {
        value: 've',
        label: 'Venezuela'
      },
      residence: {
        value: 've',
        label: 'Venezuela'
      }
    },
    direction: [
      {
        id: 1,
        city: 'Caracas',
        municipality: 'Baruta',
        address: '3 Av. Principal Colinas de Bello Monte, Caracas 1080, Distrito Capital'
      }
    ],
    business: allBusinessAsociateOfUser
  },
  {
    id: 2,
    avatar: 'https://trabajosinexperiencia.net/wp-content/uploads/2021/09/makro-logo-vector.png',
    portraitPhoto: 'https://upload.wikimedia.org/wikipedia/commons/thumb/1/1a/Makro_logo_la.svg/2560px-Makro_logo_la.svg.png',
    username: 'Makro',
    companyName: 'Supermercados Makro, C.A.',
    accountId: '7f41a26a17972cca29080eaf7e58c488a76c7250aae51465fae26v8cd740a91',
    identities: [
      {
        id: 1,
        type: 'RIF',
        document: 'J-10515336'
      }
    ],
    phones: [
      {
        id: 1,
        phone: '04129625261'
      }
    ],
    emails: [
      {
        id: 1,
        email: 'nelson@gmail.es'
      }
    ],
    socialmedia: [
      {
        id: 1,
        social: 'www.facebook.com/nelson15'
      }
    ],
    shedules: 'Lunes a Domingo	8:00–20:00',
    country: {
      origin: {
        value: 've',
        label: 'Venezuela'
      },
      residence: {
        value: 've',
        label: 'Venezuela'
      }
    },
    direction: [
      {
        id: 1,
        city: 'Caracas',
        municipality: 'Baruta',
        address: '3 Av. Principal Colinas de Bello Monte, Caracas 1080, Distrito Capital'
      }
    ],
    business: allBusinessAsociateOfUser
  }
]

const userToLogin: User = {
  id: 1,
  avatar: null,
  username: 'nelson15',
  firstName: 'Nelson',
  lastName: 'Urbaneja',
  accountId: '7f41a26a17972cca29080eaf7e58c488a76c7250aae51465fae26v8cd740a91',
  identities: [
    {
      id: 1,
      type: 'V',
      document: '10515336'
    },
    {
      id: 2,
      type: 'DNI',
      document: '00000000A'
    },
    {
      id: 3,
      type: 'P',
      document: '012345678'
    }
  ],
  phones: [
    {
      id: 1,
      phone: '04129625261'
    }
  ],
  emails: [
    {
      id: 1,
      email: 'nelson@gmail.es'
    }
  ],
  socialmedia: [
    {
      id: 1,
      social: 'www.facebook.com/nelson15'
    }
  ],
  sex: 'Masculino',
  birthdate: '1997-10-26',
  country: {
    origin: {
      value: 've',
      label: 'Venezuela'
    },
    residence: {
      value: 've',
      label: 'Venezuela'
    }
  },
  direction: [
    {
      id: 1,
      city: 'Caracas',
      municipality: 'Sucre',
      address: 'Caracas, los dos caminos'
    }
  ],
  familyMember: [
    {
      id: 1,
      type: 'Madre',
      name: 'Nombre de mi madre'
    }
  ]
}

export const FormLogin = () => {
  const dispatch = useAppDispatch()
  const modal = useRef<HTMLIonModalElement>(null)
  const history = useHistory()
  const [openModalSelect, setOpenModalSelect] = useState(false)
  const [openModalSelectComercio, setOpenModalSelectComercio] = useState(false)
  const [openModalFinger, setOpenModalFinger] = useState(false)
  const [openModalFaceId, setOpenModalFaceId] = useState(false)

  const initialValues: LoginUser = {
    username: null,
    password: null
  }
  const validationSchemaLogin = yup.object({
    username: yup
      .string()
      .nullable()
      .required('Campo requerido.'),
    password: yup
      .string()
      .nullable()
      .required('Campo requerido.')
  })

  const dismissSelect = () => {
    setOpenModalSelect(false)
  }

  const dismissFinger = () => {
    setOpenModalFinger(false)
  }

  const dismissFaceId = () => {
    setOpenModalFaceId(false)
  }

  const handleOpenModalFinger = () => {
    dismissSelect()
    setOpenModalFinger(true)
  }

  const handleOpenModalFaceId = () => {
    dismissSelect()
    setOpenModalFaceId(true)
  }

  const handleSelectComercioForLogin = async (commerce: Commerce) => { // Select comercio for login
    setOpenModalSelectComercio(false)
    modal.current?.dismiss()
    history.replace('/dashboard', { replace: true })
    dispatch(saveUser(userToLogin))
    dispatch(saveCommerce(commerce))
  }

  const handleSubmit = (values: LoginUser, actions: FormikHelpers<LoginUser>) => {
    console.log({ values })
    console.log({ actions })
    // run services login here! if response is true then open modal
    setOpenModalSelectComercio(true)
  }

  return (
    <>
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchemaLogin}
      onSubmit={(values, actions) => { handleSubmit(values, actions) }}
    >
      {formikProps => (
        <Form className="form">
          <IonCard className="login-card ion-padding">
            <IonList className="login-list">
              <IonText color="dark"><h2 className="login-card-title">Inicia sesion</h2></IonText>
              <Input
                label="Teléfono, Correo o Usuario"
                type="text"
                name="username"
                value={formikProps.values.username}
                error={Boolean(formikProps.touched.username && formikProps.errors.username)}
                errorMessage={<IonTextError>{formikProps.touched.username && formikProps.errors.username}</IonTextError>}
                onChange={formikProps.handleChange}
                onBlur={formikProps.handleBlur}
              />
              <Input
                label="Contraseña"
                type="password"
                name="password"
                value={formikProps.values.password}
                error={Boolean(formikProps.touched.password && formikProps.errors.password)}
                errorMessage={<IonTextError>{formikProps.touched.password && formikProps.errors.password}</IonTextError>}
                onChange={formikProps.handleChange}
                onBlur={formikProps.handleBlur}
              />
              <IonText color="dark"><p className="ion-text-end"><Link to="/forgot-password">¿Olvidó su contraseña?</Link></p></IonText>
              <IonText color="dark"><p className="ion-text-center">O ingresa con:</p></IonText>
              <LoginSocialMedia />
              <IonButton color="secondary" shape="round" expand="block" type="submit" id="open-modal">Ingresar</IonButton>
              <IonText color="dark"><p className="ion-text-center">¿No tienes una cuenta? <Link to="/register">Regístrate aquí</Link></p></IonText>
            </IonList>
          </IonCard>
        </Form>
      )}
    </Formik>

    <ModalSmall isOpen={openModalSelect} onDidDismiss={dismissSelect}>
      <IonList>
        <IonText color="dark"><h3 className="ion-text-center">Acceso Biometrico</h3></IonText>
        <IonItem button={true} detail={false} onClick={() => { handleOpenModalFinger() }}>
          <IonLabel>Iniciar sesión con huella</IonLabel>
          <IonIcon icon={fingerPrintSharp}></IonIcon>
        </IonItem>
        <IonItem button={true} detail={false} onClick={() => { handleOpenModalFaceId() }}>
          <IonLabel>Iniciar sesión con face id</IonLabel>
          <IonIcon icon={scanSharp}></IonIcon>
        </IonItem>
        <IonText color="dark"><p className="ion-text-center" onClick={() => { dismissSelect() }}>Cancelar</p></IonText>
      </IonList>
    </ModalSmall>

    <ModalSmall isOpen={openModalFinger} onDidDismiss={dismissFinger}>
      <>
        <div className="ion-text-center"><IonIcon icon={fingerPrintSharp} size="large"></IonIcon></div>
        <IonText color="dark"><h3 className="ion-text-center">Iniciar sesión con tu huella</h3></IonText>
        <IonText color="dark"><p className="ion-text-center">Coloque su huella para continuar</p></IonText>
        <IonText color="dark"><p className="ion-text-center" onClick={() => { dismissFinger() }}>Cancelar</p></IonText>
      </>
    </ModalSmall>

    <ModalSmall isOpen={openModalFaceId} onDidDismiss={dismissFaceId}>
      <>
        <div className="ion-text-center"><IonIcon icon={scanSharp} size="large"></IonIcon></div>
        <IonText color="dark"><h3 className="ion-text-center">Iniciar sesión con Face ID</h3></IonText>
        <IonText color="dark"><p className="ion-text-center">Active su cámara para continuar</p></IonText>
        <IonText color="dark"><p className="ion-text-center" onClick={() => { dismissFaceId() }}>Cancelar</p></IonText>
      </>
    </ModalSmall>

    <IonModal ref={modal} isOpen={openModalSelectComercio} onDidDismiss={() => { setOpenModalSelectComercio(false) }} className={'modal-small large'}>
       <div className="ion-padding">
        <IonList>
          <IonText color="dark"><h3 className="ion-text-center">Empresas</h3></IonText>
            {comerciosLogin.map((commerce) =>
              <IonItem button detail={true} key={commerce.id} onClick={() => { handleSelectComercioForLogin(commerce) }}>
              <IonAvatar slot="start">
                <IonImg alt={commerce.companyName} src={commerce.avatar} />
              </IonAvatar>
              <IonLabel>
                <p className="ion-text-wrap"><IonNote>{commerce.companyName}</IonNote></p>
                <p><IonNote>{commerce.username}</IonNote></p>
              </IonLabel>
            </IonItem>
            )}
          <IonText color="dark"><p className="ion-text-center" onClick={() => { setOpenModalSelectComercio(false) }}>Cancelar</p></IonText>
        </IonList>
      </div>
    </IonModal>
    </>
  )
}
