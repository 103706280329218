export const countries: Array<{ value: string, label: string }> = [
  {
    value: 'ar',
    label: 'Argentina'
  },

  {
    value: 'br',
    label: 'Brasil'
  },
  {
    value: 'cl',
    label: 'Chile'
  },
  {
    value: 'co',
    label: 'Colombia'
  },
  {
    value: 'cr',
    label: 'Costa Rica'
  },
  {
    value: 'ec',
    label: 'Ecuador'
  },
  {
    value: 'mx',
    label: 'Mexico'
  },
  {
    value: 'pa',
    label: 'Panama'
  },
  {
    value: 'pe',
    label: 'Peru'
  },
  {
    value: 'uy',
    label: 'Uruguay'
  },
  {
    value: 've',
    label: 'Venezuela'
  }
]
