import { createSlice, current, type PayloadAction } from '@reduxjs/toolkit'
import { getLocalStorage, setLocalStorage } from '../../utilities/localStorage.utility'
import { type UserCardBank, type CardBank } from '../../models'

const initialState: UserCardBank = {
  cardsBank: [
    {
      id: 1,
      cardNumber: 10014125055454848,
      cardType: 'Credito',
      cardDate: '2022-12-26',
      cardCodeSegurity: 1253
    }
  ]
}

export const userCardsBankSlice = createSlice({
  name: 'user-cards-bank',
  initialState: getLocalStorage('user-cards-bank') ? JSON.parse(getLocalStorage('user-cards-bank') as string) : initialState,
  reducers: {
    saveCardBank (state: UserCardBank, action: PayloadAction<CardBank>) {
      setLocalStorage('user-cards-bank', action.payload)
      return { ...state, ...action.payload }
    },
    addCardBank (state: UserCardBank, action: PayloadAction<CardBank>) {
      const result = { ...state, cardsBank: [...state.cardsBank, action.payload] }
      setLocalStorage('user-cards-bank', result)
      return result
    },
    editCardBank (state: UserCardBank, action: PayloadAction<CardBank>) {
      const filterCards = [...current(state).cardsBank].filter(card => card.id !== action.payload.id)
      const findCard = [...current(state).cardsBank].find(card => card.id === action.payload.id)
      const editCard = { ...findCard, ...action.payload }
      const cards = [...filterCards, editCard]
      const result = { ...state, cardsBank: cards }
      setLocalStorage('user-cards-bank', result)
      return result
    }
  }
})

export const {
  saveCardBank,
  addCardBank,
  editCardBank
} = userCardsBankSlice.actions
export default userCardsBankSlice.reducer
