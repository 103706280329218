import { IonIcon } from '@ionic/react'
import { addCircleSharp, addOutline, closeCircleSharp } from 'ionicons/icons'
import { useRef, useState } from 'react'
import { type ServicesDashboard } from '../../../../models/service-dashboard'
import { ModalListOfServicesToAdd } from './components/ModalListOfServicesToAdd/ModalListOfServicesToAdd'
import { ModalDeleteServiceItem } from './components/ModalDeleteServiceItem/ModalDeleteServiceItem'
import './GridDashboardServices.css'

interface Props {
  modeEditActive: boolean
  setModeEditActive: React.Dispatch<React.SetStateAction<boolean>>
}

const GridDashboardServices = ({ modeEditActive, setModeEditActive }: Props) => {
  const [openModalLisOfServices, setOpenModalLisOfServices] = useState(false)
  const [openModalDeleteServices, setOpenModalDeleteServices] = useState(false)
  const [keySelected, setKeySelected] = useState<number | null>(null)
  const arrayOfServicesUser = useRef<ServicesDashboard[] | null[]>(new Array(9).fill(null))

  const handleOpenModalLisOfServices = (key: number) => {
    const existServiceInArray: ServicesDashboard | null = arrayOfServicesUser.current[key]
    if (existServiceInArray) return
    setKeySelected(key)
    setOpenModalLisOfServices(true)
  }

  const handleOpenModalDeleteService = ({ e, key }: { e: React.MouseEvent<HTMLIonIconElement, MouseEvent>, key: number }) => {
    e.stopPropagation()
    setKeySelected(key)
    setOpenModalDeleteServices(true)
  }

  const handleDeleteService = () => {
    const copyArrayServices: any = [...arrayOfServicesUser?.current]
    copyArrayServices.splice(keySelected as number, 1, null)
    arrayOfServicesUser.current = copyArrayServices
    setOpenModalDeleteServices(false)
  }

  return (
		<section>
			<div className="grid-dashboard-services">
        {arrayOfServicesUser.current.map((service, key) =>
          <div key={key} className="grid-dashboard-services--item" onClick={modeEditActive ? () => { handleOpenModalLisOfServices(key) } : () => null}>
            {(!service && modeEditActive) && <IonIcon className="dashboard-item-icon--plus" icon={addOutline} size="large" color='#707070' />}
            {(service && modeEditActive) && <IonIcon className="dashboard-item-icon--delete" icon={closeCircleSharp} size="large" color='secondary' onClick={(e) => { handleOpenModalDeleteService({ e, key }) }}/>}
            {service && <img alt={service?.title} src={service?.image} className="card-dashboard--image" style={{ width: '100%', maxWidth: '80px', minHeight: '80px' }} />}
          </div>
        )}
        {!modeEditActive && <div className="grid-dashboard-services--item plus" onClick={() => { setModeEditActive(true) }}>
          <IonIcon slot="start" icon={addCircleSharp} size="large" color='secondary' />
        </div>}
      </div>
      <ModalListOfServicesToAdd
        openModalLisOfServices={openModalLisOfServices}
        setOpenModalLisOfServices={setOpenModalLisOfServices}
        keySelected={keySelected as number}
        arrayOfServicesUser={arrayOfServicesUser}
      />
      <ModalDeleteServiceItem
        openModalDeleteServices={openModalDeleteServices}
        setOpenModalDeleteServices={setOpenModalDeleteServices}
        handleDeleteService={handleDeleteService}
      />
		</section>
  )
}

export default GridDashboardServices
