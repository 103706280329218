import { IonCard, IonItem, IonLabel, IonList, IonRadio, IonRadioGroup } from '@ionic/react'

export function OrderTickets () {
  return (
		<IonCard className="box ion-padding">
			<IonList>
				<IonRadioGroup>
					<IonItem lines='none'>
						<IonLabel>Asignar número de pedido automáticamente</IonLabel>
						<IonRadio value="1" slot='end'></IonRadio>
					</IonItem>
					<IonItem lines='none'>
						<IonLabel>Ingresar el número o el nombre personalizado</IonLabel>
						<IonRadio value="2" slot='end'></IonRadio>
					</IonItem>
				</IonRadioGroup>
			</IonList>
		</IonCard>
  )
}
