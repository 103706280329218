import { IonRouterOutlet, IonSplitPane } from '@ionic/react'
import { Route } from 'react-router'
import { Menu } from '../Organisms/Menu/Menu'
import { EditPersonal } from '../../pages/EditPersonal'
import { Login } from '../../pages/Login'
import { ProfileUser } from '../../pages/ProfileUser'
import { ProfileCommerce } from '../../pages/ProfileCommerce'
import { EditPreferenceConfig } from '../../pages/EditPreferenceConfig'
import { EditSegurityConfig } from '../../pages/EditSegurityConfig'
import { EditBanksAndCards } from '../../pages/EditBanksAndCards'
import { EditCommerceData } from '../../pages/EditCommerceData'
import { EditCommerceBusiness } from '../../pages/EditCommerceBusiness'
import { OpenBankAccount } from '../../pages/OpenBankAccount'
import { Register } from '../../pages/Register/Register'
import { ForgotUser } from '../../pages/ForgotUser/ForgotUser'
import { ForgotPassword } from '../../pages/ForgotPassword'
import { ForgotPasswordCode } from '../../pages/ForgotPasswordCode/ForgotPasswordCode'
import { RecoverPassword } from '../../pages/RecoverPassword/RecoverPassword'
import { Dashboard } from '../../pages/Dashboard'
import Checkout from '../../pages/Checkout/Checkout'
import { CheckoutCreateProduct } from '../../pages/CheckoutCreateProduct/CheckoutCreateProduct'
import { CheckoutCreateCheckout } from '../../pages/CheckoutCreateCheckout/CheckoutCreateCheckout'
import { Products } from '../../pages/Products/Products'
import { Activity } from '../../pages/Activity/Activity'
import { Reports } from '../../pages/Reports/Reports'
import { Divices } from '../../pages/Divices/Divices'
import { Withdrawal } from '../../pages/Withdrawal/Withdrawal'
import { Complements } from '../../pages/Complements/Complements'
import { Pos } from '../../pages/Pos/Pos'
import EditSettingCheckout from '../../pages/EditSettingCheckout/EditSettingCheckout'
import EditSettingOrders from '../../pages/EditSettingOrders/EditSettingOrders'

export interface PropsRoute {
  children: any
}

interface RoutesType {
  path: string
  component: JSX.Element
}

const ROUTES_PRIVATES: RoutesType[] = [
  {
    path: '/',
    component: <EditPersonal />
  },
  {
    path: '/perfil',
    component: <ProfileUser />
  },
  {
    path: '/dashboard',
    component: <Dashboard />
  },
  {
    path: '/punto-de-venta',
    component: <Pos />
  },
  {
    path: '/checkout',
    component: <Checkout />
  },
  {
    path: '/checkout/crear-producto',
    component: <CheckoutCreateProduct />
  },
  {
    path: '/checkout/crear-checkout',
    component: <CheckoutCreateCheckout />
  },
  {
    path: '/productos',
    component: <Products />
  },
  {
    path: '/productos/crear-producto',
    component: <CheckoutCreateProduct />
  },
  {
    path: '/actividad',
    component: <Activity />
  },
  {
    path: '/reporte',
    component: <Reports />
  },
  {
    path: '/dispositivos',
    component: <Divices />
  },
  {
    path: '/retiro',
    component: <Withdrawal />
  },
  {
    path: '/complementos',
    component: <Complements />
  },
  {
    path: '/perfil-comercio',
    component: <ProfileCommerce />
  },
  {
    path: '/natural/datos-personales/:section',
    component: <EditPersonal />
  },
  {
    path: '/natural/settings/preference/:section',
    component: <EditPreferenceConfig />
  },
  {
    path: '/natural/settings/checkout/:section',
    component: <EditSettingCheckout />
  },
  {
    path: '/natural/settings/orders/:section',
    component: <EditSettingOrders />
  },
  {
    path: '/natural/segurity/:section',
    component: <EditSegurityConfig />
  },
  {
    path: '/natural/bancos-y-tarjetas/:section',
    component: <EditBanksAndCards />
  },
  {
    path: '/comercio/datos-comercio/:section',
    component: <EditCommerceData />
  },
  {
    path: '/comercio/business/:section',
    component: <EditCommerceBusiness />
  },
  {
    path: '/comercio/bancos-y-tarjetas/:section',
    component: <EditBanksAndCards />
  },
  {
    path: '/comercio/preference/:section',
    component: <EditPreferenceConfig />
  },
  {
    path: '/aperturar-cuenta',
    component: <OpenBankAccount />
  }
]

const ROUTES_PUBLIC: RoutesType[] = [
  {
    path: '/login',
    component: <Login />
  },
  {
    path: '/register',
    component: <Register />
  },
  {
    path: '/forgot-user',
    component: <ForgotUser />
  },
  {
    path: '/forgot-password',
    component: <ForgotPassword />
  },
  {
    path: '/forgot-password-code',
    component: <ForgotPasswordCode />
  },
  {
    path: '/recover-password',
    component: <RecoverPassword />
  }
]

export const PublicSections = () => {
  return (
    <IonRouterOutlet>
      {ROUTES_PUBLIC.map(route => (
        <Route exact path={route.path} render={() => route.component} key={route.path} />
        // <Route exact path={route.path} component={route.component} key={route.path} />
      ))}
    </IonRouterOutlet>
  )
}

export const PrivateSections = () => {
  return (
    <IonSplitPane contentId="main">
      <Menu />
      <IonRouterOutlet id="main">
        {ROUTES_PRIVATES.map(route => (
          <Route exact path={route.path} render={() => route.component} key={route.path} />
        ))}
        {/* {ROUTES_PRIVATES.map(route => (
          <Route exact path={route.path} component={route.component} key={route.path} />
        ))} */}
      </IonRouterOutlet>
    </IonSplitPane>
  )
}
