import { IonButtons, IonLabel, IonMenuButton, IonSegment, IonSegmentButton } from '@ionic/react'
import { atSharp, businessSharp, calendarSharp, cardSharp, earthSharp, globeSharp, idCardSharp, keySharp, lockClosedSharp, mapSharp, newspaperSharp, peopleSharp, personCircleSharp, personSharp, phonePortraitSharp, transgenderSharp } from 'ionicons/icons'
import { useEffect, useState } from 'react'
import { ProfileUserAvatar } from '../../components/Molecules/ProfileUserAvatar/ProfileUserAvatar'
import { SectionListOfData } from '../../components/Organisms/SectionListOfData/SectionListOfData'
import { WrapperApp } from '../../components/Organisms/WrapperApp/WrapperApp'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { saveRolUserActive } from '../../store/states/roleUserActive'
import { createUserAvatarDefault } from '../../utilities/utilities'
import { SectionListSegurityData } from '../CommonComponentsInPages/SectionListSegurityData'
import { Complements } from '../Complements/Complements'
import { SettingsSegmentPage } from './components/SettingsSegmentPage/SettingsSegmentPage'

export const ProfileUser = () => {
  const dispatch = useAppDispatch()
  const user = useAppSelector(store => store.user)
  const roleUser = useAppSelector(store => store.roleUserActive)
  const [segment, setSegment] = useState<string>('profile')

  const dataUser = [
    {
      title: 'Nombre de usuario (username)',
      result: user.username,
      icon: personCircleSharp,
      disabled: true
    },
    {
      title: 'Nombre y Apellido',
      result: `${user.firstName} ${user.lastName}`,
      icon: personSharp,
      routerLink: '1',
      typeProfile: 'natural/datos-personales'
    },
    {
      title: 'ID de cuenta',
      result: user.accountId,
      icon: idCardSharp,
      disabled: true,
      routerLink: '2',
      typeProfile: 'natural/datos-personales'
    },
    {
      title: 'Documento(s) de identidad',
      result: `${user.identities.map(identity => `${identity.type}-${identity.document}`).join(', ')}`,
      icon: cardSharp,
      routerLink: '3',
      typeProfile: 'natural/datos-personales'
    },
    {
      title: 'Teléfono(s)',
      result: `${user.phones.map(phone => ` ${phone.phone}`).join(', ')}`,
      icon: phonePortraitSharp,
      routerLink: '4',
      typeProfile: 'natural/datos-personales'
    },
    {
      title: 'Correo(s)',
      result: `${user.emails.map(email => ` ${email.email}`).join(', ')}`,
      icon: atSharp,
      routerLink: '5',
      typeProfile: 'natural/datos-personales'
    },
    {
      title: 'Redes sociales',
      result: `${user.socialmedia.map(social => ` ${social.social}`).join(', ')}`,
      icon: globeSharp,
      routerLink: '6',
      typeProfile: 'natural/datos-personales'
    },
    {
      title: 'Sexo',
      result: user.sex,
      icon: transgenderSharp,
      routerLink: '7',
      typeProfile: 'natural/datos-personales'
    },
    {
      title: 'Fecha de nacimiento',
      result: user.birthdate,
      icon: calendarSharp,
      routerLink: '8',
      typeProfile: 'natural/datos-personales'
    },
    {
      title: 'País',
      result: `Origen: ${user.country.origin.label}, Residencia: ${user.country.residence.label}`,
      icon: earthSharp,
      routerLink: '9',
      typeProfile: 'natural/datos-personales'
    },
    {
      title: 'Dirección',
      result: user.direction.map(address => address.address).join(' / '),
      icon: mapSharp,
      routerLink: '10',
      typeProfile: 'natural/datos-personales'
    },
    {
      title: 'Familiares',
      result: `${user.familyMember.map(member => ` ${member.name}`).join(', ')}`,
      icon: peopleSharp,
      routerLink: '11',
      typeProfile: 'natural/datos-personales'
    }
  ]

  const dataUserSegurity = [
    {
      title: 'Claves',
      icon: keySharp,
      routerLink: '1',
      typeProfile: 'natural/segurity'
    },
    {
      title: 'Preguntas de seguridad',
      icon: lockClosedSharp,
      routerLink: '2',
      typeProfile: 'natural/segurity'
    },
    {
      title: 'Connected account',
      icon: peopleSharp,
      routerLink: '3',
      typeProfile: 'natural/segurity'
    },
    {
      title: 'Actividad',
      icon: newspaperSharp,
      routerLink: '4',
      typeProfile: 'natural/segurity'
    }
  ]

  const dataUserBanksAndCards = [
    {
      title: 'Cuentas bancarias',
      icon: businessSharp,
      routerLink: '1',
      typeProfile: 'natural/bancos-y-tarjetas'
    },
    {
      title: 'Tarjetas',
      icon: cardSharp,
      routerLink: '2',
      typeProfile: 'natural/bancos-y-tarjetas'
    }
  ]

  useEffect(() => {
    if (roleUser.id.toString() === '2') {
      dispatch(saveRolUserActive({ id: '1', type: 'natural' }))
    }
  }, [])

  return (
    <WrapperApp pageName="Perfil" renderButtons={() =>
      <IonButtons slot="start">
        <IonMenuButton />
      </IonButtons>}
    >
      <ProfileUserAvatar avatar={user?.avatar ?? createUserAvatarDefault(user.firstName)} />
      <IonSegment value={segment} scrollable onIonChange={e => { setSegment(e.detail.value as string) }}>
        <IonSegmentButton value="profile"><IonLabel>Datos Basicos</IonLabel></IonSegmentButton>
        {/* <IonSegmentButton value="preference"><IonLabel>Preferencia</IonLabel></IonSegmentButton> */}
        <IonSegmentButton value="segurity"><IonLabel>Seguridad</IonLabel></IonSegmentButton>
        <IonSegmentButton value="banks"><IonLabel>Bancos y Tarjetas</IonLabel></IonSegmentButton>
        <IonSegmentButton value="settings"><IonLabel>Configuración</IonLabel></IonSegmentButton>
        <IonSegmentButton value="addons"><IonLabel>Addons</IonLabel></IonSegmentButton>
      </IonSegment>
      {segment === 'profile' && <SectionListOfData titleSection="Datos personales" dataList={dataUser} />}
      {/* {segment === 'preference' && <SectionListOfData titleSection="Preferencia" dataList={dataUserPreference} />} */}
      {segment === 'segurity' && <SectionListSegurityData dataList={dataUserSegurity} />}
      {segment === 'settings' && <SettingsSegmentPage />}
      {segment === 'banks' && <SectionListOfData titleSection="Bancos y Tarjetas" dataList={dataUserBanksAndCards} />}
      {segment === 'addons' && <Complements />}
    </WrapperApp>
  )
}
