import { IonCard, IonItem, IonLabel, IonToggle } from '@ionic/react'
import { TitleForm } from '../../../../components/Atoms/TitleForm'

export function OrderCreation () {
  return (
		<IonCard className="box ion-padding">
			<TitleForm title='Creación del pedido' customClass='ion-padding-start'/>
			<IonItem lines='none'>
				<IonLabel>
					<h2>Activar creación de pedidos en el proceso de pago</h2>
				</IonLabel>
				<IonToggle color="secondary" slot="end" onIonChange={e => { console.log(e) }}></IonToggle>
			</IonItem>
			<IonLabel>
				<p className='ion-text-wrap ion-padding-start'>Crea pedidos para recolección en la tienda y en puerta. Todas las opciones de servicio de comidas deben estar desactivadas en el  Panel de Datos de e4cash</p>
			</IonLabel>
		</IonCard>
  )
}
