import { IonButton, IonButtons, IonCol, IonGrid, IonMenuButton, IonRow, IonText } from '@ionic/react'
import { useState } from 'react'
import { WrapperApp } from '../../components/Organisms/WrapperApp/WrapperApp'
import { Calculator } from './components/Calculator'
import { GridDashboardServices } from './components/GridDashboardServices'
import { HeaderDashboard } from './components/HeaderDashboard'
import { SectionSelectCustomer } from './components/SectionSelectCustomer/SectionSelectCustomer'

interface PropsWrappedModeEdit {
  setModeEditActive: React.Dispatch<React.SetStateAction<boolean>>
}

function WrappedModeEdit ({ setModeEditActive }: PropsWrappedModeEdit) {
  return (
		<div style={{ padding: '1rem', height: '100%', backgroundColor: '#f2f2f2', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
			<IonText>mode edit</IonText>
			<IonButton color="secondary" shape="round" expand="block" type="submit" id="open-modal" onClick={() => { setModeEditActive(false) }}>Done edit</IonButton>
		</div>
  )
}

const Dashboard: React.FC = () => {
  const [modeEditActive, setModeEditActive] = useState(false)

  return (
		<WrapperApp
			pageName="Cobranza"
			hiddenAvatar={true}
			renderButtons={() => (
				<>
					<IonButtons slot="start">
						<IonMenuButton />
					</IonButtons>
					<HeaderDashboard />
				</>
			)}
		>
			<IonGrid>
				<IonRow>
					<IonCol size="12" size-md="6" size-xl="7">
						<GridDashboardServices setModeEditActive={setModeEditActive} modeEditActive={modeEditActive} />
					</IonCol>
					<IonCol size="12" size-md="6" size-xl="5">
						{!modeEditActive
						 ?	<>
									<SectionSelectCustomer />
									<Calculator />
								</>
					   : <WrappedModeEdit setModeEditActive={setModeEditActive} />}
					</IonCol>
				</IonRow>
			</IonGrid>
		</WrapperApp>
  )
}

export default Dashboard
