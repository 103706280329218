import { IonBackButton, IonButtons } from '@ionic/react'
import { Redirect, useRouteMatch } from 'react-router'
import { WrapperApp } from '../../components/Organisms/WrapperApp/WrapperApp'
import { OrderCreation } from './components/OrderCreation/OrderCreation'
import { OrderNotifications } from './components/OrderNotifications/OrderNotifications'
import { SyncUp } from './components/SyncUp/SyncUp'

interface MatchParams {
  section: string
}

const EditSettingOrders: React.FC = () => {
  const routerMatch = useRouteMatch<MatchParams>()

  const renderComponent = (id: string) => {
    switch (id) {
      case '1': return <OrderCreation />
      case '2': return <OrderNotifications />
      case '3': return <SyncUp />
      default: return <Redirect to="/perfil" />
    }
  }

  return (
		<WrapperApp
			pageName="Perfil Natural"
			renderButtons={() =>
			<IonButtons slot="start">
				<IonBackButton defaultHref="/perfil" />
			</IonButtons>}
		>
			{renderComponent(routerMatch.params.section)}
		</WrapperApp>
  )
}

export default EditSettingOrders
