import { IonBadge, IonButton, IonCard, IonImg, IonItem, IonLabel, IonList, IonText, useIonToast } from '@ionic/react'

export function Complements () {
  const [present] = useIonToast()

  const message = () => {
    present({
      message: 'Modulo aún no disponible',
      duration: 2500,
      position: 'bottom',
      color: 'warning'
    })
  }

  return (
    <>
		 <IonText><h5 className="ion-padding">Complementos</h5></IonText>
      <IonCard className='ion-padding' style={{ minHeight: '400px' }}>
        <IonItem button={false} detail={false} lines='full'>
          <IonLabel slot='start' className='ion-no-padding'>
            <p className='ion-no-padding'>Nombre</p>
          </IonLabel>
          <IonLabel slot='end' className='ion-no-padding'>
            <p className='ion-no-padding'>Saldo</p>
          </IonLabel>
        </IonItem>
        <div className="ion-text-end ion-margin-top">
          <IonButton color="secondary" shape="round" type="submit" onClick={() => { message() }}>
            Instalar
          </IonButton>
        </div>

				<IonList lines='none' style={{ marginBottom: '1rem' }}>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem', alignItems: 'center', justifyContent: 'center', flex: 1, paddingBottom: '.6rem' }}>
						<IonItem style={{ flex: 1, width: '100%' }}>
							<div style={{ display: 'flex', gap: '.8rem', alignItems: 'center' }}>
								<div style={{ display: 'flex', gap: '.8rem', alignItems: 'center', height: '100%' }}>
									<IonImg src='https://www.letthembelittleboutique.com/cdn/shop/products/e38bd83af578077b65a31424bd24d085_949x.png?v=1615582622' alt='name' style={{ width: 50, height: 50, borderRadios: '30px' }} className="ion-align-self-center" />
								</div>
								<div style={{ }}>
									<IonLabel className='ion-no-padding'>Gift Card</IonLabel>
									<p style={{ opacity: '.6', paddingTop: 0, marginTop: '.2rem', marginBottom: '.5rem' }}>Save and edit orders</p>
									<IonBadge color="success" style={{ padding: '.3rem' }}>added</IonBadge>
								</div>
							</div>
						</IonItem>
						<IonItem style={{ flex: 1, width: '100%' }}>
							<div style={{ display: 'flex', gap: '.8rem', alignItems: 'center' }}>
								<div style={{ display: 'flex', gap: '.8rem', alignItems: 'center', height: '100%' }}>
									<IonImg src='https://www.letthembelittleboutique.com/cdn/shop/products/e38bd83af578077b65a31424bd24d085_949x.png?v=1615582622' alt='name' style={{ width: 50, height: 50, borderRadios: '30px' }} className="ion-align-self-center" />
								</div>
								<div style={{ }}>
									<IonLabel className='ion-no-padding'>Gift Card</IonLabel>
									<p style={{ opacity: '.6', paddingTop: 0, marginTop: '.2rem', marginBottom: '.5rem' }}>Save and edit orders</p>
									<IonBadge color="success" style={{ padding: '.3rem' }}>added</IonBadge>
								</div>
							</div>
						</IonItem>
					</div>
				</IonList>
      </IonCard>
    </>
  )
}
