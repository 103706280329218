import { IonAvatar, IonButton, IonCol, IonGrid, IonIcon, IonImg, IonInput, IonItem, IonLabel, IonList, IonRow, IonSelect, IonSelectOption, useIonToast } from '@ionic/react'
import { addCircle, bookmarkSharp, search } from 'ionicons/icons'
import { useState } from 'react'
import { TitleForm } from '../../../../components/Atoms/TitleForm'
import ModalSmall from '../../../../components/Organisms/ModalSmall/ModalSmall'
import { createUserAvatarDefault } from '../../../../utilities/utilities'
import { Input } from '../../../../components/Atoms/Input/Input'

interface UserDashboard {
  id: number
  name: string
  username: string
  email: string
  phone: number
}

export function SectionSelectCustomer () {
  const [present] = useIonToast()
  const [openModalClients, setOpenModalClients] = useState(false)
  const [openModalAddClient, setOpenModalAddClient] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [userSelectedToAdd, setUserSelectedToAdd] = useState<UserDashboard | null>(null)
  const [listOfUserAdded, setListOfUserAdded] = useState<UserDashboard[]>([])
  const [newUser, setNewUser] = useState({
    firstName: '',
    lastName: '',
    phone: '',
    email: ''
  })

  const USERS: UserDashboard[] = [
    {
      id: 1,
      name: 'Nelson Urbaneja',
      username: 'neslon',
      email: 'nelson@gmail.com',
      phone: 4125266985
    },
    {
      id: 2,
      name: 'Adbel Sifontes',
      username: 'adbel',
      email: 'adbelsifontes@style.com.ve',
      phone: 4125569854
    }
  ]

  const handleChange = (e: any) => {
    console.log({ e })
    setNewUser(values => ({
      ...values,
      [e.target.name]: e.target.value
    }))
  }

  const handleSelectUser = (user: UserDashboard) => {
    setUserSelectedToAdd(user)
    setListOfUserAdded(prevUsers => {
      return [...prevUsers, user]
    })
	  setOpenModalClients(false)
  }

  const handleSearchValue = (e: any) => {
    setSearchValue(e?.target?.value)
  }

  const filteredUser = USERS.filter(user => {
    return searchValue
		  ? user.username.toLowerCase().includes(searchValue.toLowerCase())
		  : USERS
  })

  const handleOpenModalAddClient = () => {
    setOpenModalClients(false)
    setOpenModalAddClient(true)
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setOpenModalAddClient(false)
    present({
      message: 'Cliente agregado',
      duration: 2500,
      position: 'bottom',
      color: 'success'
    })
  }

  return (
		<div className="ion-margin-bottom">
			<IonItem className="input-item ion-no-margin" disabled={!userSelectedToAdd}>
				<IonLabel position="floating">Customer</IonLabel>
				<IonSelect
					value={userSelectedToAdd?.username}
					placeholder="Customer"
					name="customer"
				>
					{listOfUserAdded.map(user =>
						<IonSelectOption key={user.id} value={user?.username}>${user?.username}</IonSelectOption>
					)}
				</IonSelect>
			</IonItem>
			<IonButton color="secondary" expand="block" onClick={() => { setOpenModalClients(true) }}>Agregar cliente</IonButton>
			<ModalSmall isOpen={openModalClients} onDidDismiss={() => { setOpenModalClients(false) }} customClass='large'>
				<div style={{ minWidth: '100%' }}>
					<IonItem className="ion-no-padding" style={{ width: '100%' }}>
						<TitleForm title='Clientes' customClass='ion-text-center ion-padding-start'/>
						<IonIcon icon={addCircle} size="large" slot='end' onClick={() => { handleOpenModalAddClient() }}/>
					</IonItem>
					<IonList>
						<IonItem className="ion-no-padding" style={{ width: '100%' }}>
							<IonIcon slot="start" icon={search} className='ion-padding-start'/>
							<IonInput name='search' placeholder='Buscar' type='search' value={searchValue} onIonChange={handleSearchValue} />
						</IonItem>
						<TitleForm title='Creados recientemente' customClass='ion-text-left ion-padding-start' />
						{filteredUser.length > 0
						  ? filteredUser.map(user => {
						  	const isUserAdded = listOfUserAdded.some(userItem => userItem.id === user.id)
						    return (
									<IonItem button key={user.id} onClick={() => { handleSelectUser(user) }} detail={!isUserAdded} disabled={isUserAdded}>
										<IonAvatar slot="start" style={{ minWidth: '15px', minHeight: '15px', objectFit: 'contain', position: 'relative', right: '1px' }}>
											<IonImg alt={user.name} src={createUserAvatarDefault(user.name)} />
										</IonAvatar>
										<IonLabel>
											<h2>{ user.name }</h2>
											<p>+58 - { user.phone } / { user.email } { isUserAdded && '(agregado)'} </p>
										</IonLabel>
									</IonItem>
						    )
						  })
						  : <IonItem>
								<IonLabel>Sin resultados</IonLabel>
							</IonItem>
						}
          </IonList>
        </div>
      </ModalSmall>
			<ModalSmall isOpen={openModalAddClient} onDidDismiss={() => { setOpenModalAddClient(false) }} customClass='large'>
				<form style={{ minWidth: '100%' }} onSubmit={e => { handleSubmit(e) }}>
					<IonItem className="ion-no-padding ion-margin-bottom" style={{ width: '100%' }}>
						<TitleForm title='Crear cliente nuevo' customClass='ion-text-center ion-padding-start'/>
					</IonItem>
					<div className='ion-margin-top ion-margin-bottom'>
						<IonButton shape="round" color="light" expand="block">Agregar desde la libreta de direcciones</IonButton>
					</div>
					<IonGrid>
						<IonRow>
							<IonCol>
								<Input label="Nombre" type="text" name="firstName" value={newUser.firstName} onChange={e => { handleChange(e) }}/>
							</IonCol>
							<IonCol>
								<Input label="Apellido" type="text" name="lastName" value={newUser.lastName} onChange={e => { handleChange(e) }} />
							</IonCol>
						</IonRow>
						<IonRow>
							<IonCol>
								<Input label="Teléfono" type="number" name="phone" value={newUser.phone} onChange={e => { handleChange(e) }}/>
							</IonCol>
						</IonRow>
						<IonRow>
							<IonCol>
								<Input label="Dirección de correo electrónico" type="text" name="email" value={newUser.email} onChange={e => { handleChange(e) }} />
							</IonCol>
						</IonRow>
						<div className='ion-margin-top ion-margin-bottom'>
							<IonButton shape="round" color="secondary" expand="block" type='submit'>
								Guardar
								<IonIcon icon={bookmarkSharp} size="large" slot='end'/>
							</IonButton>
						</div>
					</IonGrid>
				</form>
			</ModalSmall>
		</div>
  )
}
