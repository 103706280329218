import { IonSegment, IonSegmentButton, IonItem, IonLabel, IonList, IonCard, IonButton, IonText, IonCheckbox } from '@ionic/react'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { Input } from '../../../../components/Atoms/Input/Input'
import './FormRegister.css'

interface RegisterUser {
  username?: string | null
  password?: string | number | null
  entityname: string | null
  email: string | null
  phone: number | null
}

export const FormRegister = () => {
  const [segment, setSegment] = useState<string>('natural')
  const [userRegister, setUserRegister] = useState<RegisterUser>({
    username: null,
    password: null,
    entityname: null,
    email: null,
    phone: null
  })

  const handleRegister = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
  }

  const handleChange = (e: any) => {
    setUserRegister(values => ({
      ...values,
      [e.target.name]: e.target.value
    }))
  }

  return (
    <form className="form" onSubmit={e => { handleRegister(e) }}>
      <IonCard className="register-card ion-padding">
        <IonList className="register-list">
          <IonSegment value={segment} onIonChange={e => { setSegment(e.detail.value as string) }} className="form-segment">
            <IonSegmentButton value="natural"><IonLabel>Natural</IonLabel></IonSegmentButton>
            <IonSegmentButton value="juridico"><IonLabel>Jurídico</IonLabel></IonSegmentButton>
          </IonSegment>
          <IonText color="dark"><h2 className="register-card-title">Creación de usuario { segment }</h2></IonText>
          {segment === 'natural' &&
            <>
            <Input
              label="Nombre de usuario (username)"
              type="text"
              name="username"
              value={userRegister.username}
              onChange={e => { handleChange(e) }}
            />
            <Input
              label="Correo"
              type="email"
              name="email"
              value={userRegister.email}
              onChange={e => { handleChange(e) }}
            />
            <IonText color="secondary"><p>Usar teléfono</p></IonText>
            <Input
              label="Contraseña"
              type="password"
              name="password"
              value={userRegister.password}
              onChange={e => { handleChange(e) }}
            />
            </>
          }
          {segment === 'juridico' &&
            <>
             <Input
              label="Apodo del comercio"
              type="text"
              name="username"
              value={userRegister.username}
              onChange={e => { handleChange(e) }}
            />
            <IonText color="secondary"><p>Usar teléfono</p></IonText>
            <Input
              label="Contraseña"
              type="password"
              name="password"
              value={userRegister.password}
              onChange={e => { handleChange(e) }}
            />
            </>
          }
          <IonItem>
            <IonCheckbox name="terms" defaultChecked={true} slot="start" />
            <IonLabel className="ion-text-wrap" id="terms">He leído y acepto los <Link to="/terminos-y-condiciones">Términos de servicio</Link> y la <Link to="/politicas">Política de privacidad</Link></IonLabel>
          </IonItem>
          <IonButton shape="round" color="secondary" expand="block" type="submit">Registrar</IonButton>
          <p className="ion-text-center">¿Ya tienes cuenta? <Link to="/login">Ingresa aquí</Link></p>
        </IonList>
      </IonCard>
    </form>
  )
}
