import { IonBackButton, IonButtons } from '@ionic/react'
import { Redirect, useRouteMatch } from 'react-router'
import { WrapperApp } from '../../components/Organisms/WrapperApp/WrapperApp'
import { ListGivenCurrency } from './components/ListGivenCurrency'
import { ListLanguage } from './components/ListLanguage'
import { ListNotifications } from './components/ListNotifications'
import { ListPreferredChannelOfUse } from './components/ListPreferredChannelOfUse'

interface MatchParams {
  section: string
}

const EditPreferenceConfig: React.FC = () => {
  const routerMatch = useRouteMatch<MatchParams>()

  const renderComponent = (id: string) => {
    switch (id) {
      case '1': return <ListNotifications />
      case '2': return <ListPreferredChannelOfUse />
      case '3': return <ListGivenCurrency />
      case '4': return <ListLanguage />
      default: return <Redirect to="/perfil" />
    }
  }

  return (
		<WrapperApp
			pageName="Perfil Natural"
			renderButtons={() =>
			<IonButtons slot="start">
				<IonBackButton defaultHref="/perfil" />
			</IonButtons>}
		>
			{renderComponent(routerMatch.params.section)}
		</WrapperApp>
  )
}

export default EditPreferenceConfig
