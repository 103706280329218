import { IonBackButton, IonButtons } from '@ionic/react'
import { Redirect, useRouteMatch } from 'react-router'
import { WrapperApp } from '../../components/Organisms/WrapperApp/WrapperApp'
import { FormEditBirthDate } from './components/FormEditBirthDate'
import { FormEditCountry } from '../CommonComponentsInPages/FormEditCountry'
import { FormEditFullName } from './components/FormEditFullName'
import { FormEditSex } from './components/FormEditSex'
import { ListOfAddress } from '../CommonComponentsInPages/ListOfAddress'
import { ListOfEmails } from '../CommonComponentsInPages/ListOfEmails'
import { ListOfIdentityDocuments } from '../CommonComponentsInPages/ListOfIdentityDocuments'
import { ListOfSocialMedia } from '../CommonComponentsInPages/ListOfSocialMedia'
import { ListOfMembersFamily } from './components/ListOfMembersFamily'
import { ListOfPhones } from '../CommonComponentsInPages/ListOfPhones'
import { ViewAccountWallet } from '../CommonComponentsInPages/ViewAccountWallet'
import { useAppSelector } from '../../store/hooks'

interface MatchParams {
  section: string
}

const EditPersonal: React.FC = () => {
  const routerMatch = useRouteMatch<MatchParams>()
  const user = useAppSelector(store => store.user)

  const renderComponent = (id: string) => {
    switch (id) {
      case '1': return <FormEditFullName />
      case '2': return <ViewAccountWallet />
      case '3': return <ListOfIdentityDocuments title="Documento(s) de identidad" list={user.identities} />
      case '4': return <ListOfPhones title="Teléfonos" list={user.phones} />
      case '5': return <ListOfEmails title="Correos" list={user.emails} />
      case '6': return <ListOfSocialMedia title="Redes sociales" list={user.socialmedia} />
      case '7': return <FormEditSex />
      case '8': return <FormEditBirthDate />
      case '9': return <FormEditCountry userType={user} />
      case '10': return <ListOfAddress title="Direcciones" list={user.direction} />
      case '11': return <ListOfMembersFamily />
      default: return <Redirect to="/perfil" />
    }
  }

  return (
		<WrapperApp
			pageName="Perfil Natural"
			renderButtons={() =>
			<IonButtons slot="start">
				<IonBackButton defaultHref="/perfil" />
			</IonButtons>}
		>
			{renderComponent(routerMatch.params.section)}
		</WrapperApp>
  )
}

export default EditPersonal
