import { IonPopover, IonContent, IonList, IonItem, IonLabel } from '@ionic/react'

interface PopoverNotificationProps {
  popover: React.RefObject<HTMLIonPopoverElement>
  popoverOpen: boolean
  setPopoverOpen: React.Dispatch<React.SetStateAction<boolean>>
}

function PopoverNotification ({ popover, popoverOpen, setPopoverOpen }: PopoverNotificationProps) {
  return (
		<IonPopover dismissOnSelect={true} ref={popover} isOpen={popoverOpen} onDidDismiss={() => { setPopoverOpen(false) }} size="auto" className='popover-select-commerce'>
			<IonContent>
				<IonList>
					<IonItem lines="none">
						<IonLabel>Notificaciones</IonLabel>
					</IonItem>
					<IonItem lines="none" button={true} detail={true}>
						<IonLabel>
							<span style={{ fontSize: '14px' }}>Pago</span>
							<p style={{ fontSize: '16px' }}>Pago exitoso a Daka por monto de $346,88</p>
							<p style={{ fontSize: '14px' }}>Hace 0 minutos</p>
						</IonLabel>
					</IonItem>
				</IonList>
			</IonContent>
		</IonPopover>
  )
}

export default PopoverNotification
