import { IonIcon, IonItem, IonLabel, IonList } from '@ionic/react'
import { closeOutline } from 'ionicons/icons'

interface Props {
  setIsOpenModalSelectPayment: React.Dispatch<React.SetStateAction<boolean>>
  setIsOpenModalPaymentPersonalize: React.Dispatch<React.SetStateAction<boolean>>
  setIsOpenModalRegisterPayment: React.Dispatch<React.SetStateAction<boolean>>
}

export function ModalSelectPaymentType ({ setIsOpenModalSelectPayment, setIsOpenModalPaymentPersonalize, setIsOpenModalRegisterPayment }: Props) {
  const handleDividir = () => {
    setIsOpenModalSelectPayment(false)
    setIsOpenModalPaymentPersonalize(true)
  }

  const handleRegisterPayment = () => {
    setIsOpenModalSelectPayment(false)
    setIsOpenModalRegisterPayment(true)
  }

  return (
		<div style={{ minWidth: '100%' }}>
			<IonItem className='ion-no-padding ion-no-margin' lines='none'>
        <IonIcon icon={closeOutline} size='small' slot='start' onClick={() => { setIsOpenModalSelectPayment(false) }} className='ion-padding-start' />
				<IonItem slot='end' className='ion-no-padding ion-no-margin' lines='none' button onClick={() => { handleDividir() }}>
					<IonLabel slot='end'>Dividir monto</IonLabel>
				</IonItem>
      </IonItem>
			<div>
				<IonItem className='ion-no-padding ion-no-margin ion-text-center' lines='none'>
					<IonLabel>
						<h1>$1245.88</h1>
					</IonLabel>
				</IonItem>
				<IonItem className='ion-no-padding ion-no-margin ion-text-center' lines='none'>
					<IonLabel>Selecciona el tipo de pago a continuación</IonLabel>
				</IonItem>
				<IonList>
					<div>
						<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '1rem', marginTop: '2rem', marginBottom: '1rem' }}>
							<IonItem button>
								<IonLabel style={{ flex: 1 }} className='ion-padding-start'>
									<h2 style={{ flex: 1, textAlign: 'left', fontWeight: 'bold' }}>Efetivo</h2>
								</IonLabel>
							</IonItem>
							<IonItem>
								<IonLabel style={{ flex: 1 }} className='ion-padding-start'>
									<h2 style={{ flex: 1, textAlign: 'center', fontWeight: 'bold' }}>$1351.00</h2>
								</IonLabel>
							</IonItem>
							<IonItem>
								<IonLabel style={{ flex: 1 }} className='ion-padding-start'>
									<h2 style={{ flex: 1, textAlign: 'center', fontWeight: 'bold' }}>$1351.00</h2>
								</IonLabel>
							</IonItem>
							<IonItem>
								<IonLabel style={{ flex: 1 }} className='ion-padding-end'>
									<h2 style={{ flex: 1, textAlign: 'center', fontWeight: 'right' }}>Personalizar</h2>
								</IonLabel>
							</IonItem>
						</div>
					</div>
					<IonItem button detail={true} lines='none' onClick={() => { handleRegisterPayment() }}>
						<IonLabel>
							<h2>Registrar pago con tarjeta</h2>
						</IonLabel>
					</IonItem>
				</IonList>
			</div>
    </div>
  )
}
