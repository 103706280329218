import { IonButtons, IonCard, IonIcon, IonLabel, IonMenuButton, IonSegment, IonSegmentButton } from '@ionic/react'
import { analytics, wallet } from 'ionicons/icons'
import { useState } from 'react'
import { WrapperApp } from '../../components/Organisms/WrapperApp/WrapperApp'
import { HeaderDashboard } from '../Dashboard/components/HeaderDashboard'
import { FormRequest } from './components/FormRequest/FormRequest'
import { ListStatus } from './components/ListStatus/ListStatus'

export function Withdrawal () {
  const [segment, setSegment] = useState('form')

  return (
    <>
      <WrapperApp
        pageName="Retiro"
        hiddenAvatar={true}
        renderButtons={() => (
          <>
            <IonButtons slot="start">
              <IonMenuButton />
            </IonButtons>
            <HeaderDashboard />
          </>
        )}
      >
        <IonCard className='box ion-padding'>
          <IonSegment color='secondary' value={segment} scrollable onIonChange={e => { setSegment(e.detail.value as string) }} className='ion-padding-horizontal'>
            <IonSegmentButton value={'form'} layout="icon-start">
              <IonIcon icon={wallet} />
              <IonLabel>Retiro</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value={'status'} layout="icon-start">
              <IonIcon icon={analytics} />
              <IonLabel>Estatus</IonLabel>
            </IonSegmentButton>
          </IonSegment>

         {segment === 'form' && <FormRequest />}
         {segment === 'status' && <ListStatus />}
        </IonCard>
      </WrapperApp>
    </>
  )
}
