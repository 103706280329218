import { IonIcon, useIonToast } from '@ionic/react'
import { addCircleSharp, addOutline, cart, closeCircleSharp, gift, pricetags, star } from 'ionicons/icons'
import { useState } from 'react'
import { TitleForm } from '../../../../components/Atoms/TitleForm'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import { addProductToCart, changeModeEdit, deleteServiceToGrid } from '../../../../store/states/pos'
import { ModalDeleteServiceItem } from '../../../Dashboard/components/GridDashboardServices/components/ModalDeleteServiceItem/ModalDeleteServiceItem'
import type { ProductCart, ServicePos, ServicesPosType } from '../../Pos'
import { ModalListOfServicesToAddPos } from '../ModalListOfServicesToAddPos/ModalListOfServicesToAddPos'
import './GridDashboardPos.css'

interface Props {
  gridServices: ServicePos[] | null[]
  productsCart: ProductCart[]
}

export function GridDashboardPos ({ productsCart, gridServices }: Props) {
  const [openModalLisOfServices, setOpenModalLisOfServices] = useState(false)
  const [openModalDeleteServices, setOpenModalDeleteServices] = useState(false)
  const [keySelected, setKeySelected] = useState<number | null>(null)
  const [present] = useIonToast()
  const dispatch = useAppDispatch()
  const posState = useAppSelector(store => store.pos)

  const handleOpenModalLisOfServices = (key: number) => {
    console.log({ key })
    const existServiceInArray: ServicePos | null = gridServices[key]
    console.log({ existServiceInArray })
    if (existServiceInArray?.image) return
    setKeySelected(key)
    setOpenModalLisOfServices(true)
  }

  const handleOpenModalDeleteService = ({ e, key, service }: { e: React.MouseEvent<HTMLIonIconElement, MouseEvent>, key: number, service: ServicePos }) => {
    const isProductInCart = productsCart.some(product => product.id === service?.id)
    if (isProductInCart) {
      present({
        message: 'No puedes eliminar un producto añadido al carrito',
        duration: 2500,
        position: 'bottom',
        color: 'danger'
      })
      return
    }

    const exitProductInAccountOne = posState.accountOne.productsCart?.some(product => product.id === service?.id)
    const exitProductInAccountTwo = posState.accountTwo.productsCart?.some(product => product.id === service?.id)
    const exitProductInAccountThree = posState.accountThree.productsCart?.some(product => product.id === service?.id)

    const isProductInSomeCart = exitProductInAccountOne || exitProductInAccountTwo || exitProductInAccountThree

    if (isProductInSomeCart) {
      present({
        message: 'No puedes eliminar un producto porque ya se encuente añadido a unos de los carritos',
        duration: 2500,
        position: 'bottom',
        color: 'danger'
      })
      return
    }

    e.stopPropagation()
    setKeySelected(key)
    setOpenModalDeleteServices(true)
  }

  const handleDeleteService = () => {
    dispatch(deleteServiceToGrid({ keySelected }))
    setOpenModalDeleteServices(false)
  }

  const handleAddProductToList = (product: ServicesPosType) => {
    dispatch(addProductToCart({ productToAdd: product }))
  }

  return (
    <section>
      <div className="grid-dashboard-services">
        {gridServices.map((service, key) => {
          const isProductInCart = productsCart?.some(product => product.id === service?.id)
          console.log({ key })
          console.log({ serviceId: service?.id })
          return (
            <div key={key} className={`grid-dashboard-services--item ${isProductInCart && 'in-cart'}`} onClick={(service && !isProductInCart && !posState.modeEditActive) ? () => { handleAddProductToList(service) } : () => null}>
              {(!service?.image) && (posState.modeEditActive) && <IonIcon className="dashboard-item-icon--plus" icon={addOutline} size="large" color='#707070' onClick={posState.modeEditActive ? () => { handleOpenModalLisOfServices(key) } : () => null} />}
              {(service?.image && posState.modeEditActive) && <IonIcon className="dashboard-item-icon--delete" icon={closeCircleSharp} size="large" color='secondary' onClick={(e) => { handleOpenModalDeleteService({ e, key, service }) }} />}
              {(isProductInCart) && <IonIcon className="dashboard-item-icon--cart" icon={cart} size="small" color='secondary' onClick={(e) => { handleOpenModalDeleteService({ e, key, service }) }} />}
              {(service?.image) && <img alt={service?.title} src={service?.image} className="card-dashboard--image" style={{ width: '100%', maxWidth: '80px', minHeight: '80px' }} />}
            </div>
          )
        }
        )}
        <div className="grid-dashboard-services--item plus" style={{ display: 'flex', flexDirection: 'column', gap: '.5rem' }}>
          <IonIcon slot="start" icon={pricetags} size="large" color='secondary' />
          <TitleForm title="Descuento" customClass="ion-text-center ion-no-margin" />
        </div>
        <div className="grid-dashboard-services--item plus" style={{ display: 'flex', flexDirection: 'column', gap: '.5rem' }}>
          <IonIcon slot="start" icon={star} size="large" color='secondary' />
          <TitleForm title="Rewards" customClass="ion-text-center ion-no-margin" />
        </div>
        <div className="grid-dashboard-services--item plus" style={{ display: 'flex', flexDirection: 'column', gap: '.5rem' }}>
          <IonIcon slot="start" icon={gift} size="large" color='secondary' />
          <TitleForm title="Gift cards" customClass="ion-text-center ion-no-margin" />
        </div>
        <div className="grid-dashboard-services--item plus" style={{ display: 'flex', flexDirection: 'column', gap: '.5rem' }}>

        </div>
        {!posState.modeEditActive &&
         <div className="grid-dashboard-services--item plus" onClick={() => { dispatch(changeModeEdit(true)) }}>
          <IonIcon slot="start" icon={addCircleSharp} size="large" color='secondary' />
        </div>}
        {posState.modeEditActive &&
        <div className="grid-dashboard-services--item plus" onClick={() => { dispatch(changeModeEdit(false)) }}>
          Done edit
        </div>}
      </div>
      {/* <div className="grid-dashboard-services ion-padding-top">
        <div className="grid-dashboard-services--item plus" style={{ display: 'flex', flexDirection: 'column', gap: '.5rem' }}>
          <IonIcon slot="start" icon={pricetags} size="large" color='secondary' />
          <TitleForm title="Descuento" customClass="ion-text-center ion-no-margin" />
        </div>
        <div className="grid-dashboard-services--item plus" style={{ display: 'flex', flexDirection: 'column', gap: '.5rem' }}>
          <IonIcon slot="start" icon={star} size="large" color='secondary' />
          <TitleForm title="Rewards" customClass="ion-text-center ion-no-margin" />
        </div>
        <div className="grid-dashboard-services--item plus" style={{ display: 'flex', flexDirection: 'column', gap: '.5rem' }}>
          <IonIcon slot="start" icon={gift} size="large" color='secondary' />
          <TitleForm title="Gift cards" customClass="ion-text-center ion-no-margin" />
        </div>
      </div> */}
      <ModalListOfServicesToAddPos
        openModalLisOfServices={openModalLisOfServices}
        setOpenModalLisOfServices={setOpenModalLisOfServices}
        keySelected={keySelected as number}
        arrayOfServicesUser={gridServices}
      />
      <ModalDeleteServiceItem
        openModalDeleteServices={openModalDeleteServices}
        setOpenModalDeleteServices={setOpenModalDeleteServices}
        handleDeleteService={handleDeleteService}
      />
    </section>
  )
}
