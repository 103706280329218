export const getLocalStorage = (key: string) => {
  return window.localStorage.getItem(key)
}

export const setLocalStorage = <T>(key: string, value: T): void => {
  window.localStorage.setItem(key, JSON.stringify(value))
}

export const clearLocalStorage = () => {
  window.localStorage.clear()
}

export const clearLocalStorageKey = (key: string) => {
  window.localStorage.removeItem(key)
}
