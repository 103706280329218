import { createSlice, current } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { User, Address, Email, FamilyMember, Identity, Phone, SocialMedia } from '../../models'
import { clearLocalStorage, getLocalStorage, setLocalStorage } from '../../utilities/localStorage.utility'

const initialState: User | null = {
  id: null,
  username: '',
  avatar: '',
  firstName: '',
  lastName: '',
  accountId: '',
  identities: [],
  phones: [],
  emails: [],
  socialmedia: [],
  sex: '',
  birthdate: '',
  country: {
    origin: {
      value: '',
      label: ''
    },
    residence: {
      value: '',
      label: ''
    }
  },
  direction: [],
  familyMember: []
}

export const userSlice = createSlice({
  name: 'user',
  initialState: getLocalStorage('user') ? JSON.parse(getLocalStorage('user') as string) : initialState,
  reducers: {
    saveUser (state: User, action: PayloadAction<User>) {
      setLocalStorage('user', action.payload)
      return { ...state, ...action.payload }
    },
    editFullName (state: User, action) {
      const result = { ...state, ...action.payload }
      setLocalStorage('user', result)
      return result
    },
    editAvatar (state: User, action) {
      const result = { ...state, ...action.payload }
      setLocalStorage('user', result)
      return result
    },
    editSex (state: User, action) {
      const result = { ...state, ...action.payload }
      setLocalStorage('user', result)
      return result
    },
    editBirthdate (state: User, action) {
      const result = { ...state, ...action.payload }
      setLocalStorage('user', result)
      return result
    },
    editCountries (state: User, action) {
      const result = { ...state, ...action.payload }
      setLocalStorage('user', result)
      return result
    },
    addSocialMedia (state: User, action: PayloadAction<SocialMedia>) {
      const socials = [...current(state).socialmedia, action.payload]
      const result = { ...state, socialmedia: socials }
      setLocalStorage('user', result)
      return result
    },
    editSocialMedia (state: User, action: PayloadAction<SocialMedia>) {
      const filterSocialMedia = [...current(state).socialmedia].filter(social => social.id !== action.payload.id)
      const findSocialMedia = [...current(state).socialmedia].find(social => social.id === action.payload.id)
      const editSocialMedia = { ...findSocialMedia, ...action.payload }
      const socialmedia = [...filterSocialMedia, editSocialMedia]
      const result = { ...state, socialmedia }
      setLocalStorage('user', result)
      return result
    },
    addEmail (state: User, action: PayloadAction<Email>) {
      const emails = [...current(state).emails, action.payload]
      const result = { ...state, emails }
      setLocalStorage('user', result)
      return result
    },
    editEmail (state: User, action: PayloadAction<Email>) {
      const filterEmails = [...current(state).emails].filter(email => email.id !== action.payload.id)
      const findEmail = [...current(state).emails].find(email => email.id === action.payload.id)
      const editEmail = { ...findEmail, ...action.payload }
      const emails = [...filterEmails, editEmail]
      const result = { ...state, emails }
      setLocalStorage('user', result)
      return result
    },
    addAddress (state: User, action: PayloadAction<Address>) {
      const direction = [...current(state).direction, action.payload]
      const result = { ...state, direction }
      setLocalStorage('user', result)
      return result
    },
    editAddress (state: User, action: PayloadAction<Address>) {
      const filterAddress = [...current(state).direction].filter(address => address.id !== action.payload.id)
      const findAddress = [...current(state).direction].find(address => address.id === action.payload.id)
      const editAddress = { ...findAddress, ...action.payload }
      const address = [...filterAddress, editAddress]
      const result = { ...state, direction: address }
      setLocalStorage('user', result)
      return result
    },
    addPhone (state: User, action: PayloadAction<Phone>) {
      const phones = [...current(state).phones, action.payload]
      const result = { ...state, phones }
      setLocalStorage('user', result)
      return result
    },
    editPhone (state: User, action: PayloadAction<Phone>) {
      const filterPhones = [...current(state).phones].filter(phone => phone.id !== action.payload.id)
      const findPhone = [...current(state).phones].find(phone => phone.id === action.payload.id)
      const editPhone = { ...findPhone, ...action.payload }
      const phones = [...filterPhones, editPhone]
      const result = { ...state, phones }
      setLocalStorage('user', result)
      return result
    },
    addMembersFamily (state: User, action: PayloadAction<FamilyMember>) {
      const familyMember = [...current(state).familyMember, action.payload]
      const result = { ...state, familyMember }
      setLocalStorage('user', result)
      return result
    },
    editMembersFamily (state: User, action: PayloadAction<FamilyMember>) {
      const filterFamilyMember = [...current(state).familyMember].filter(member => member.id !== action.payload.id)
      const findFamilyMember = [...current(state).familyMember].find(member => member.id === action.payload.id)
      const editFamilyMember = { ...findFamilyMember, ...action.payload }
      const familyMember = [...filterFamilyMember, editFamilyMember]
      const result = { ...state, familyMember }
      setLocalStorage('user', result)
      return result
    },
    addIdentities (state: User, action: PayloadAction<Identity>) {
      const identities = [...current(state).identities, action.payload]
      const result = { ...state, identities }
      setLocalStorage('user', result)
      return result
    },
    editIdentities (state: User, action: PayloadAction<Identity>) {
      const filterItentities = [...current(state).identities].filter(identity => identity.id !== action.payload.id)
      const findIdentity = [...current(state).identities].find(identity => identity.id === action.payload.id)
      const editIdentity = { ...findIdentity, ...action.payload }
      const identities = [...filterItentities, editIdentity]
      const result = { ...state, identities }
      setLocalStorage('user', result)
      return result
    },
    clearUser () {
      clearLocalStorage()
      return initialState
    }
  }
})

export const {
  saveUser,
  editFullName,
  editAvatar,
  editSex,
  editBirthdate,
  editCountries,
  clearUser,
  editIdentities,
  addIdentities,
  addPhone,
  editPhone,
  addEmail,
  editEmail,
  addAddress,
  editAddress,
  addSocialMedia,
  editSocialMedia,
  addMembersFamily,
  editMembersFamily
} = userSlice.actions
export default userSlice.reducer
