import { IonBackButton, IonButtons } from '@ionic/react'
import { WrapperApp } from '../../components/Organisms/WrapperApp/WrapperApp'
import { FormOpenBankAccount } from './components/FormOpenBankAccount'

export const OpenBankAccount = () => {
  return (
    <WrapperApp pageName="Aperturar cuenta" renderButtons={() =>
      <IonButtons slot="start">
				<IonBackButton defaultHref="/natural/bancos-y-tarjetas/1" />
			</IonButtons>}
    >
			<FormOpenBankAccount />
    </WrapperApp>
  )
}

export default OpenBankAccount
