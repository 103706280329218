import { IonButton, IonText, useIonToast } from '@ionic/react'
import { useState } from 'react'
import { Input } from '../../../components/Atoms/Input/Input'
import { TitleForm } from '../../../components/Atoms/TitleForm'
import type { Email } from '../../../models/email'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { addEmailCommerce, editEmailCommerce } from '../../../store/states/commerce'
import { addEmail, editEmail } from '../../../store/states/user'

export interface Props {
  isEdit: boolean
  itemEdit?: Email
  closeModal: () => void
}

const FormEmails: React.FC<Props> = ({ isEdit, itemEdit, closeModal }) => {
  const dispatch = useAppDispatch()
  const roleUser = useAppSelector(store => store.roleUserActive)
  const [present] = useIonToast()
  const initialValue = isEdit
    ? { ...itemEdit, email: itemEdit?.email }
    : { email: '' }
  const [email, setEmail] = useState<Email>(initialValue as Email)

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    closeModal()

    // run services edit here!
    if (roleUser.id.toString() === '1') {
      if (isEdit) {
        dispatch(editEmail(email))
      } else {
        dispatch(addEmail({ ...email, id: Math.ceil(Math.random() * 1000) }))
      }
    } else {
      if (isEdit) {
        dispatch(editEmailCommerce(email))
      } else {
        dispatch(addEmailCommerce({ ...email, id: Math.ceil(Math.random() * 1000) }))
      }
    }
    present({
      message: isEdit ? 'Datos guardados' : 'Dato creado',
      duration: 2500,
      position: 'bottom',
      color: 'success'
    })
  }

  const handleChange = (e: any) => {
    setEmail(prevValue => ({
      ...prevValue,
      [e.target.name]: e.target.value
    }))
  }

  return (
		<div className="box ion-padding">
			<form className="form" onSubmit={e => { handleSubmit(e) }}>
				<TitleForm title={`${isEdit ? 'Editar' : 'Agregar'} correo`} />
				<Input label="Correo" type="email" name="email" value={email.email} onChange={e => { handleChange(e) }}/>
				<div className="ion-padding">
					<IonText>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</IonText>
				</div>
				<div className="ion-text-end">
					<IonButton color="secondary" shape="round" type="submit">{isEdit ? 'Guardar' : 'Crear'}</IonButton>
					<IonButton color="secondary" shape="round" fill="clear" type="button" onClick={() => { closeModal() }}>Cancelar</IonButton>
				</div>
			</form>
		</div>
  )
}

export default FormEmails
