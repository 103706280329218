import { current, type PayloadAction, createSlice } from '@reduxjs/toolkit'

import type { Commerce, Address, Email, Identity, Phone, SocialMedia } from '../../models'
import {
  getLocalStorage,
  setLocalStorage
} from '../../utilities/localStorage.utility'

const initialState: Commerce | null = {
  id: null,
  avatar: '',
  portraitPhoto: '',
  username: '',
  companyName: '',
  accountId: '',
  identities: [],
  phones: [],
  emails: [],
  socialmedia: [],
  shedules: '',
  country: {
    origin: {
      value: '',
      label: ''
    },
    residence: {
      value: '',
      label: ''
    }
  },
  direction: [],
  business: []
}

export const commerceSlice = createSlice({
  name: 'commerce',
  initialState: getLocalStorage('commerce') ? JSON.parse(getLocalStorage('commerce') as string) : initialState,
  reducers: {
    saveCommerce (state: Commerce, action: PayloadAction<Commerce>) {
      setLocalStorage('commerce', action.payload)
      return { ...state, ...action.payload }
    },
    editAvatarCommerce (state: Commerce, action) {
      const result = { ...state, ...action.payload }
      setLocalStorage('commerce', result)
      return result
    },
    editCompanyName (state: Commerce, action) {
      const result = { ...state, ...action.payload }
      setLocalStorage('commerce', result)
      return result
    },
    addIdentitiesCommerce (state: Commerce, action: PayloadAction<Identity>) {
      const identities = [...current(state).identities, action.payload]
      const result = { ...state, identities }
      setLocalStorage('commerce', result)
      return result
    },
    editIdentitiesCommerce (state: Commerce, action: PayloadAction<Identity>) {
      const filterItentities = [...current(state).identities].filter(identity => identity.id !== action.payload.id)
      const findIdentity = [...current(state).identities].find(identity => identity.id === action.payload.id)
      const editIdentity = { ...findIdentity, ...action.payload }
      const identities = [...filterItentities, editIdentity]
      const result = { ...state, identities }
      setLocalStorage('commerce', result)
      return result
    },
    addPhoneCommerce (state: Commerce, action: PayloadAction<Phone>) {
      const phones = [...current(state).phones, action.payload]
      const result = { ...state, phones }
      setLocalStorage('commerce', result)
      return result
    },
    editPhoneCommerce (state: Commerce, action: PayloadAction<Phone>) {
      const filterPhones = [...current(state).phones].filter(phone => phone.id !== action.payload.id)
      const findPhone = [...current(state).phones].find(phone => phone.id === action.payload.id)
      const editPhone = { ...findPhone, ...action.payload }
      const phones = [...filterPhones, editPhone]
      const result = { ...state, phones }
      setLocalStorage('commerce', result)
      return result
    },
    addEmailCommerce (state: Commerce, action: PayloadAction<Email>) {
      const emails = [...current(state).emails, action.payload]
      const result = { ...state, emails }
      setLocalStorage('commerce', result)
      return result
    },
    editEmailCommerce (state: Commerce, action: PayloadAction<Email>) {
      const filterEmails = [...current(state).emails].filter(email => email.id !== action.payload.id)
      const findEmail = [...current(state).emails].find(email => email.id === action.payload.id)
      const editEmail = { ...findEmail, ...action.payload }
      const emails = [...filterEmails, editEmail]
      const result = { ...state, emails }
      setLocalStorage('commerce', result)
      return result
    },
    addSocialMediaCommerce (state: Commerce, action: PayloadAction<SocialMedia>) {
      const socials = [...current(state).socialmedia, action.payload]
      const result = { ...state, socialmedia: socials }
      setLocalStorage('commerce', result)
      return result
    },
    editSocialMediaCommerce (state: Commerce, action: PayloadAction<SocialMedia>) {
      const filterSocialMedia = [...current(state).socialmedia].filter(social => social.id !== action.payload.id)
      const findSocialMedia = [...current(state).socialmedia].find(social => social.id === action.payload.id)
      const editSocialMedia = { ...findSocialMedia, ...action.payload }
      const socialmedia = [...filterSocialMedia, editSocialMedia]
      const result = { ...state, socialmedia }
      setLocalStorage('commerce', result)
      return result
    },
    editShedulesCommerce (state: Commerce, action) {
      const result = { ...state, ...action.payload }
      setLocalStorage('commerce', result)
      return result
    },
    editCountriesCommerce (state: Commerce, action) {
      const result = { ...state, ...action.payload }
      setLocalStorage('commerce', result)
      return result
    },
    addAddressCommerce (state: Commerce, action: PayloadAction<Address>) {
      const direction = [...current(state).direction, action.payload]
      const result = { ...state, direction }
      setLocalStorage('commerce', result)
      return result
    },
    editAddressCommerce (state: Commerce, action: PayloadAction<Address>) {
      const filterAddress = [...current(state).direction].filter(address => address.id !== action.payload.id)
      const findAddress = [...current(state).direction].find(address => address.id === action.payload.id)
      const editAddress = { ...findAddress, ...action.payload }
      const address = [...filterAddress, editAddress]
      const result = { ...state, direction: address }
      setLocalStorage('commerce', result)
      return result
    }

  }
})

export const {
  saveCommerce,
  editAvatarCommerce,
  editCompanyName,
  addIdentitiesCommerce,
  editIdentitiesCommerce,
  addPhoneCommerce,
  editPhoneCommerce,
  addEmailCommerce,
  editEmailCommerce,
  addSocialMediaCommerce,
  editSocialMediaCommerce,
  editShedulesCommerce,
  editCountriesCommerce,
  addAddressCommerce,
  editAddressCommerce
} = commerceSlice.actions
export default commerceSlice.reducer
