import { IonButton, IonList, useIonToast } from '@ionic/react'
import { useState } from 'react'
import { Input } from '../../../../components/Atoms/Input/Input'
import { TitleForm } from '../../../../components/Atoms/TitleForm'

export interface Props {
  closeModal: () => void
}

interface passwordType {
  currentpass: string | null
  validate1: string | null
  validate2: string | null
}

const FormChangePassword: React.FC<Props> = ({ closeModal }) => {
  const [present] = useIonToast()
  const [password, setPassword] = useState<passwordType>({
    currentpass: null,
    validate1: null,
    validate2: null
  })

  const handleLogin = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (password.validate1 !== password.validate2) return

    present({
      message: 'Cambio guardado exitosamente',
      duration: 2500,
      position: 'bottom',
      color: 'success'
    })
    closeModal()
  }

  const handleChange = (e: any) => {
    setPassword(values => ({
      ...values,
      [e.target.name]: e.target.value
    }))
  }

  return (
		<div className="box ion-padding">
			<form className="form" onSubmit={e => { handleLogin(e) }}>
        <TitleForm title="Cambio de contraseña" />
      	<IonList>
					<Input
            label="Clave actual"
            type="password"
            name="currentpass"
            value={password.currentpass}
            onChange={e => { handleChange(e) }}
          />
					<TitleForm title="Ingrese la nueva contraseña" customClass="ion-padding-bottom" />
          <Input
            label="Contraseña nueva"
            type="password"
            name="validate1"
            value={password.validate1}
            onChange={e => { handleChange(e) }}
          />
          <Input
            label="Repetir contraseña"
            type="password"
            name="validate2"
            value={password.validate2}
            onChange={e => { handleChange(e) }}
          />
        </IonList>
				<div className="ion-text-end">
					<IonButton color="secondary" shape="round" type="submit">Guardar</IonButton>
					<IonButton color="secondary" shape="round" fill="clear" type="button" onClick={() => { closeModal() }}>Cancelar</IonButton>
				</div>
    	</form>
		</div>
  )
}

export default FormChangePassword
