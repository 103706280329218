import { IonBackButton, IonButtons } from '@ionic/react'
import { Redirect, useRouteMatch } from 'react-router'
import { WrapperApp } from '../../components/Organisms/WrapperApp/WrapperApp'
import { ListOfActivities } from './components/ListOfActivities'
import { ListOfConnectedAccount } from './components/ListOfConnectedAccount'
import { ListOfKeys } from './components/ListOfKeys'
import { ListOfSecurityQuestions } from './components/ListOfSecurityQuestions'

interface MatchParams {
  section: string
}

const EditSegurityConfig: React.FC = () => {
  const routerMatch = useRouteMatch<MatchParams>()

  const renderComponent = (id: string) => {
    switch (id) {
      case '1': return <ListOfKeys />
      case '2': return <ListOfSecurityQuestions />
      case '3': return <ListOfConnectedAccount />
      case '4': return <ListOfActivities />
      default: return <Redirect to="/perfil" />
    }
  }

  return (
		<WrapperApp
			pageName="Perfil Natural"
			renderButtons={() =>
			<IonButtons slot="start">
				<IonBackButton defaultHref="/perfil" />
			</IonButtons>}
		>
			{renderComponent(routerMatch.params.section)}
		</WrapperApp>
  )
}

export default EditSegurityConfig
