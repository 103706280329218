import { IonLabel, IonSegment, IonSegmentButton } from '@ionic/react'
import { cardSharp, cashSharp, gridSharp, languageSharp, notificationsSharp, peopleSharp, walletSharp } from 'ionicons/icons'
import { useState } from 'react'
import { SectionListOfData } from '../../../../components/Organisms/SectionListOfData/SectionListOfData'
import MenuDinamic from '../../../MenuDinamic/MenuDinamic'

const dataUserPreference = [
  {
    title: 'Notificaciones',
    icon: notificationsSharp,
    routerLink: '1',
    typeProfile: 'natural/settings/preference'
  },
  {
    title: 'Canal de uso preferido',
    icon: cardSharp,
    routerLink: '2',
    typeProfile: 'natural/settings/preference'
  },
  {
    title: 'Moneda determinada',
    icon: cashSharp,
    routerLink: '3',
    typeProfile: 'natural/settings/preference'
  },
  {
    title: 'Idioma',
    icon: languageSharp,
    routerLink: '4',
    typeProfile: 'natural/settings/preference'
  }
]

const dataUserCheckout = [
  {
    title: 'Montos rápidos',
    icon: notificationsSharp,
    routerLink: '1',
    typeProfile: 'natural/settings/checkout'
  },
  {
    title: 'Impuesto sobre la venta',
    icon: cardSharp,
    routerLink: '2',
    typeProfile: 'natural/settings/checkout'
  },
  {
    title: 'Pedidos realizados',
    icon: cashSharp,
    routerLink: '3',
    typeProfile: 'natural/settings/checkout'
  },
  {
    title: 'Pago',
    icon: walletSharp,
    routerLink: '4',
    typeProfile: 'natural/settings/checkout'
  },
  {
    title: 'Gestion de clientes',
    icon: peopleSharp,
    routerLink: '5',
    typeProfile: 'natural/settings/checkout'
  },
  {
    title: 'Custom grid',
    icon: gridSharp,
    routerLink: '6',
    typeProfile: 'natural/settings/checkout'
  }
]

const dataUserOrders = [
  {
    title: 'Creación el pedido',
    routerLink: '1',
    typeProfile: 'natural/settings/orders'
  },
  {
    title: 'Notificaciones de pedidos',
    routerLink: '2',
    typeProfile: 'natural/settings/orders'
  },
  {
    title: 'Sincronizar',
    routerLink: '3',
    typeProfile: 'natural/settings/orders'
  }
]

export function SettingsSegmentPage () {
  const [segment, setSegment] = useState<string>('preference')

  return (
		<>
		  <IonSegment value={segment} scrollable onIonChange={e => { setSegment(e.detail.value as string) }}>
				<IonSegmentButton value="cobro"><IonLabel>Cobro</IonLabel></IonSegmentButton>
				<IonSegmentButton value="menu"><IonLabel>Menu</IonLabel></IonSegmentButton>
				<IonSegmentButton value="ordenes"><IonLabel>Ordenes</IonLabel></IonSegmentButton>
				<IonSegmentButton value="preference"><IonLabel>Preferencia</IonLabel></IonSegmentButton>
      </IonSegment>
			 {segment === 'cobro' && <SectionListOfData titleSection="General" dataList={dataUserCheckout} />}
			 {segment === 'menu' && <MenuDinamic />}
			 {segment === 'ordenes' && <SectionListOfData titleSection="Ordenes" dataList={dataUserOrders} />}
			 {segment === 'preference' && <SectionListOfData titleSection="Preferencia" dataList={dataUserPreference} />}
		</>
  )
}
