import { IonButton, IonItem, IonLabel, IonSelect, IonSelectOption, useIonToast } from '@ionic/react'
import { useState } from 'react'
import { Input } from '../../../../components/Atoms/Input/Input'

export function FormRequest () {
  const [request, setRequest] = useState({
    reason: '',
    amount: 0,
    bank: ''
  })

  const [present] = useIonToast()

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    console.log({ request })

    setRequest({
      reason: '',
      amount: 0,
      bank: ''
    })

    present({
      message: 'Solicitud enviada',
      duration: 2500,
      position: 'bottom',
      color: 'success'
    })
  }

  const handleChange = (e: any) => {
    setRequest(prevValue => ({
      ...prevValue,
      [e.target.name]: e.target.value
    }))
  }

  return (
    <>
      <IonItem lines='none' className='ion-padding-top'>
        <IonLabel>¿Quieres retirar dinero de e4cash? Completa tu solicitud a continuación.</IonLabel>
      </IonItem>
      <form className='fromion-padding-horizontal' onSubmit={e => { handleSubmit(e) }}>
        <IonItem className="input-item">
          <IonLabel position="floating">Banco</IonLabel>
          <IonSelect
            value={request.bank}
            placeholder="Seleccione uno de tus bancos"
            name="bank"
            onIonChange={(e) => { handleChange(e) }}
          >
            <IonSelectOption value='1'>
              Banco 1
            </IonSelectOption>
            <IonSelectOption value='2'>
              banco 2
            </IonSelectOption>
          </IonSelect>
        </IonItem>
        <Input label="Escribe el monto aquí" type="number" name="amount" value={request.amount} onChange={e => { handleChange(e) }} />
        <IonItem className="input-item">
          <IonLabel position="floating">Motivo del retiro</IonLabel>
          <IonSelect
            value={request.reason}
            placeholder="Seleccione una opción"
            name="reason"
            onIonChange={(e) => { handleChange(e) }}
          >
            <IonSelectOption value='1'>
            Motivos personales
            </IonSelectOption>
            <IonSelectOption value='2'>
            No usaré más la app
            </IonSelectOption>
            <IonSelectOption value='3'>
            Necesito el dinero
            </IonSelectOption>
            <IonSelectOption value='4'>
            Otros
            </IonSelectOption>
          </IonSelect>
        </IonItem>
        <div className="ion-text-end ion-margin-top">
          <IonButton color="secondary" shape="round" type="submit">
            Enviar solicitud
          </IonButton>
        </div>
      </form>
    </>
  )
}
