import { IonButton, IonItem, IonLabel, useIonToast } from '@ionic/react'

interface Props {
  setIsOpenModalSuccessPayment: React.Dispatch<React.SetStateAction<boolean>>
}

export function ModalSuccessPayment ({ setIsOpenModalSuccessPayment }: Props) {
  const [present] = useIonToast()

  const handleSendEmail = () => {
    setIsOpenModalSuccessPayment(false)
    present({
      message: 'Recibo enviado.',
      duration: 2500,
      position: 'bottom',
      color: 'success'
    })
  }
  const handleClose = () => {
    setIsOpenModalSuccessPayment(false)
  }

  return (
		<div style={{ minWidth: '100%' }}>
			<div style={{ display: 'flex', justifyContent: 'space-between' }} className='ion-padding'>
				<IonLabel slot='start'>Venta nueva</IonLabel>
				<IonLabel slot='end'>Agregar cliente</IonLabel>
      </div>
			<div>
				<IonItem className='ion-no-padding ion-no-margin ion-text-center' lines='none'>
					<IonLabel>
						<h1>$1245.88</h1>
					</IonLabel>
				</IonItem>
				<IonItem className='ion-no-padding ion-no-margin ion-text-center' lines='none'>
					<IonLabel>¿Como desea obtener el recibo?</IonLabel>
				</IonItem>
				<div>
					<IonButton color="secondary" size='large' expand='block' onClick={() => { handleSendEmail() }}>Correo electrónico</IonButton>
					<IonButton color="secondary" size='large' expand='block' onClick={() => { handleClose() }}>Sin recibo</IonButton>
				</div>
			</div>
    </div>
  )
}
