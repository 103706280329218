import { IonBackButton, IonButtons } from '@ionic/react'
import { Redirect, useRouteMatch } from 'react-router'
import { WrapperApp } from '../../components/Organisms/WrapperApp/WrapperApp'
import { QuickAmounts } from './components/QuickAmounts/QuickAmounts'
import { SalesTaxes } from './components/SalesTaxes/SalesTaxes'
import { OrderTickets } from './components/OrderTickets/OrderTickets'
import { Payment } from './components/Payment/Payment'
import { CustomerManagement } from './components/CustomerManagement/CustomerManagement'
import { CustomGrid } from './components/CustomGrid/CustomGrid'

interface MatchParams {
  section: string
}

const EditSettingCheckout: React.FC = () => {
  const routerMatch = useRouteMatch<MatchParams>()

  const renderComponent = (id: string) => {
    switch (id) {
      case '1': return <QuickAmounts />
      case '2': return <SalesTaxes />
      case '3': return <OrderTickets />
      case '4': return <Payment />
      case '5': return <CustomerManagement />
      case '6': return <CustomGrid />
      default: return <Redirect to="/perfil" />
    }
  }

  return (
		<WrapperApp
			pageName="Perfil Natural"
			renderButtons={() =>
			<IonButtons slot="start">
				<IonBackButton defaultHref="/perfil" />
			</IonButtons>}
		>
			{renderComponent(routerMatch.params.section)}
		</WrapperApp>
  )
}

export default EditSettingCheckout
