import bankSantander from '../assets/bank-santander.png'
import bankVenezuela from '../assets/bank-venezuela.png'
import bankBanesco from '../assets/bank-banesco.png'
import bankProvincial from '../assets/bank-provincial.png'
import bankMercantil from '../assets/bank-mercantil.png'

enum BanksNamesFromCode {
  PROVINCIAL = '0108',
  BANESCO = '0134',
  MERCANTIL = '0105',
  VENEZUELA = '0102',
  SANTANDER = '0049'
}

const banksNames = {
  [BanksNamesFromCode.VENEZUELA]: bankVenezuela,
  [BanksNamesFromCode.BANESCO]: bankBanesco,
  [BanksNamesFromCode.MERCANTIL]: bankMercantil,
  [BanksNamesFromCode.PROVINCIAL]: bankProvincial,
  [BanksNamesFromCode.SANTANDER]: bankSantander
}

export const generateIconBank = (accountNumber: keyof typeof banksNames) => {
  const icon = banksNames[accountNumber]
  return icon
}
