import './WrapperCenterVertically.css'

interface WrapperCenterVerticallyProps {
  children: React.ReactNode
}

const WrapperCenterVertically: React.FC<WrapperCenterVerticallyProps> = ({ children }) => {
  return (
    <div className="center-vertically">
      <div>{ children }</div>
    </div>
  )
}

export default WrapperCenterVertically
