import { IonBackButton, IonButtons } from '@ionic/react'
import { Redirect, useRouteMatch } from 'react-router'
import { WrapperApp } from '../../components/Organisms/WrapperApp/WrapperApp'
import { ListOfBankAccount } from './components/ListOfBankAccount'
import { ListOfCarsBank } from './components/ListOfCarsBank'

interface MatchParams {
  section: string
}

const EditBanksAndCards: React.FC = () => {
  const routerMatch = useRouteMatch<MatchParams>()

  const renderComponent = (id: string) => {
    switch (id) {
      case '1': return <ListOfBankAccount />
      case '2': return <ListOfCarsBank />
      default: return <Redirect to="/perfil" />
    }
  }

  return (
		<WrapperApp
			pageName="Perfil Natural"
			renderButtons={() =>
			<IonButtons slot="start">
				<IonBackButton defaultHref="/perfil" />
			</IonButtons>}
		>
			{renderComponent(routerMatch.params.section)}
		</WrapperApp>
  )
}

export default EditBanksAndCards
