import { setupIonicReact } from '@ionic/react'
import { Provider } from 'react-redux'
import { AppRouter } from './AppRouter'
import { store } from './store/store'

setupIonicReact()

const App = () => {
  return (
    <Provider store={store}>
      <AppRouter />
    </Provider>
  )
}

export default App
