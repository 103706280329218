import { IonContent, IonIcon, IonItem, IonLabel, IonList, IonPopover, useIonToast } from '@ionic/react'
import { personAddSharp } from 'ionicons/icons'
import { useHistory } from 'react-router'
import { type Commerce } from '../../../models/commerce'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { saveCommerce } from '../../../store/states/commerce'
import { saveRolUserActive } from '../../../store/states/roleUserActive'
import { BusinessItem } from '../../Atoms/BusinessItem'
import './PopoverChangeCommerce.css'

export interface Props {
  popover: React.RefObject<HTMLIonPopoverElement>
  popoverOpen: boolean
  setPopoverOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const PopoverChangeCommerce: React.FC<Props> = ({ popover, popoverOpen, setPopoverOpen }) => {
  const commerce = useAppSelector(store => store.commerce)
  const roleUser = useAppSelector(store => store.roleUserActive)
  const dispatch = useAppDispatch()
  const history = useHistory()
  const [present] = useIonToast()

  const handleChangeCommerce = (commerceSelect: Commerce) => {
    history.replace('/perfil-comercio', { replace: true })
    // if(commerce.id === commerceSelect.id) return
    dispatch(saveCommerce({ ...commerceSelect, business: commerce.business }))
    present({
      message: `Sesión iniciada con: ${commerceSelect.companyName}`,
      duration: 1500,
      position: 'top'
    })
    if (roleUser.id.toString() === '2') return
    dispatch(saveRolUserActive({ id: '2', type: 'commerce' }))
  }

  return (
		<IonPopover dismissOnSelect={true} ref={popover} isOpen={popoverOpen} onDidDismiss={() => { setPopoverOpen(false) }} size="auto" className='popover-select-commerce'>
			<IonContent>
				<IonList>
					<IonItem lines="none">
						<IonLabel>Cuentas asociadas</IonLabel>
					</IonItem>
					{commerce.business.map(commerceItem =>
						<BusinessItem key={Number(commerceItem.id)} businessItem={commerceItem} handleClick={() => { handleChangeCommerce(commerceItem) }} />
					)}
					<IonItem lines="none" button={true} detail={true}>
						<IonIcon slot="start" icon={personAddSharp}></IonIcon>
						<IonLabel>Agregar cuenta</IonLabel>
					</IonItem>
				</IonList>
			</IonContent>
		</IonPopover>
  )
}

export default PopoverChangeCommerce
