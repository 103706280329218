import { IonButton, IonButtons, IonCard, IonCheckbox, IonIcon, IonItem, IonLabel, IonMenuButton, IonText, useIonToast, IonGrid, IonRow, IonCol, IonSelect, IonSelectOption } from '@ionic/react'
import { cloudUploadSharp, camera } from 'ionicons/icons'
import { useState } from 'react'
import { useHistory } from 'react-router'
import { Input } from '../../components/Atoms/Input/Input'
import { TitleForm } from '../../components/Atoms/TitleForm'
import { LineSeparator } from '../../components/Atoms/LineSeparator/LineSeparator'
import { WrapperApp } from '../../components/Organisms/WrapperApp/WrapperApp'
import { HeaderDashboard } from '../Dashboard/components/HeaderDashboard'
import { Camera, CameraResultType } from '@capacitor/camera'

export function CheckoutCreateProduct () {
  const [present] = useIonToast()
  const history = useHistory()
  const [cantOfPist, setCantOfPist] = useState(false)
  const [productImage, setProductImage] = useState<null | string>(null)
  const [statusProduct, setStatusProduct] = useState<string>('activo')
  const [sellEvenIfItDoesntExist, setSellEvenIfItDoesntExist] = useState(false)
  const [product, setProduct] = useState({
    title: '',
    description: '',
    price: '',
    costForItem: '',
    sku: '',
    code: '',
    cantOfPist: false,
    nameSite: '',
    cantLocation: 0,
    searchType: '',
    vendor: '',
    tags: ''
  })

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    // run services edit here!
    present({
      message: 'Producto creado',
      duration: 2500,
      position: 'bottom',
      color: 'success'
    })
    history.replace('/checkout', { replace: true })
  }

  const handleChange = (e: any) => {
    console.log({ e })
    setProduct(values => ({
      ...values,
      [e.target.name]: e.target.value
    }))
  }

  const uploadImage = async () => {
    try {
      const image = await Camera.getPhoto({
        quality: 100,
        allowEditing: true,
        resultType: CameraResultType.Uri
      })
      setProductImage(image.webPath as string)
      console.log({ image })
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <WrapperApp
      pageName="Crear producto"
      hiddenAvatar={true}
      renderButtons={() => (
        <>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <HeaderDashboard />
        </>
      )}
    >
      <form onSubmit={e => { handleSubmit(e) }}>
        <IonGrid>
          <IonRow>
            <IonCol size="12" size-md="6" size-lg="7" size-xl="8">
              <IonCard className="box ion-padding">
                <Input label="Titulo" type="text" name="title" value={product.title} onChange={e => { handleChange(e) }}/>
                <Input label="Descripción" type="text" name="description" value={product.description} onChange={e => { handleChange(e) }} />
              </IonCard>

              <IonCard className="box ion-padding">
                <IonItem className='ion-no-padding ion-no-margin' lines='none'>
                  <TitleForm title="Imagen" customClass="ion-no-padding ion-no-margin" />
                  {productImage && <IonIcon icon={camera} size='large' slot='end' onClick={async () => { await uploadImage() }} />}
                </IonItem>

                <LineSeparator noSpaceTop />

                { productImage
                  ? <div className="box ion-padding" style={{ maxHeight: '200px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <img src={productImage} alt="product image" style={{ objectFit: 'scale-down', maxHeight: '200px' }} />
                    </div>
                  : <div className="box ion-padding">
                      <div className='ion-text-center ion-padding' style={{ border: '1px dashed', borderRadius: '10px', cursor: 'pointer' }} onClick={async () => { await uploadImage() }}>
                        <IonIcon icon={cloudUploadSharp} size='large' />
                        <IonText><p className='ion-no-margin' style={{ fontSize: '14px', paddingTop: '4px', paddingBottom: '8px' }}>Carga una imagen</p></IonText>
                        <IonText><p className='ion-no-margin' style={{ fontSize: '14px' }}>Tamaño recomendado 100x100</p></IonText>
                      </div>
                    </div>
                }
              </IonCard>

              <IonCard className="box ion-padding">
                <IonItem className='ion-no-padding' lines='none'>
                  <TitleForm title="Precio" customClass="ion-no-padding ion-no-margin" />
                </IonItem>
                <LineSeparator noSpaceTop />
                <div>
                  <Input label="precio" type="text" name="price" value={product.price} onChange={e => { handleChange(e) }}/>
                  <Input label="costo por item" type="text" name="costForItem" value={product.costForItem} onChange={e => { handleChange(e) }} />
                </div>
                <div>
                  <IonLabel>Los clientes no verán esto</IonLabel>
                </div>
              </IonCard>

              <IonCard className="box ion-padding">
                <IonItem className='ion-no-padding' lines='none'>
                  <TitleForm title="Inventario" customClass="ion-no-padding ion-no-margin" />
                </IonItem>
                <LineSeparator noSpaceTop />
                <div className='ion-padding-top'>
                  <Input label="SKU (stok keeping unit)" type="text" name="sku" value={product.sku} onChange={e => { handleChange(e) }}/>
                  <Input label="Código de barra ( ISBN, UPC, GTIN, etc)" type="text" name="code" value={product.code} onChange={e => { handleChange(e) }} />
                  <IonItem lines='none'>
                    <IonCheckbox name="cantOfPist" defaultChecked={false} value={cantOfPist} slot="start" onIonChange={(e) => { setCantOfPist(e.detail.checked) }}/>
                    <IonLabel className="ion-text-wrap" id="cantOfPist">Cantidad de pistas</IonLabel>
                  </IonItem>
                  <IonItem lines='none'>
                    <IonCheckbox name="sellEvenIfItDoesntExist" defaultChecked={false} value={sellEvenIfItDoesntExist} slot="start" onIonChange={(e) => { setSellEvenIfItDoesntExist(e.detail.checked) }}/>
                    <IonLabel className="ion-text-wrap" id="sellEvenIfItDoesntExist">Continuar vendiendo cuando no haya existencia</IonLabel>
                  </IonItem>
                  <TitleForm title="Cantidad" customClass="ion-no-margin-top" />
                  <div style={{ display: 'flex', gap: '1rem', alignItems: 'center', justifyContent: 'space-between', marginBottom: '1rem' }}>
                    <div style={{ flex: 1 }} >
                      <Input noSpace label="Nombre del lugar" type="text" name="nameSite" value={product.nameSite} onChange={e => { handleChange(e) }} />
                    </div>
                    <IonItem lines='none'>
                      <IonLabel>
                        <IonText color='secondary' className="ion-text-wrap">Disponible</IonText>
                      </IonLabel>
                    </IonItem>
                  </div>
                  <div style={{ display: 'flex', gap: '1rem', alignItems: 'center', justifyContent: 'flex-start', marginBottom: '1rem' }}>
                    <IonLabel className="ion-text-wrap" id="cantOfPist">Ubicación de tienda</IonLabel>
                    <div>
                      <Input noSpace label="Cantidad" type="number" name="cantLocation" value={product.cantLocation} onChange={e => { handleChange(e) }} />
                    </div>
                  </div>
                </div>
              </IonCard>

              <IonCard className="box ion-padding">
                <IonItem className='ion-no-padding' lines='none'>
                  <TitleForm title="Opciones" customClass="ion-no-padding ion-no-margin" />
                </IonItem>
                <LineSeparator noSpaceTop />
                <IonItem lines='none'>
                  <IonCheckbox name="sellEvenIfItDoesntExist" defaultChecked={false} value={sellEvenIfItDoesntExist} slot="start" onIonChange={(e) => { setSellEvenIfItDoesntExist(e.detail.checked) }}/>
                  <IonLabel className="ion-text-wrap" id="sellEvenIfItDoesntExist">Continuar vendiendo cuando no haya existencia</IonLabel>
                </IonItem>
              </IonCard>

              <IonCard className="box ion-padding">
                <IonItem className='ion-no-padding' lines='none'>
                  <TitleForm title="Lista de motores" customClass="ion-no-padding ion-no-margin" />
                  <IonItem className='ion-no-padding' lines='none' slot='end'><IonText color='secondary'>Editar</IonText></IonItem>
                </IonItem>
                <LineSeparator noSpaceTop noSpaceBottom />

                <div className='ion-padding-bottom ion-padding-top'>
                  <IonLabel className='ion-no-padding'>Agregue un título y una descripción para ver cómo podría aparecer este producto en una lista de motores de búsqueda</IonLabel>
                </div>
              </IonCard>
            </IonCol>
            <IonCol size="12" size-md="6" size-lg="5" size-xl="4">
              <IonCard className="box ion-padding">
                <TitleForm title="Estatus del producto" customClass="ion-padding-top ion-no-margin" />
                <LineSeparator />
                <IonItem className="input-item" lines='none'>
                  <IonLabel position="floating">Estatus</IonLabel>
                    <IonSelect name='seller' placeholder="status" value={statusProduct} onIonChange={e => { setStatusProduct(e.detail.value) }}>
                      <IonSelectOption value="activo">Activo</IonSelectOption>
                      <IonSelectOption value="inactivo">Inactivo</IonSelectOption>
                    </IonSelect>
                  </IonItem>
                  <IonText>Este producto estará disponible para 2 canales de venta.</IonText>

                  <LineSeparator />

                  <TitleForm title="Canales de venta y apps" noSpaceTop />
                  <IonText color='secondary'>Detectar todas</IonText>

                  <LineSeparator />

                  <IonItem lines='none'>
                    <IonCheckbox name="cantOfPist" defaultChecked={false} value={cantOfPist} slot="start" onIonChange={(e) => { setCantOfPist(e.detail.checked) }}/>
                    <IonLabel className="ion-text-wrap" id="cantOfPist">
                      <IonLabel>Tienda online</IonLabel>
                      <IonText color='secondary' style={{ fontSize: '14px' }}>Horario disponibilidad</IonText>
                    </IonLabel>
                  </IonItem>

                  <IonItem lines='none'>
                    <IonCheckbox name="sellEvenIfItDoesntExist" defaultChecked={false} value={sellEvenIfItDoesntExist} slot="start" onIonChange={(e) => { setSellEvenIfItDoesntExist(e.detail.checked) }}/>
                    <IonLabel className="ion-text-wrap" id="sellEvenIfItDoesntExist">facebook</IonLabel>
                  </IonItem>
              </IonCard>
              <IonCard className="box ion-padding">
                <TitleForm title="Organización del producto" customClass="ion-padding-top ion-no-margin" />
                <LineSeparator />

                <Input label="Buscar tipo" type="text" name="searchType" value={product.searchType} onChange={e => { handleChange(e) }}/>
                <Input label="Vendedor" type="text" name="vendor" value={product.vendor} onChange={e => { handleChange(e) }}/>
                <Input label="tags" type="text" name="tags" value={product.tags} onChange={e => { handleChange(e) }}/>

               <IonItem lines='none' className='ion-no-padding'>
                <IonText color='secondary' style={{ fontSize: '14px' }} slot='end'>Administrar etiquetas</IonText>
               </IonItem>
              </IonCard>
            </IonCol>
          </IonRow>
        </IonGrid>
        <div className="ion-text-end ion-padding">
          <IonButton color="secondary" shape="round" type="submit">Guardar</IonButton>
        </div>
      </form>
    </WrapperApp>
  )
}
