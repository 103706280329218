type GenderType = Record<number, string>

export const genderName = (genderId: number) => {
  if (!genderId) return null

  const genders: GenderType = {
    1: 'Masculino',
    2: 'Femenino',
    0: 'Otros'
  }

  return genders[genderId] ?? 'No definido'
}

export const MESSAGE_ERROR_DEFAULT = 'Error, algo salió mal.'

export const createUserAvatarDefault = (name: string) => {
  return `https://api.dicebear.com/5.x/initials/svg?seed=${name}`
}

export const createImageDefault = (name: string) => {
  return `https://api.dicebear.com/5.x/shapes/svg?seed=${name}`
}

export const getDayName = (dateString: Date) => {
  return new Intl.DateTimeFormat('en-Us', { weekday: 'long' }).format(new Date(dateString))
}
