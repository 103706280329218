import { IonItem, IonIcon, IonText, IonRadioGroup, IonRadio, IonLabel, IonButton } from '@ionic/react'
import { closeOutline } from 'ionicons/icons'
import { LineSeparator } from '../../../../components/Atoms/LineSeparator/LineSeparator'
import { TitleForm } from '../../../../components/Atoms/TitleForm'
import { useState } from 'react'

interface Props {
  setIsOpenModalExport: React.Dispatch<React.SetStateAction<boolean>>
}

export function ModalExportProducts ({ setIsOpenModalExport }: Props) {
  const [exportValue, setExportValue] = useState('currentPage')
  const [exportType, setExportType] = useState('csvCalcSheet')

  const handleExport = () => {
    console.log({ exportValue })
    console.log({ exportType })
    setIsOpenModalExport(false)
  }

  const onChange = (e: any) => {
    setExportValue(e.detail.value)
  }

  const onChangeType = (e: any) => {
    setExportType(e.detail.value)
  }

  return (
    <>
      <IonItem className='ion-no-padding ion-no-margin' lines='none'>
        <TitleForm title="Exportar Productos" customClass="ion-no-padding ion-no-margin" />
        <IonIcon icon={closeOutline} size='large' slot='end' onClick={() => { setIsOpenModalExport(false) }} />
      </IonItem>
      <LineSeparator noSpaceTop />
      <div style={{ maxWidth: '700px' }}>
        <IonText color='dark'>
          Este archivo CSV puede actualizar toda la información del producto. Para actualizar solo las cantidades de inventario, use el CSV
          <IonText color='secondary'> file for inventory</IonText>
        </IonText>
      </div>
      <TitleForm title="Exportar" />
      <IonRadioGroup value={exportValue} onIonChange={e => { onChange(e) }}>
        <IonItem lines='none'>
          <IonRadio slot="start" value="currentPage" />
          <IonLabel>Página actual</IonLabel>
        </IonItem>
        <IonItem lines='none'>
          <IonRadio slot="start" value="allProducts" />
          <IonLabel>Todos los productos</IonLabel>
        </IonItem>
        <IonItem lines='none'>
          <IonRadio slot="start" value="selectedProducts" />
          <IonLabel>Seleccionados: 0 productos</IonLabel>
        </IonItem>
        <IonItem lines='none'>
          <IonRadio slot="start" value="filteredProducts" />
          <IonLabel>2 productos que coinciden con su búsqueda</IonLabel>
        </IonItem>
      </IonRadioGroup>

      <TitleForm title="Exportar como" />
      <IonRadioGroup value={exportType} onIonChange={e => { onChangeType(e) }}>
        <IonItem lines='none'>
          <IonRadio slot="start" value="csvCalcSheet" />
          <IonLabel>CSV para Excel Numbers u otros programas de hojas de cálculo</IonLabel>
        </IonItem>
        <IonItem lines='none'>
          <IonRadio slot="start" value="csvSimple" />
          <IonLabel>Archivo CSV simple</IonLabel>
        </IonItem>
      </IonRadioGroup>
      <LineSeparator />
      <div style={{ maxWidth: '700px', paddingBottom: '1rem' }}>
        <IonText color='dark'>
          Obtenga más información sobre cómo
          <IonText color='secondary'> exportar productos a un archivo CSV </IonText>
          o
          <IonText color='secondary'> editor masivo</IonText>
        </IonText>
      </div>
      <div className="ion-text-end ion-padding-horizontal">
        <IonButton color="secondary" shape="round" onClick={() => { handleExport() }}>Exportar</IonButton>
      </div>
    </>
  )
}
