import { IonCard, IonItem, IonLabel, IonToggle } from '@ionic/react'
import { TitleForm } from '../../../../components/Atoms/TitleForm'

export function CustomerManagement () {
  return (
		<IonCard className="box ion-padding">
			<IonItem lines='none'>
				<IonLabel>
					<h2 style={{ fontWeight: 'bold' }}>Activar Perfiles instantáneos</h2>
				</IonLabel>
				<IonToggle color="secondary" slot="end" onIonChange={e => { console.log(e) }}></IonToggle>
			</IonItem>
			<IonLabel className='ion-padding-start'>
				<p className='ion-text-wrap ion-padding-start ion-padding-bottom'>Cuando un cliente realiza una transacción, se crea automáticamente un perfil en tu Directorio. Cada vez que haga una transacción con la misma tarjeta, su perfil se actualizará con los detalles de la transacción.</p>
				<p className='ion-text-wrap ion-padding-start ion-padding-bottom'>Cuando esta función está activada, eres responsable de cumplir con las leyes pertinentes de privacidad, incluido un aviso de la política de privacidad frente al cliente con respecto a la recopilación y al uso de información de los clientes.</p>
			</IonLabel>
			<div style={{ width: '100%', height: '1px', background: '#727070b1' }}></div>
			<TitleForm title='Agregar cliente a la venta' customClass='ion-padding-start'/>
			<div style={{ width: '100%', height: '0.5px', background: '#727070b1' }}></div>
			<IonItem lines='none' className='ion-text-wrap'>
				<IonLabel className='ion-text-wrap'>
					<h2 style={{ fontWeight: 'bold' }}>Mostrar botón para agregar cliente durante el proceso de pago</h2>
				</IonLabel>
				<IonToggle color="secondary" slot="end" onIonChange={e => { console.log(e) }}></IonToggle>
			</IonItem>
			<IonLabel className='ion-padding-start'>
				<p className='ion-text-wrap ion-padding-start ion-padding-bottom'>Agrega un cliente a la venta desde la vista del carrito</p>
			</IonLabel>
			<div style={{ width: '100%', height: '1px', background: '#727070b1' }}></div>
			<IonItem lines='none' className='ion-text-wrap'>
				<IonLabel className='ion-text-wrap'>
					<h2 style={{ fontWeight: 'bold' }}>Mostrar botón para agregar cliente después del proceso de pago</h2>
				</IonLabel>
				<IonToggle color="secondary" slot="end" onIonChange={e => { console.log(e) }}></IonToggle>
			</IonItem>
			<IonLabel className='ion-padding-start'>
				<p className='ion-text-wrap ion-padding-start ion-padding-bottom'>Agrega un cliente a la venta desde la pantalla de confirmación</p>
			</IonLabel>
			<div style={{ width: '100%', height: '1px', background: '#727070b1' }}></div>
			<IonItem lines='none' className='ion-text-wrap'>
				<IonLabel className='ion-text-wrap'>
					<h2 style={{ fontWeight: 'bold' }}>Escanea clientes usando la cámara del dispositivo</h2>
				</IonLabel>
				<IonToggle color="secondary" slot="end" onIonChange={e => { console.log(e) }}></IonToggle>
			</IonItem>
			<IonLabel className='ion-padding-start'>
				<p className='ion-text-wrap ion-padding-start ion-padding-bottom'>Agrega un cliente a la venta más rápido pulsando el icono de escaneado en la barra de búsqueda y escaneando tu código QR o código de barras.</p>
			</IonLabel>
		</IonCard>
  )
}
