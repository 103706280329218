import { Camera, CameraResultType } from '@capacitor/camera'
import { IonButton, IonButtons, IonCard, IonCol, IonGrid, IonIcon, IonItem, IonMenuButton, IonRow, IonText, useIonToast } from '@ionic/react'
import { camera, cloudUploadSharp } from 'ionicons/icons'
import { useState } from 'react'
import { useHistory } from 'react-router'
import { Input } from '../../components/Atoms/Input/Input'
import { LineSeparator } from '../../components/Atoms/LineSeparator/LineSeparator'
import { TitleForm } from '../../components/Atoms/TitleForm'
import { WrapperApp } from '../../components/Organisms/WrapperApp/WrapperApp'
import { HeaderDashboard } from '../Dashboard/components/HeaderDashboard'
import { useAppSelector } from '../../store/hooks'

export function CheckoutCreateCheckout () {
  const [present] = useIonToast()
  const history = useHistory()
  const commerce = useAppSelector(store => store.commerce)
  const [productImage, setProductImage] = useState<null | string>(null)
  const [product, setProduct] = useState({
    title: '',
    description: '',
    fixedPrice: 0,
    customQuantity: 0
  })

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    // run services edit here!
    present({
      message: 'Chekcout creado',
      duration: 2500,
      position: 'bottom',
      color: 'success'
    })
    history.replace('/checkout', { replace: true })
  }

  const handleChange = (e: any) => {
    console.log({ e })
    setProduct(values => ({
      ...values,
      [e.target.name]: e.target.value
    }))
  }

  const uploadImage = async () => {
    try {
      const image = await Camera.getPhoto({
        quality: 100,
        allowEditing: true,
        resultType: CameraResultType.Uri
      })
      setProductImage(image.webPath as string)
      console.log({ image })
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <WrapperApp
      pageName="Crear producto"
      hiddenAvatar={true}
      renderButtons={() => (
        <>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <HeaderDashboard />
        </>
      )}
    >
      <form onSubmit={e => { handleSubmit(e) }}>
        <IonGrid>
          <IonRow>
            <IonCol size="12" size-md="6" size-lg="7" size-xl="8">
              <IonCard className="box ion-padding">
                <IonItem className='ion-no-padding' lines='none'>
                  <TitleForm title="Detalles del producto" customClass="ion-no-padding ion-no-margin" />
                </IonItem>
                <LineSeparator noSpaceTop />
                <div>
                  <Input label="Título" type="text" name="title" value={product.title} onChange={e => { handleChange(e) }}/>
                  <Input label="Descripción" type="text" name="description" value={product.description} onChange={e => { handleChange(e) }} />
                </div>

                <IonGrid>
                  <IonRow className='ion-no-padding'>
                    <IonCol size="6">
                      <IonText><p className='ion-no-margin ion-text-nowrap' style={{ fontSize: '12px', marginBottom: '15px' }}>Establece un precio fijo para tus clientes</p></IonText>
                      <Input label="Precio fijo" type="number" name="fixedPrice" value={product.fixedPrice} onChange={e => { handleChange(e) }}/>
                    </IonCol>
                    <IonCol size="6">
                      <IonText><p className='ion-no-margin ion-text-nowrap' style={{ fontSize: '12px', marginBottom: '15px' }}>Permitir a los clientes ingresar cualquier cantidad</p></IonText>
                      <Input label="Cantidad personalizada" type="number" name="customQuantity" value={product.customQuantity} onChange={e => { handleChange(e) }}/>
                    </IonCol>
                  </IonRow>
                </IonGrid>

                <IonItem className='ion-no-padding ion-no-margin' lines='none'>
                  <TitleForm title="Imagen" customClass="ion-no-padding ion-no-margin" />
                  {productImage && <IonIcon icon={camera} size='large' slot='end' onClick={async () => { await uploadImage() }} />}
                </IonItem>

                <LineSeparator noSpaceTop />

                { productImage
                  ? <div className="box" style={{ maxHeight: '200px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <img src={productImage} alt="product image" style={{ objectFit: 'scale-down', maxHeight: '200px' }} />
                    </div>
                  : <div className="box">
                      <div className='ion-text-center ion-padding' style={{ border: '1px dashed', borderRadius: '10px', cursor: 'pointer' }} onClick={async () => { await uploadImage() }}>
                        <IonIcon icon={cloudUploadSharp} size='large' />
                        <IonText><p className='ion-no-margin' style={{ fontSize: '14px', paddingTop: '4px', paddingBottom: '8px' }}>Carga una imagen</p></IonText>
                        <IonText><p className='ion-no-margin' style={{ fontSize: '14px' }}>Tamaño recomendado 100x100</p></IonText>
                      </div>
                    </div>
                }
               </IonCard>
              <div className="ion-text-end ion-padding">
                <IonButton color="secondary" shape="round" type="submit">Crear checkout</IonButton>
              </div>
            </IonCol>
            <IonCol size="12" size-md="6" size-lg="5" size-xl="4">
              <IonCard className="box ion-padding">
                <IonItem className='ion-no-padding' lines='none'>
                  <TitleForm title="Preview" customClass="ion-no-padding ion-no-margin" />
                  <IonItem className='ion-no-padding' lines='none' slot='end'><IonText color='secondary'>Opciones</IonText></IonItem>
                </IonItem>
                <LineSeparator noSpaceTop noSpaceBottom />
                <IonCard className="ion-padding">
                  <div className="box ion-text-center">
                    <img src={commerce.avatar} alt={commerce.companyName} style={{ objectFit: 'scale-down', maxHeight: '40px' }} />
                  </div>
                  <IonText><span className='ion-text-center'>{ commerce.companyName }</span></IonText>
                  <LineSeparator />
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1rem' }}>
                    {
                      productImage
                        ? <img src={productImage} alt={product.title} style={{ objectFit: 'scale-down', maxHeight: '40px' }} />
                        : <div style={{ width: '40px', height: '40px', backgroundColor: '#f2f2f2' }}></div>
                    }
                    <IonText><span className='ion-text-center'>{ product.title }</span></IonText>
                  </div>
                </IonCard>
              </IonCard>
            </IonCol>
          </IonRow>
        </IonGrid>

      </form>
    </WrapperApp>
  )
}
