import { useState } from 'react'
import { Clipboard } from '@capacitor/clipboard'
import { IonAvatar, IonCard, IonIcon, IonImg, IonItem, IonLabel, useIonToast } from '@ionic/react'
import { copySharp, qrCodeSharp } from 'ionicons/icons'
import { TitleForm } from '../../../components/Atoms/TitleForm'
import ModalSmall from '../../../components/Organisms/ModalSmall/ModalSmall'
import { useAppSelector } from '../../../store/hooks'
import qr from '../../../assets/qr-code.png'

const ViewAccountWallet: React.FC = () => {
  const [isOpenModalShowQr, setIsOpenModalShowQr] = useState(false)
  const user = useAppSelector(store => store.user)
  const [present] = useIonToast()

  const writeToClipboard = async () => {
    Clipboard.write({ string: user.accountId })
      .then(() => {
        present({
          message: 'Wallet copiada al portapapeles',
          duration: 2500,
          position: 'bottom',
          color: 'success'
        })
      })
      .catch(() => {
        present({
          message: 'Error al intentar copiar la wallet',
          duration: 2500,
          position: 'bottom',
          color: 'error'
        })
      })
  }

  return (
		<>
			<IonCard className="box ion-padding">
				<div>
					<TitleForm title="Cuenta" customClass="ion-margin-start" />
					<div>
						<IonItem>
							<IonAvatar slot="start">
								<IonImg alt="Silhouette of a person's head" src="https://ionicframework.com/docs/demos/api/avatar/avatar.svg" />
							</IonAvatar>
							<IonLabel>
								<p>e4Coin</p>
								<h2>500.00</h2>
							</IonLabel>
							<IonIcon icon={qrCodeSharp} slot="end" onClick={() => { setIsOpenModalShowQr(true) }}></IonIcon>
						</IonItem>
						<IonItem lines="none">
							<IonLabel>
								<h2>Wallet</h2>
								<p className="ion-text-wrap">{user.accountId}</p>
							</IonLabel>
							<IonIcon icon={copySharp} slot="end" className="ion-margin-top" onClick={async () => { await writeToClipboard() }} />
						</IonItem>
					</div>
				</div>
			</IonCard>
			<ModalSmall isOpen={isOpenModalShowQr} onDidDismiss={() => { setIsOpenModalShowQr(false) }}>
				<IonImg src={qr} alt="qr" style={{ maxWidth: '300px', maxHeight: '300px' }}/>
			</ModalSmall>
		</>
  )
}

export default ViewAccountWallet
