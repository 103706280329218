import { IonCard, IonIcon, IonItem, IonList } from '@ionic/react'
import { addCircle } from 'ionicons/icons'
import { useState } from 'react'
import { TitleForm } from '../../../components/Atoms/TitleForm'
import ModalSmall from '../../../components/Organisms/ModalSmall/ModalSmall'
import { type SocialMedia } from '../../../models/social-media'
import { FormSocialMedia } from '../FormSocialMedia'

export interface Props {
  title: string
  list: SocialMedia[]
}

interface HandleSocialParams {
  isEdit: boolean
  itemEdit?: SocialMedia
}

const ListOfSocialMedia: React.FC<Props> = ({ title, list }) => {
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [itemEdit, setItemEdit] = useState<SocialMedia>()

  const handleSocialMedia = ({ isEdit, itemEdit }: HandleSocialParams) => {
    setIsOpenModal(true)
    setIsEdit(isEdit)
    if (isEdit) setItemEdit(itemEdit)
  }

  return (
		<>
		<IonCard className="box ion-padding">
      <div>
        <TitleForm title={title} customClass="ion-margin-start" />
				<IonList>
					{list.map(social =>
						<IonItem key={social.id} button={true} detail={true} onClick={() => { handleSocialMedia({ isEdit: true, itemEdit: social }) }}>{ social.social }</IonItem>
					)}
				</IonList>
      </div>
			<IonItem lines="none">
				<IonIcon icon={addCircle} slot="end" size="large" onClick={() => { handleSocialMedia({ isEdit: false }) }}/>
			</IonItem>
    </IonCard>
		<ModalSmall isOpen={isOpenModal} onDidDismiss={() => { setIsOpenModal(false) }}>
			<FormSocialMedia isEdit={isEdit} itemEdit={itemEdit} closeModal={() => { setIsOpenModal(false) }} />
		</ModalSmall>
		</>
  )
}

export default ListOfSocialMedia
