import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice, current } from '@reduxjs/toolkit'
import { getLocalStorage, setLocalStorage } from '../../utilities/localStorage.utility'
import type { ProductCart, ServicePos } from '../../pages/Pos/Pos'

enum Account {
  ONE = 'accountOne',
  TWO = 'accountTwo',
  THREE = 'accountThree'
}
export interface PosStateType {
  tab: string
  modeEditActive: boolean
  showCalculator: boolean
  gridServices: ServicePos[] | null[]
  accountOne: {
    gridServices: ServicePos[] | null[]
    productsCart: ProductCart[]
  }
  accountTwo: {
    gridServices: ServicePos[] | null[]
    productsCart: ProductCart[]
  }
  accountThree: {
    gridServices: ServicePos[] | null[]
    productsCart: ProductCart[]
  }
}

const initialState: PosStateType = {
  tab: 'accountOne',
  showCalculator: false,
  modeEditActive: false,
  gridServices: new Array(5).fill(null),
  accountOne: {
    gridServices: new Array(5).fill(null),
    productsCart: []
  },
  accountTwo: {
    gridServices: new Array(0).fill(null),
    productsCart: []
  },
  accountThree: {
    gridServices: new Array(0).fill(null),
    productsCart: []
  }
}

export const posSlice = createSlice({
  name: 'pos-state',
  initialState: getLocalStorage('pos-state') ? JSON.parse(getLocalStorage('pos-state') as string) : initialState,
  reducers: {
    changeCurrentTab (state: PosStateType, action: PayloadAction<string>) {
      const result = { ...state, tab: action.payload }
      setLocalStorage('pos-state', result)
      return result
    },
    toggleShowCalculator (state: PosStateType, action: PayloadAction<boolean>) {
      const result = { ...state, showCalculator: action.payload }
      setLocalStorage('pos-state', result)
      return result
    },
    changeModeEdit (state: PosStateType, action: PayloadAction<boolean>) {
      const result = { ...state, modeEditActive: action.payload }
      setLocalStorage('pos-state', result)
      return result
    },
    addServiceToGrid (state: PosStateType, action) {
      const { service, keySelected } = action.payload
      // let currentGrid: ServicePos[] | null[] = []

      // if (state.tab === Account.ONE) {
      //   currentGrid = [...current(state).accountOne.gridServices]
      // }
      // if (state.tab === Account.TWO) {
      //   currentGrid = [...current(state).accountTwo.gridServices]
      // }
      // if (state.tab === Account.THREE) {
      //   currentGrid = [...current(state).accountThree.gridServices]
      // }

      // currentGrid[keySelected] = service

      // let result = {}
      // if (state.tab === Account.ONE) {
      //   result = { ...state, accountOne: { ...state.accountOne, gridServices: currentGrid } }
      // }
      // if (state.tab === Account.TWO) {
      //   result = { ...state, accountTwo: { ...state.accountTwo, gridServices: currentGrid } }
      // }
      // if (state.tab === Account.THREE) {
      //   result = { ...state, accountThree: { ...state.accountThree, gridServices: currentGrid } }
      // }

      let currentGrid: ServicePos[] | null[] = []
      currentGrid = [...current(state).gridServices]
      currentGrid[keySelected] = service
      let result = {}
      result = { ...state, gridServices: currentGrid }
      setLocalStorage('pos-state', result)
      return result
    },
    deleteServiceToGrid (state: PosStateType, action) {
      const { keySelected } = action.payload
      // let copyArrayServices: ServicePos[] | null[] = []

      // if (state.tab === Account.ONE) {
      //   copyArrayServices = [...current(state).accountOne.gridServices]
      // }
      // if (state.tab === Account.TWO) {
      //   copyArrayServices = [...current(state).accountTwo.gridServices]
      // }
      // if (state.tab === Account.THREE) {
      //   copyArrayServices = [...current(state).accountThree.gridServices]
      // }

      // copyArrayServices.splice(keySelected as number, 1, null)

      // let result = {}
      // if (state.tab === Account.ONE) {
      //   result = { ...state, accountOne: { ...state.accountOne, gridServices: copyArrayServices } }
      // }
      // if (state.tab === Account.TWO) {
      //   result = { ...state, accountTwo: { ...state.accountTwo, gridServices: copyArrayServices } }
      // }
      // if (state.tab === Account.THREE) {
      //   result = { ...state, accountThree: { ...state.accountThree, gridServices: copyArrayServices } }
      // }

      let copyArrayServices: ServicePos[] | null[] = []

      copyArrayServices = [...current(state).gridServices]
      copyArrayServices.splice(keySelected as number, 1, null)
      let result = {}
      result = { ...state, gridServices: copyArrayServices }
      setLocalStorage('pos-state', result)
      return result
    },
    addProductToCart (state: PosStateType, action) {
      const { productToAdd } = action.payload
      let newArray: ProductCart[] = []

      if (state.tab === Account.ONE) {
        newArray = [...current(state).accountOne.productsCart, { ...productToAdd, quantity: 1 }]
      }
      if (state.tab === Account.TWO) {
        newArray = [...current(state).accountTwo.productsCart, { ...productToAdd, quantity: 1 }]
      }
      if (state.tab === Account.THREE) {
        newArray = [...current(state).accountThree.productsCart, { ...productToAdd, quantity: 1 }]
      }

      let result = {}
      if (state.tab === Account.ONE) {
        result = { ...state, accountOne: { ...state.accountOne, productsCart: newArray } }
      }
      if (state.tab === Account.TWO) {
        result = { ...state, accountTwo: { ...state.accountTwo, productsCart: newArray } }
      }
      if (state.tab === Account.THREE) {
        result = { ...state, accountThree: { ...state.accountThree, productsCart: newArray } }
      }

      setLocalStorage('pos-state', result)
      return result
    },
    deleteProductToCart (state: PosStateType, action) {
      const { productToDelete } = action.payload
      let newArray: ProductCart[] = []

      if (state.tab === Account.ONE) {
        newArray = [...current(state).accountOne.productsCart].filter(productCart => productCart.id !== productToDelete?.id)
      }
      if (state.tab === Account.TWO) {
        newArray = [...current(state).accountTwo.productsCart].filter(productCart => productCart.id !== productToDelete?.id)
      }
      if (state.tab === Account.THREE) {
        newArray = [...current(state).accountThree.productsCart].filter(productCart => productCart.id !== productToDelete?.id)
      }

      let result = {}
      if (state.tab === Account.ONE) {
        result = { ...state, accountOne: { ...state.accountOne, productsCart: newArray } }
      }
      if (state.tab === Account.TWO) {
        result = { ...state, accountTwo: { ...state.accountTwo, productsCart: newArray } }
      }
      if (state.tab === Account.THREE) {
        result = { ...state, accountThree: { ...state.accountThree, productsCart: newArray } }
      }

      setLocalStorage('pos-state', result)
      return result
    },
    incrementProductInCart (state: PosStateType, action) {
      const { productToIncrement } = action.payload
      let currentArray: ProductCart[] = []

      if (state.tab === Account.ONE) {
        currentArray = [...current(state).accountOne.productsCart]
      }
      if (state.tab === Account.TWO) {
        currentArray = [...current(state).accountTwo.productsCart]
      }
      if (state.tab === Account.THREE) {
        currentArray = [...current(state).accountThree.productsCart]
      }

      const newArray = currentArray.map(productCart => productCart.id === productToIncrement?.id
        ? { ...productToIncrement, quantity: Number(productToIncrement.quantity) + 1 }
        : productCart)

      let result = {}
      if (state.tab === Account.ONE) {
        result = { ...state, accountOne: { ...state.accountOne, productsCart: newArray } }
      }
      if (state.tab === Account.TWO) {
        result = { ...state, accountTwo: { ...state.accountTwo, productsCart: newArray } }
      }
      if (state.tab === Account.THREE) {
        result = { ...state, accountThree: { ...state.accountThree, productsCart: newArray } }
      }

      setLocalStorage('pos-state', result)
      return result
    },
    decrementProductInCart (state: PosStateType, action) {
      const { productToDecrement } = action.payload
      let currentArray: ProductCart[] = []

      if (state.tab === Account.ONE) {
        currentArray = [...current(state).accountOne.productsCart]
      }
      if (state.tab === Account.TWO) {
        currentArray = [...current(state).accountTwo.productsCart]
      }
      if (state.tab === Account.THREE) {
        currentArray = [...current(state).accountThree.productsCart]
      }

      const newArray = currentArray.map(productCart => productCart.id === productToDecrement?.id
        ? { ...productToDecrement, quantity: Number(productToDecrement?.quantity) > 1 ? Number(productToDecrement.quantity) - 1 : productToDecrement.quantity }
        : productCart)

      let result = {}
      if (state.tab === Account.ONE) {
        result = { ...state, accountOne: { ...state.accountOne, productsCart: newArray } }
      }
      if (state.tab === Account.TWO) {
        result = { ...state, accountTwo: { ...state.accountTwo, productsCart: newArray } }
      }
      if (state.tab === Account.THREE) {
        result = { ...state, accountThree: { ...state.accountThree, productsCart: newArray } }
      }

      setLocalStorage('pos-state', result)
      return result
    }
  }
})

export const {
  changeCurrentTab,
  toggleShowCalculator,
  changeModeEdit,
  addServiceToGrid,
  deleteServiceToGrid,
  addProductToCart,
  deleteProductToCart,
  incrementProductInCart,
  decrementProductInCart
} = posSlice.actions
export default posSlice.reducer
