import { IonButton, IonCol, IonGrid, IonRow, IonText, useIonToast } from '@ionic/react'
import { useState } from 'react'
import { Input } from '../../../../components/Atoms/Input/Input'
import { TitleForm } from '../../../../components/Atoms/TitleForm'
import { type FamilyMember } from '../../../../models/family-member'
import { useAppDispatch } from '../../../../store/hooks'
import { addMembersFamily, editMembersFamily } from '../../../../store/states/user'

export interface Props {
  isEdit: boolean
  itemEdit?: FamilyMember
  closeModal: () => void
}

const FormMembersFamily: React.FC<Props> = ({ isEdit, itemEdit, closeModal }) => {
  const [present] = useIonToast()
  const initialValue = isEdit
    ? {
        id: itemEdit?.id,
        type: itemEdit?.type,
        name: itemEdit?.name
      }
    : { type: '', name: '' }
  const [member, setMember] = useState<FamilyMember>(initialValue as FamilyMember)
  const dispatch = useAppDispatch()

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    closeModal()
    // run services edit here!
    if (isEdit) {
      dispatch(editMembersFamily(member))
    } else {
      dispatch(addMembersFamily({ ...member, id: Math.ceil(Math.random() * 1000) }))
    }
    present({
      message: isEdit ? 'Datos guardados' : 'Dato creado',
      duration: 2500,
      position: 'bottom',
      color: 'success'
    })
  }

  const handleChange = (e: any) => {
    setMember(prevValue => ({
      ...prevValue,
      [e.target.name]: e.target.value
    }))
  }

  return (
		<div className="box ion-padding">
			<form className="form" onSubmit={e => { handleSubmit(e) }}>
				<TitleForm title={`${isEdit ? 'Editar' : 'Añadir'} familiar`} customClass="ion-margin-start" />
				<IonGrid>
          <IonRow>
            <IonCol>
							<Input label="Parentesco " type="text" name="type" value={member.type} onChange={e => { handleChange(e) }}/>
            </IonCol>
            <IonCol>
							<Input label="Nombre" type="text" name="name" value={member.name} onChange={e => { handleChange(e) }}/>
            </IonCol>
          </IonRow>
        </IonGrid>

				<div className="ion-padding">
					<IonText></IonText>
				</div>
				<div className="ion-text-end">
					<IonButton color="secondary" shape="round" type="submit">{isEdit ? 'Guardar' : 'Crear'}</IonButton>
					<IonButton color="secondary" shape="round" fill="clear" type="button" onClick={() => { closeModal() }}>Cancelar</IonButton>
				</div>
			</form>
		</div>
  )
}

export default FormMembersFamily
