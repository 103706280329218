import { IonAvatar, IonIcon, IonImg, IonItem, IonLabel } from '@ionic/react'
import { checkmarkSharp, logInSharp } from 'ionicons/icons'
import { type Commerce } from '../../../models/commerce'
import { useAppSelector } from '../../../store/hooks'

export interface Props {
  businessItem: Commerce
  handleClick: () => void
}

const BusinessItem: React.FC<Props> = ({ businessItem, handleClick }) => {
  const commerce = useAppSelector(store => store.commerce)

  return (
		<IonItem lines="none" button={true} detail={false} key={Number(businessItem.id)} onClick={() => { handleClick() }}>
			<IonAvatar slot="start">
				<IonImg alt={businessItem.companyName} src={businessItem.avatar} />
			</IonAvatar>
			<IonLabel>
				<h2 className="ion-text-wrap">{businessItem.companyName}</h2>
				<p>{businessItem.username}</p>
			</IonLabel>
			<IonIcon slot="end" icon={commerce.id === businessItem.id ? checkmarkSharp : logInSharp} />
		</IonItem>
  )
}

export default BusinessItem
