import { IonRow, IonCol, IonItem, IonIcon, IonLabel, IonButton } from '@ionic/react'
import { wallet } from 'ionicons/icons'
import { generateColorStatus, Status, type TypeRequest } from '../ListStatus/ListStatus'

interface Props {
  request: TypeRequest
}

export function ItemStatus ({ request }: Props) {
  return (
    <article>
      <IonRow className='ion-justify-content-start ion-align-items-center ion-no-padding ion-no-margin ion-text-center' style={{ height: '80px' }} >
        <IonCol size='2' className='flex ion-align-items-center ion-no-padding ion-no-margin' >
          <IonItem button={false} detail={false} lines='none' className='ion-no-padding ion-no-margin'>
            <IonIcon icon={wallet} style={{ marginRight: '10px' }}></IonIcon>
            <IonLabel>
              <IonLabel>{request.date}</IonLabel>
              <IonLabel style={{ fontSize: '14px' }}>{request.time}</IonLabel>
            </IonLabel>
          </IonItem>
        </IonCol>
        <IonCol size='2'>
          {request.type}
        </IonCol>
        <IonCol size='2' className='flex ion-justify-content-center ion-align-items-center'>
          <div style={{ width: '10px', height: '10px', backgroundColor: generateColorStatus(request.status), borderRadius: '50%', marginRight: '10px' }}></div>
          <IonLabel>{request.status}</IonLabel>
        </IonCol>
        <IonCol size='2' className='ion-no-padding ion-no-margin'>
          <IonItem button={false} detail={false} lines='none' className='ion-no-padding ion-no-margin'>
            <IonLabel className='ion-no-padding ion-no-margin'>
              <IonLabel> {request.clientName} </IonLabel>
              <IonLabel style={{ fontSize: '14px' }}>{request.clientEmail}</IonLabel>
            </IonLabel>
          </IonItem>
        </IonCol>
        <IonCol size='2'>
          <IonCol>
            <IonItem button={false} detail={false} lines='none'>
              <IonLabel>
                <IonLabel>${request.priceE4coin} e4coin</IonLabel>
                <IonLabel style={{ fontSize: '14px', color: '#52C42B' }}>{request.priceBs} Bs</IonLabel>
              </IonLabel>
            </IonItem>
          </IonCol>
        </IonCol>
        <IonCol size='2'>
          {
            request.status === Status.EXCESS
              ? <IonButton color="secondary" shape="round" fill="outline" size='small'>
                  Resolver
                </IonButton>
              : null
          }
        </IonCol>
      </IonRow>
    </article>
  )
}
