import { IonButton, IonCard, IonDatetime, IonItem, IonList, useIonToast } from '@ionic/react'
import { useState } from 'react'
import { useHistory } from 'react-router'
import { TitleForm } from '../../../../components/Atoms/TitleForm'
import ModalSmall from '../../../../components/Organisms/ModalSmall/ModalSmall'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import { editBirthdate } from '../../../../store/states/user'

const FormEditBirthDate: React.FC = () => {
  const [present] = useIonToast()
  const history = useHistory()
  const user = useAppSelector(store => store.user)
  const dispatch = useAppDispatch()
  const [birthdate, setBirthdate] = useState<string>(user.birthdate)
  const [isOpenModal, setIsOpenModal] = useState(false)

  const handleSubmit = () => {
    // run services edit here!
    present({
      message: 'Datos guardados',
      duration: 2500,
      position: 'bottom',
      color: 'success'
    })
    dispatch(editBirthdate({ birthdate }))
    history.replace('/perfil', { replace: true })
    setIsOpenModal(false)
  }

  const handleChange = (e: any) => {
    setBirthdate(e.detail.value)
  }

  return (
		<>
			<IonCard className="box ion-padding">
				<TitleForm title="Fecha de nacimiento" customClass="ion-margin-start"/>
				<IonList lines="none">
					<IonItem button={true} detail={true} onClick={() => { setIsOpenModal(true) }}>{birthdate}</IonItem>
				</IonList>
			</IonCard>

			<ModalSmall isOpen={isOpenModal} onDidDismiss={() => { setIsOpenModal(false) }}>
				<IonDatetime
					locale="es-ES"
					value={birthdate}
					max={new Date().toISOString().slice(0, 10)}
					onIonChange={e => { handleChange(e) }}
					presentation="date"
				/>
				<div className="ion-text-end">
					<IonButton color="secondary" shape="round" type="submit" onClick={() => { handleSubmit() }}>Guardar</IonButton>
					<IonButton color="secondary" shape="round" fill="clear" type="button" onClick={() => { setIsOpenModal(false) }}>Cancelar</IonButton>
				</div>
			</ModalSmall>
		</>
  )
}

export default FormEditBirthDate
