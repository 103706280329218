import {
  IonButton,
  IonCard,
  IonCol,
  IonGrid,
  IonItem,
  IonLabel,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonText,
  useIonToast
} from '@ionic/react'
import { useState } from 'react'
import { useHistory } from 'react-router'
import { TitleForm } from '../../../components/Atoms/TitleForm'
import { countries } from '../../../data/countries'
import { type Commerce, type User } from '../../../models'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { editCountriesCommerce } from '../../../store/states/commerce'
import { editCountries } from '../../../store/states/user'

interface Props {
  userType: User | Commerce
}

const FormEditCountry: React.FC<Props> = ({ userType }) => {
  const dispatch = useAppDispatch()
  const history = useHistory()
  const roleUser = useAppSelector((store) => store.roleUserActive)
  const [present] = useIonToast()
  const [country, setCountry] = useState({
    origin: {
      value: userType.country.origin.value,
      label: userType.country.origin.label
    },
    residence: {
      value: userType.country.residence.value,
      label: userType.country.residence.label
    }
  })

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    // run services edit here!

    if (roleUser.id.toString() === '1') {
      dispatch(editCountries({ country }))
      history.replace('/perfil', { replace: true })
    } else {
      dispatch(editCountriesCommerce({ country }))
      history.replace('/perfil-comercio', { replace: true })
    }
    present({
      message: 'Datos guardados',
      duration: 2500,
      position: 'bottom',
      color: 'success'
    })
  }

  const handleChange = (e: any) => {
    setCountry((prevValue) => ({
      ...prevValue,
      [e.target.name]: {
        label: countries.find((c) => c.value === e.detail.value)?.label,
        value: e.detail.value
      }
    }))
  }

  return (
    <IonCard className="box ion-padding">
      <form onSubmit={(e) => { handleSubmit(e) }}>
        <TitleForm title="País" customClass="ion-padding-start" />
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonItem className="input-item">
                <IonLabel position="floating">País de origen</IonLabel>
                <IonSelect
                  value={country.origin.value}
                  placeholder="País de origen"
                  name="origin"
                  onIonChange={(e) => { handleChange(e) }}
                >
                  {countries.map((country) => (
                    <IonSelectOption key={country.value} value={country.value}>
                      {country.label}
                    </IonSelectOption>
                  ))}
                </IonSelect>
              </IonItem>
            </IonCol>
            <IonCol>
              <IonItem className="input-item">
                <IonLabel position="floating">País de residencia</IonLabel>
                <IonSelect
                  value={country.residence.value}
                  placeholder="País de residencia"
                  name="residence"
                  onIonChange={(e) => { handleChange(e) }}
                >
                  {countries.map((country) => (
                    <IonSelectOption key={country.value} value={country.value}>
                      {country.label}
                    </IonSelectOption>
                  ))}
                </IonSelect>
              </IonItem>
            </IonCol>
          </IonRow>
        </IonGrid>

        <div className="ion-padding">
          <IonText>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry.
          </IonText>
        </div>
        <div className="ion-text-end">
          <IonButton color="secondary" shape="round" type="submit">
            Guardar
          </IonButton>
          <IonButton
            color="secondary"
            shape="round"
            fill="clear"
            type="button"
            onClick={() => { history.goBack() }}
          >
            Cancelar
          </IonButton>
        </div>
      </form>
    </IonCard>
  )
}

export default FormEditCountry
