import { IonBadge, IonCard, IonIcon, IonItem, IonLabel, IonList } from '@ionic/react'
import { addCircle } from 'ionicons/icons'
import { useState } from 'react'
import { TitleForm } from '../../../../components/Atoms/TitleForm'
import ModalSmall from '../../../../components/Organisms/ModalSmall/ModalSmall'
import type { Employee } from '../../../../models'
import { FormInviteEmployee } from '../FormInviteEmployee'
import { FormEditEmployee } from '../FormEditEmployee'

interface HandleParams {
  itemEdit?: Employee
}

const ListOfEmployees: React.FC = () => {
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [isOpenModalInvite, setIsOpenModalInvite] = useState(false)
  const [itemEdit, setItemEdit] = useState<Employee>()

  const employeesList: Employee[] = [
    {
      id: 1,
      firstName: 'Jose',
      lastName: 'Perez',
      typeDocument: 'V',
      numberDocument: '15858779',
      jobTitle: 'Gerente',
      branchOffice: 'Sucursal Principal',
      profile: {
        id: 1,
        title: 'Super Administrador'
      }
    },
    {
      id: 2,
      firstName: 'Juan',
      lastName: 'Luis Guerra',
      typeDocument: 'V',
      numberDocument: '15858779',
      jobTitle: 'Gerente',
      branchOffice: 'Sucursal Principal',
      profile: {
        id: 2,
        title: 'Administrador'
      }
    }
  ]

  const handleEditEmployee = ({ itemEdit }: HandleParams) => {
    setIsOpenModal(true)
    setItemEdit(itemEdit)
  }

  const handleInviteEmployee = () => {
    setIsOpenModalInvite(true)
  }

  return (
		<>
		<IonCard className="box ion-padding">
      <div>
        <TitleForm title="Emplados" customClass="ion-margin-start" />
				<IonList>
					{employeesList.map(employee =>
						<IonItem key={employee.id} button={true} detail={true} onClick={() => { handleEditEmployee({ itemEdit: employee }) }}>
							<IonLabel>
								<h2>{ employee.firstName } { employee.lastName }</h2>
								<p>{ employee.jobTitle }</p>
							</IonLabel>
							{employee.profile.id === 1 &&
							<IonLabel>
								<IonBadge slot="end" color="secondary">{ employee.profile.title }</IonBadge>
							</IonLabel>}
						</IonItem>
					)}
				</IonList>
      </div>
			<IonItem lines="none">
				<IonIcon icon={addCircle} slot="end" size="large" onClick={() => { handleInviteEmployee() }} />
			</IonItem>
    </IonCard>
		<ModalSmall isOpen={isOpenModal} onDidDismiss={() => { setIsOpenModal(false) }}>
			<FormEditEmployee itemEdit={itemEdit} closeModal={() => { setIsOpenModal(false) }} />
		</ModalSmall>
		<ModalSmall isOpen={isOpenModalInvite} onDidDismiss={() => { setIsOpenModalInvite(false) }}>
			<FormInviteEmployee closeModal={() => { setIsOpenModalInvite(false) }} />
		</ModalSmall>
		</>
  )
}

export default ListOfEmployees
