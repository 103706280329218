import { type FunctionComponent } from 'react'
import styled from 'styled-components'
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks'
import { addDiscount } from '../../../../../../store/states/discount'

interface DisplayProps {
  hasMemory: boolean
  expression: string
  value: string
}

const StyledIndicatorList = styled.div`
  font-size: 0.75em;
  line-height: 1;
  opacity: 0.4;
  text-align: right;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.25em;
  min-height: 1em;
`

const StyledExpression = styled.span`
  margin-left: auto;
`

const StyleScreen = styled.div`
  font-size: 2.1em;
  min-height: 1.4em;
  display: flex;
  align-items: center;
  justify-content: flex-start;  
  overflow: hidden;
`

const StyledScreenMain = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;  
`

const StyledDisplay = styled.div`
  background-color: #393939;
  color: #fff;
  padding: 1.5em 1em;
`

const StyleButtonPlus = styled.div`
	width: 30px;
	height: 30px;
  background-color: #00ccff;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
  color: #fff;
	cursor: pointer;
`

export const Screen: FunctionComponent<DisplayProps> = ({ value, hasMemory, expression }) => {
  const dispatch = useAppDispatch()
  const { discounts } = useAppSelector(store => store.discounts)

  const handleAddDiscount = () => {
    if (Number(value) < 1) return
    dispatch(addDiscount({
      id: discounts.length + 1,
      discount: Number(`${value}`)
    }))
  }

  return (
    <StyledDisplay>
      <StyledIndicatorList>
        {hasMemory &&
          <span>M</span>
        }

        <StyledExpression>
          {expression}
        </StyledExpression>
      </StyledIndicatorList>

      <StyledScreenMain>
				<StyleScreen>
					RD$ {value}
				</StyleScreen>
				<StyleButtonPlus onClick={() => { handleAddDiscount() }}>
					<span>+</span>
				</StyleButtonPlus>
			</StyledScreenMain>
    </StyledDisplay>
  )
}

export default Screen
