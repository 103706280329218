import { IonCard, IonItem, IonLabel, IonList } from '@ionic/react'
import { useState } from 'react'
import { TitleForm } from '../../../../components/Atoms/TitleForm'

interface Activity {
  id: number
  device: string
  place: string
  lastConnection: string
}

const ListOfActivities: React.FC = () => {
  // const [present] = useIonToast();
  const [activities] = useState<Activity[]>([
    {
      id: 1,
      device: 'Dispositivo Android Xiaomi 9C',
      place: 'Boyacá (CO) - 168.90.15.140',
      lastConnection: '8/29/22, 8:12:38 AM GTM-5'
    },
    {
      id: 2,
      device: 'Dispositivo iOS iPhone 12',
      place: 'Distrito Nacional (DO) - 148.0.71.235',
      lastConnection: '8/29/22, 8:12:38 AM GTM-5'
    }
  ])

  // const handleDisconnect = () => {
  // 	setActivities(prevActivities => {
  // 		return Activities.filter(ele => ele.id !== account.id)
  // 	})
  // 	present({
  //     message: 'Desconexión exitosa',
  //     duration: 2500,
  //     position: "bottom",
  // 		color: "success"
  //   })
  // }

  return (
		<>
		<IonCard className="box ion-padding">
      <div>
        <TitleForm title="Actividad" customClass="ion-margin-start" />
				<IonList>
					{activities.map(activity =>
						<IonItem key={activity.id}>
							<IonLabel>
								<h2>{activity.device}</h2>
								<p>{activity.place}</p>
								<p>{activity.lastConnection}</p>
							</IonLabel>
							{/* <IonButton color="danger" fill="clear"><IonIcon slot="end" icon={logOutSharp}></IonIcon></IonButton> */}
						</IonItem>
					)}
				</IonList>
      </div>
    </IonCard>
		</>
  )
}

export default ListOfActivities
