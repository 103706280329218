import { IonCard, IonIcon, IonImg, IonItem, IonLabel, IonList } from '@ionic/react'
import { addCircle } from 'ionicons/icons'
import { useState } from 'react'
import iconCardBank from '../../../../assets/icon-card-bank.png'
import { TitleForm } from '../../../../components/Atoms/TitleForm'
import ModalSmall from '../../../../components/Organisms/ModalSmall/ModalSmall'
import type { CardBank } from '../../../../models/user-card-bank'
import { useAppSelector } from '../../../../store/hooks'
import { FormCardsBank } from '../FormCardsBank'

interface HandleCardsBankParams {
  isEdit: boolean
  itemEdit?: CardBank
}

const ListOfCarsBank: React.FC = () => {
  const userCardsBank = useAppSelector(store => store.userCardsBank)
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [itemEdit, setItemEdit] = useState<CardBank>()

  const handleCardsBank = ({ isEdit, itemEdit }: HandleCardsBankParams) => {
    setIsOpenModal(true)
    setIsEdit(isEdit)
    if (isEdit) setItemEdit(itemEdit)
  }

  return (
		<>
			<IonCard className="box ion-padding">
				<div>
					<TitleForm title="Tarjetas" customClass="ion-margin-start" />
					<IonList>
						{userCardsBank.cardsBank.map(card =>
							<IonItem button={true} detail={true} key={card.id} onClick={() => { handleCardsBank({ isEdit: true, itemEdit: card }) }}>
								<IonImg slot="start" alt={card.cardType} src={iconCardBank} style={{ width: '60px', height: '30px' }} />
								<IonLabel>
									<h2>{ card.cardType }</h2>
									<p>{ card.cardNumber }</p>
								</IonLabel>
							</IonItem>
						)}
					</IonList>
				</div>
				<IonItem lines="none">
					<IonIcon icon={addCircle} slot="end" size="large" onClick={() => { handleCardsBank({ isEdit: false }) }}/>
				</IonItem>
			</IonCard>
			<ModalSmall isOpen={isOpenModal} onDidDismiss={() => { setIsOpenModal(false) }}>
				<FormCardsBank isEdit={isEdit} itemEdit={itemEdit} closeModal={() => { setIsOpenModal(false) }} />
			</ModalSmall>
		</>
  )
}

export default ListOfCarsBank
