import { IonCard, IonItem, IonLabel, IonToggle, IonButton, IonList, IonReorder, IonReorderGroup, type ItemReorderEventDetail } from '@ionic/react'
import { TitleForm } from '../../../../components/Atoms/TitleForm'

export function Payment () {
  function handleReorder (event: CustomEvent<ItemReorderEventDetail>) {
    // The `from` and `to` properties contain the index of the item
    // when the drag started and ended, respectively
    console.log('Dragged from index', event.detail.from, 'to', event.detail.to)

    // Finish the reorder and position the item in the DOM based on
    // where the gesture ended. This method can also be called directly
    // by the reorder group
    event.detail.complete()
  }

  return (
		<IonCard className="box ion-padding">
			<TitleForm title='General' customClass='ion-padding-start'/>
			<IonItem lines='none'>
				<IonLabel>
					<h2>Omitir revisión de ventas</h2>
					<p className='ion-text-wrap'>Las transacciones con un monto personalizado único omitirán la pantalla de &quot;revisar venta&quot;</p>
				</IonLabel>
				<IonToggle color="secondary" slot="end" onIonChange={e => { console.log(e) }}></IonToggle>
			</IonItem>
			<div style={{ width: '100%', height: '4px', background: '#727070b1' }}></div>
			<div style={{ display: 'flex', alignItems: 'center', gap: '2rem', justifyContent: 'space-between', marginTop: '1rem' }}>
				<TitleForm title='Todos los tipos de pagos' customClass='ion-padding-start'/>
				<IonButton color="secondary" shape="round" size='small'>Vista previa</IonButton>
			</div>
			<IonItem lines='none' className='ion-text-wrap'>
				<IonLabel className='ion-text-wrap'>
					Activa los tipos de pago que aceptas y ordénalos de la menera que quieras que se muestren
				</IonLabel>
			</IonItem>
			<TitleForm title='Tipo de pago principal' customClass='ion-padding-start'/>
			<IonList>
      {/* The reorder gesture is disabled by default, enable it to drag and drop items */}
				<IonReorderGroup disabled={false} onIonItemReorder={handleReorder}>
					<IonItem>
						<IonLabel>Item 1</IonLabel>
						<IonReorder slot="start"></IonReorder>
					</IonItem>

					<IonItem>
						<IonLabel>Item 2</IonLabel>
						<IonReorder slot="start"></IonReorder>
					</IonItem>

					<TitleForm title='Más tipos de pago' customClass='ion-padding-start'/>

					<IonItem>
						<IonLabel>Efectivo</IonLabel>
						<IonReorder slot="start"></IonReorder>
					</IonItem>
				</IonReorderGroup>
    	</IonList>
		</IonCard>
  )
}
