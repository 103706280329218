import { IonCard, IonIcon, IonItem, IonLabel, IonList, IonText, IonToggle } from '@ionic/react'
import { TitleForm } from '../../components/Atoms/TitleForm'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { changeShowMenuItem, type MenuItem } from '../../store/states/menu'

const MenuDinamicItem = ({ item, onChange }: { item: MenuItem, onChange: (e: any, item: MenuItem) => void }) => {
  return (
		<IonItem disabled={item?.fixed}>
			<IonIcon slot="start" icon={item.mdIcon} />
			<IonLabel>{ item.title }</IonLabel>
			<IonToggle name="dop" color="secondary" checked={item.show} slot="end" onIonChange={e => { onChange(e, item) }} />
		</IonItem>
  )
}

const MenuDinamic: React.FC = () => {
  const mainMenu = useAppSelector(store => store.mainMenu)
  const dispatch = useAppDispatch()

  console.log({ mainMenu })

  const onChange = (e: any, item: MenuItem) => {
    const updateItem: MenuItem = { ...item, show: e.detail.checked }
    dispatch(changeShowMenuItem(updateItem))
  }

  const mainMenuActive = mainMenu.filter(item => item.show)
  const mainMenuInactive = mainMenu.filter(item => !item.show)

  return (
		<>
			<IonText><h5 className="ion-padding">Main menu</h5></IonText>
			<IonCard>
				<IonList>
					{
						mainMenuActive.map(item =>
							<MenuDinamicItem item={item} onChange={onChange} key={item.id} />
						)
					}
				</IonList>
				<TitleForm title="Más" customClass="ion-margin-start" />
				<IonList>
					{
						mainMenuInactive.map(item =>
							<MenuDinamicItem item={item} onChange={onChange} key={item.id} />
						)
					}
				</IonList>
			</IonCard>
		</>
  )
}

export default MenuDinamic
