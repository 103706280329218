import { IonCard, IonHeader, IonIcon, IonCol, IonList, IonItem, IonLabel } from '@ionic/react'
import { calendar, mail, print } from 'ionicons/icons'
import { TitleForm } from '../../../../components/Atoms/TitleForm'

export function DrawerHistory () {
  return (
    <IonCol size="12" size-md="8" size-lg="8" size-xl="9">
      <IonCard>
        <IonHeader className="ion-padding">
          <div className="flex ion-justify-content-between ion-align-items-center">
            <div>
              <IonIcon icon={calendar} style={{ fontSize: '22px' }} slot="start"></IonIcon>
            </div>
            <TitleForm title="Junio 20, 2023" customClass="ion-no-padding ion-no-margin" />
            <div>
              <IonIcon icon={print} slot="start" style={{ fontSize: '22px' }} title='Imprimir' />
              <IonIcon icon={mail} slot="start" style={{ fontSize: '22px' }} className='ion-padding-start' />
            </div>
          </div>
        </IonHeader>
        <IonList>
          <IonItem button={true} detail={false} lines='full'>
            <IonLabel slot='start'>
              <h2>Fecha</h2>
              <p>10-10-23</p>
            </IonLabel>
            <IonLabel slot='start'>
              <h2>Estatus</h2>
              <p>Apertura</p>
            </IonLabel>
            <IonLabel slot='end'>
              <h2>Monto finalizado</h2>
              <p>10.00bs</p>
            </IonLabel>
          </IonItem>

          <IonItem button={true} detail={false} lines='full'>
            <IonLabel slot='start'>
              <h2>Fecha</h2>
              <p>11-10-23</p>
            </IonLabel>
            <IonLabel slot='start'>
              <h2>Estatus</h2>
              <p>Cierre</p>
            </IonLabel>
            <IonLabel slot='end'>
              <h2>Monto finalizado</h2>
              <p>110.00bs</p>
            </IonLabel>
          </IonItem>
        </IonList>
      </IonCard>
    </IonCol>
  )
}
