import { IonButton, IonText, useIonToast } from '@ionic/react'
import { useState } from 'react'
import { Input } from '../../../components/Atoms/Input/Input'
import { TitleForm } from '../../../components/Atoms/TitleForm'
import { type Phone } from '../../../models/phone'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { addPhoneCommerce, editPhoneCommerce } from '../../../store/states/commerce'
import { addPhone, editPhone } from '../../../store/states/user'

export interface Props {
  isEdit: boolean
  itemEdit?: Phone
  closeModal: () => void
}

const FormPhones: React.FC<Props> = ({ isEdit, itemEdit, closeModal }) => {
  const dispatch = useAppDispatch()
  const roleUser = useAppSelector(store => store.roleUserActive)
  const [present] = useIonToast()
  const initialValue = isEdit
    ? { ...itemEdit, phone: itemEdit?.phone }
    : { phone: '' }
  const [phone, setPhone] = useState<Phone>(initialValue as Phone)

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    closeModal()

    // run services edit here!

    if (roleUser.id.toString() === '1') {
      if (isEdit) {
        dispatch(editPhone(phone))
      } else {
        dispatch(addPhone({ ...phone, id: Math.ceil(Math.random() * 1000) }))
      }
    } else {
      if (isEdit) {
        dispatch(editPhoneCommerce(phone))
      } else {
        dispatch(addPhoneCommerce({ ...phone, id: Math.ceil(Math.random() * 1000) }))
      }
    }
    present({
      message: isEdit ? 'Datos guardados' : 'Dato creado',
      duration: 2500,
      position: 'bottom',
      color: 'success'
    })
  }

  const handleChange = (e: any) => {
    setPhone(prevValue => ({
      ...prevValue,
      [e.target.name]: e.target.value
    }))
  }

  return (
		<div className="box ion-padding">
			<form className="form" onSubmit={e => { handleSubmit(e) }}>
				<TitleForm title={`${isEdit ? 'Editar' : 'Agregar'} teléfono`} />
				<Input label="Teléfono" type="number" name="phone" value={phone.phone} onChange={e => { handleChange(e) }}/>
				<div className="ion-padding">
					<IonText>¡No olvides colocar el código de área! Ej: (585) 0251637.</IonText>
				</div>
				<div className="ion-text-end">
					<IonButton color="secondary" shape="round" type="submit">{isEdit ? 'Guardar' : 'Crear'}</IonButton>
					<IonButton color="secondary" shape="round" fill="clear" type="button" onClick={() => { closeModal() }}>Cancelar</IonButton>
				</div>
			</form>
		</div>
  )
}

export default FormPhones
