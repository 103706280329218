import { IonButton, IonCard, IonText, useIonToast } from '@ionic/react'
import { useState } from 'react'
import { useHistory } from 'react-router'
import { Input } from '../../../../components/Atoms/Input/Input'
import { TitleForm } from '../../../../components/Atoms/TitleForm'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import { editCompanyName } from '../../../../store/states/commerce'

const FormEditNameCompany: React.FC = () => {
  const [present] = useIonToast()
  const history = useHistory()
  const commerce = useAppSelector(store => store.commerce)
  const dispatch = useAppDispatch()
  const [nameCompany, setNameCompany] = useState<string>(commerce.companyName)

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    // run services edit here!
    present({
      message: 'Datos guardados',
      duration: 2500,
      position: 'bottom',
      color: 'success'
    })
    dispatch(editCompanyName({ companyName: nameCompany }))
    history.replace('/perfil-comercio', { replace: true })
  }

  const handleChange = (e: any) => {
    setNameCompany(e.target.value)
  }

  return (
		<IonCard className="box ion-padding">
			<form onSubmit={e => { handleSubmit(e) }}>
				<TitleForm title="Nombre compañia" />
        <Input label="Nombre de la compañia" type="text" name="nameCompany" value={nameCompany} onChange={e => { handleChange(e) }}/>
				<div className="ion-padding">
					<IonText>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</IonText>
				</div>
				<div className="ion-text-end">
					<IonButton color="secondary" shape="round" type="submit">Guardar</IonButton>
					<IonButton color="secondary" shape="round" fill="clear" type="button" onClick={() => { history.replace('/perfil-comercio', { replace: true }) }}>Cancelar</IonButton>
				</div>
			</form>
		</IonCard>
  )
}

export default FormEditNameCompany
