import { IonButton, useIonToast } from '@ionic/react'
import { Input } from '../../../../components/Atoms/Input/Input'
import { TitleForm } from '../../../../components/Atoms/TitleForm'

interface Props {
  setIsOpenModalNewTicket: React.Dispatch<React.SetStateAction<boolean>>
}
export function ModalCreateNewTicket ({ setIsOpenModalNewTicket }: Props) {
  const [present] = useIonToast()
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    present({
      message: 'Pedido creado',
      duration: 2500,
      position: 'bottom',
      color: 'success'
    })
    setIsOpenModalNewTicket(false)
  }

  return (
		<form onSubmit={(e) => { handleSubmit(e) }}>
			<div className="ion-padding box">
				<TitleForm title='Nuevo pedido' customClass='ion-margin-bottom'/>
				<div style={{ flex: 1, display: 'flex', gap: '1rem', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
					<div style={{ flex: 1 }}>
						<Input name='title' label="Titulo" type="text" />
					</div>
				</div>
				<div style={{ flex: 1, display: 'flex', gap: '1rem', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
					<div style={{ flex: 1 }}>
						<Input name="description" label="Descripción" type="text" />
					</div>
				</div>
				<div className="ion-text-end">
					<IonButton color="secondary" shape="round" type="submit">Guardar</IonButton>
					<IonButton color="secondary" shape="round" fill="clear" type="button" onClick={() => { setIsOpenModalNewTicket(false) }}>Cancelar</IonButton>
				</div>
			</div>
			</form>
  )
}
