import { IonButton, IonItem, IonLabel, IonSelect, IonSelectOption, IonText, useIonToast } from '@ionic/react'
import { useState } from 'react'
import { Input } from '../../../../components/Atoms/Input/Input'
import { TitleForm } from '../../../../components/Atoms/TitleForm'
import type { Dispositive } from '../../../../models/dispositive'

export interface Props {
  isEdit: boolean
  itemEdit?: Dispositive
  closeModal: () => void
}

const FormDispositive: React.FC<Props> = ({ isEdit, itemEdit, closeModal }) => {
  const [present] = useIonToast()
  const initialValue = isEdit
    ? {
        ...itemEdit,
        name: itemEdit?.name,
        agent: itemEdit?.agent
      }
	 : { name: '', agent: {} }
  const [dispositive, setDispositive] = useState<Dispositive>(initialValue as Dispositive)

  const agentsList = [ // change for agents of state
    {
      id: 'ASad-sf45s-dffds1',
      name: 'Agente 1'
    },
    {
      id: 'ASad-sf45s-dffds2',
      name: 'Agente 2'
    }
  ]

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    closeModal()
    // run services here!
    present({
      message: isEdit ? 'Datos guardados' : 'Dato creado',
      duration: 2500,
      position: 'bottom',
      color: 'success'
    })
  }

  const handleChange = (e: any) => {
    if (e.target.name === 'agent') {
      setDispositive(prevValue => ({
        ...prevValue,
        [e.target.name]: {
          id: e.detail.value,
          agent: agentsList.find(agent => agent.id === e.detail.value)?.name
        }
      }))
    } else {
      setDispositive(prevValue => ({
        ...prevValue,
        [e.target.name]: e.target.value
      }))
    }
  }

  return (
		<div className="box ion-padding">
			<form className="form" onSubmit={e => { handleSubmit(e) }}>
				<TitleForm title={`${isEdit ? 'Editar' : 'Agregar'} dispositivo`} />
				{isEdit && <Input disabled label="ID" type="text" name="id" value={dispositive.id} />}
				<Input label="Nombre" type="text" name="name" value={dispositive.name} onChange={e => { handleChange(e) }}/>
				<IonItem className="input-item">
					<IonLabel position="floating">Perfil</IonLabel>
					<IonSelect value={dispositive.agent.id} name="agent" onIonChange={e => { handleChange(e) }}>
						{agentsList.map(agent =>
							<IonSelectOption key={agent.id} value={agent.id}>{agent.name}</IonSelectOption>
						)}
					</IonSelect>
				</IonItem>
				{!isEdit &&
				<div className="ion-padding-bottom">
					<IonText>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</IonText>
				</div>}
				<div className="ion-text-end">
					<IonButton color="secondary" shape="round" type="submit">{isEdit ? 'Guardar' : 'Agregar'}</IonButton>
					<IonButton color="secondary" shape="round" fill="clear" type="button" onClick={() => { closeModal() }}>Cancelar</IonButton>
				</div>
			</form>
		</div>
  )
}

export default FormDispositive
