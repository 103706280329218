import { IonButton, IonCard, IonList, IonText } from '@ionic/react'
import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Input } from '../../../../components/Atoms/Input/Input'

export const FormForgotPasswordCode = () => {
  const [userValue, setUserValue] = useState<string | null>('')
  const history = useHistory()

  const handleLogin = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    history.push('/recover-password')
  }

  const handleChange = (e: any) => {
    setUserValue(e.target.value as string)
  }

  return (
    <form className="form" onSubmit={e => { handleLogin(e) }}>
     <IonCard className="login-card ion-padding">
        <IonList className="login-list">
          <p className="ion-text-start">Se ha enviado un código de seguridad a su correo.</p>
          <Input
            label="Código"
            type="text"
            name="text"
            value={userValue}
            onChange={e => { handleChange(e) }}
          />
          <IonText color="secondary"><p className="ion-text-start">Enviar de nuevo <IonText color="dark"><span className="ion-margin-start">50 segundos</span></IonText></p></IonText>

          <IonButton color="secondary" shape="round" expand="block" type="submit">Confirmar código</IonButton>
          <p className="ion-text-center">¿Recordaste tu contraseña? <Link to="/login">Ingresa aquí</Link></p>
        </IonList>
      </IonCard>
    </form>
  )
}
