import { IonPage } from '@ionic/react'
import { PageHeader } from '../../Molecules/PageHeader/PageHeader'

interface WrapperAppProps {
  pageName: string
  hiddenAvatar?: boolean
  children: React.ReactNode
  renderButtons?: () => React.ReactNode
  renderFooter?: () => React.ReactNode
}

export const WrapperApp: React.FC<WrapperAppProps> = ({ pageName, children, renderButtons, renderFooter, hiddenAvatar }) => {
  return (
    <IonPage>
      <PageHeader pageName={pageName} hiddenAvatar={hiddenAvatar} renderButtons={renderButtons} renderFooter={renderFooter}>
        { children }
      </PageHeader>
    </IonPage>
  )
}
