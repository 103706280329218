import { IonButton, IonCard, IonList, IonText, IonToast } from '@ionic/react'
import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Input } from '../../../../components/Atoms/Input/Input'

interface passwordType {
  validate1: string | null
  validate2: string | null
}

export const FormRecoverPassword = () => {
  const [showToast, setShowToast] = useState(false)
  const [password, setPassword] = useState<passwordType>({
    validate1: null,
    validate2: null
  })

  const history = useHistory()

  const handleLogin = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (password.validate1 !== password.validate2) return
    history.push('/login')
    setShowToast(true)
  }

  const handleChange = (e: any) => {
    setPassword(values => ({
      ...values,
      [e.target.name]: e.target.value
    }))
  }

  return (
    <form className="form" onSubmit={e => { handleLogin(e) }}>
     <IonCard className="login-card ion-padding">
        <IonList className="login-list">
          <IonText color="dark"><h2 className="login-card-title">Recuperación de contraseña</h2></IonText>
            <Input
              label="Contraseña nueva"
              type="password"
              name="validate1"
              value={password.validate1}
              onChange={e => { handleChange(e) }}
            />
            <Input
              label="Repetir contraseña"
              type="password"
              name="validate2"
              value={password.validate2}
              onChange={e => { handleChange(e) }}
            />
          <IonButton color="secondary" shape="round" expand="block" type="submit">Aceptar</IonButton>
          <p className="ion-text-center">¿Recordaste tu contraseña? <Link to="/login">Ingresa aquí</Link></p>
        </IonList>
      </IonCard>
      <IonToast
        color="success"
        isOpen={showToast}
        onDidDismiss={() => { setShowToast(false) }}
        message="Contraseña cambiada"
        duration={3500}
      />
    </form>
  )
}
