import { useState } from 'react'
import { IonButton, IonIcon, IonItem, IonLabel, IonList, IonGrid, IonRow, IonCol, useIonToast } from '@ionic/react'
import { book, card, fastFood, laptop, medkit, pricetags, shirt, person } from 'ionicons/icons'
import service1 from '../../../../../../assets/dashboard/service-dashboard-1.png'
import service2 from '../../../../../../assets/dashboard/service-dashboard-2.png'
import service3 from '../../../../../../assets/dashboard/service-dashboard-3.png'
import service4 from '../../../../../../assets/dashboard/service-dashboard-4.png'
import service5 from '../../../../../../assets/dashboard/service-dashboard-5.png'
import ModalSmall from '../../../../../../components/Organisms/ModalSmall/ModalSmall'
import TitleForm from '../../../../../../components/Atoms/TitleForm/TitleForm'
import { type ServicesDashboard } from '../../../../../../models/service-dashboard'
import { Input } from '../../../../../../components/Atoms/Input/Input'
import { createUserAvatarDefault } from '../../../../../../utilities/utilities'

interface Props {
  openModalLisOfServices: boolean
  setOpenModalLisOfServices: (value: React.SetStateAction<boolean>) => void
  arrayOfServicesUser: React.MutableRefObject<ServicesDashboard[] | null[]>
  keySelected: number
}

export const LIST_DASHBOARD_SERVICES: ServicesDashboard[] = [
  {
    id: 1,
    title: 'Descuestos',
    image: service2,
    icon: pricetags
  },
  {
    id: 2,
    title: 'Gift Card',
    subtitle: '-20% descuento',
    icon: card,
    image: service2
  },
  {
    id: 3,
    title: 'Libros',
    image: service3,
    icon: book
  },
  {
    id: 4,
    title: 'Comida',
    image: service4,
    icon: fastFood
  },
  {
    id: 5,
    title: 'Ropa',
    image: service5,
    icon: shirt
  },
  {
    id: 6,
    title: 'Tecnología',
    image: service1,
    icon: laptop
  },
  {
    id: 7,
    title: 'Cuidado personal',
    image: service5,
    icon: medkit
  }
]

export function ModalListOfServicesToAdd ({ openModalLisOfServices, setOpenModalLisOfServices, arrayOfServicesUser, keySelected }: Props) {
  const [openModalCreateItem, setOpenModalCreateItem] = useState(false)
  const [openModalCreateDiscount, setOpenModalCreateDiscount] = useState(false)
  const [nameService, setNameService] = useState('')
  const [nameDiscount, setNameDiscount] = useState('')
  const [present] = useIonToast()

  const handleAddService = (service: ServicesDashboard | null) => {
    arrayOfServicesUser.current[keySelected] = service
    setOpenModalLisOfServices(false)
  }

  const handleOpenModalCreateItem = () => {
    setOpenModalLisOfServices(false)
    setOpenModalCreateItem(true)
  }

  const handleOpenModalCreateDiscount = () => {
    setOpenModalLisOfServices(false)
    setOpenModalCreateDiscount(true)
  }

  const handleSubmitCreateNewItem = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!nameService) return
    setOpenModalCreateItem(false)
    setOpenModalLisOfServices(true)
    LIST_DASHBOARD_SERVICES.push({
      id: LIST_DASHBOARD_SERVICES.length + 1,
      title: nameService,
      image: createUserAvatarDefault(nameService),
      icon: person
    })
    setNameService('')
    present({
      message: 'Servicio agregado',
      duration: 2500,
      position: 'bottom',
      color: 'success'
    })
  }

  const handleSubmitCreateNewDiscount = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!nameDiscount) return
    setOpenModalCreateDiscount(false)
    setOpenModalLisOfServices(true)
    LIST_DASHBOARD_SERVICES.push({
      id: LIST_DASHBOARD_SERVICES.length + 1,
      title: nameDiscount,
      image: createUserAvatarDefault(nameDiscount),
      icon: pricetags
    })
    setNameDiscount('')
    present({
      message: 'Descuento agregado',
      duration: 2500,
      position: 'bottom',
      color: 'success'
    })
  }

  const handleChangeNewItem = (e: any) => {
    setNameService(e?.target?.value)
  }

  const handleChangeNewDiscount = (e: any) => {
    setNameDiscount(e?.target?.value)
  }

  return (
    <>
      <ModalSmall isOpen={openModalLisOfServices} onDidDismiss={() => { setOpenModalLisOfServices(false) }}>
        <div style={{ width: '399px' } }>
          <TitleForm title="Add to item  grid" customClass="ion-text-center" />
          <div className="ion-text-center ion-padding-top ion-padding-bottom" style={{ display: 'flex', justifyContent: 'center', gap: '1rem' }}>
            <IonButton color="secondary" shape="round" onClick={handleOpenModalCreateItem}>Crear item</IonButton>
            <IonButton color="secondary" shape="round" onClick={handleOpenModalCreateDiscount} fill="outline">crear descuento</IonButton>
          </div>
          <IonList style={{ maxHeight: '300px', overflowY: 'auto' }}>
            {LIST_DASHBOARD_SERVICES.map(service => {
              const isAddedInGrid = arrayOfServicesUser?.current.some(ele => ele?.id === service.id)
              return (
                <IonItem button key={service.id} onClick={() => { handleAddService(service) }} disabled={isAddedInGrid}>
                  {service.icon && <IonIcon slot="start" icon={service.icon} />}
                  <IonLabel>{ service.title }</IonLabel>
                </IonItem>
              )
            })}
          </IonList>
        </div>
      </ModalSmall>

      <ModalSmall isOpen={openModalCreateItem} onDidDismiss={() => { setOpenModalCreateItem(false) }}>
        <form onSubmit={handleSubmitCreateNewItem}>
          <TitleForm title="Create Item" customClass="ion-margin-start" />
          <IonGrid>
            <IonRow>
              <IonCol>
                <Input label="Nombre" type="text" name="serviceName" value={nameService} onChange={handleChangeNewItem} />
              </IonCol>
            </IonRow>
          </IonGrid>
          <div className="ion-text-end">
            <IonButton color="secondary" shape="round" type="submit">Guardar</IonButton>
            <IonButton color="secondary" shape="round" fill="clear" type="button" onClick={() => { setOpenModalCreateItem(false) }}>Cancelar</IonButton>
          </div>
        </form>
      </ModalSmall>

      <ModalSmall isOpen={openModalCreateDiscount} onDidDismiss={() => { setOpenModalCreateDiscount(false) }}>
        <form onSubmit={handleSubmitCreateNewDiscount}>
          <TitleForm title="Create descuento" customClass="ion-margin-start" />
          <IonGrid>
            <IonRow>
              <IonCol>
                <Input label="Nombre" type="text" name="serviceName" value={nameDiscount} onChange={handleChangeNewDiscount} />
              </IonCol>
            </IonRow>
          </IonGrid>
          <div className="ion-text-end">
            <IonButton color="secondary" shape="round" type="submit">Guardar</IonButton>
            <IonButton color="secondary" shape="round" fill="clear" type="button" onClick={() => { setOpenModalCreateDiscount(false) }}>Cancelar</IonButton>
          </div>
        </form>
      </ModalSmall>
    </>
  )
}
