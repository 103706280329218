import { IonItem, IonLabel, IonCard, IonToggle, IonIcon } from '@ionic/react'
import { Input } from '../../../../components/Atoms/Input/Input'
import { useState } from 'react'
import { trashOutline } from 'ionicons/icons'

export function QuickAmounts () {
  const [checked, setChecked] = useState(false)

  return (
		<IonCard className="box ion-padding">
			<IonItem lines='none'>
				<IonLabel>Acelera tus ventas agregando los importes cobrados con frecuencia al teclado del proceso de pago </IonLabel>
			</IonItem>
			<IonItem button={false} detail={false} lines='full'>
        <IonLabel style={{ textWrap: 'nowrap' }}>
          <h2>Montos mensuales</h2>
          <p>Estable tus propios montos rápidos personalizados. Puedes agregar hasta 3 montos</p>
        </IonLabel>
				<IonToggle color="secondary" slot="end" checked={checked} onIonChange={e => { setChecked(e.detail.checked) }}/>
      </IonItem>
			{ checked &&
				<div style={{ display: 'flex', alignItems: 'center', marginTop: '2rem', flexDirection: 'column', gap: '1rem' }}>
					<div style={{ flex: 1, display: 'flex', gap: '1rem', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
						<div style={{ flex: 1 }}>
							<Input label="Importe" type="number" name="phone" noSpace />
						</div>
						<IonIcon slot="end" icon={trashOutline} size='large'/>
					</div>
					<div style={{ flex: 1, display: 'flex', gap: '1rem', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
						<div style={{ flex: 1 }}>
							<Input label="Importe" type="number" name="phone" noSpace />
						</div>
						<IonIcon slot="end" icon={trashOutline} size='large'/>
					</div>
					<div style={{ flex: 1, display: 'flex', gap: '1rem', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
						<div style={{ flex: 1 }}>
							<Input label="Importe" type="number" name="phone" noSpace />
						</div>
						<IonIcon slot="end" icon={trashOutline} size='large'/>
					</div>
				</div>}
		</IonCard>
  )
}
