import { createSlice, current, type PayloadAction } from '@reduxjs/toolkit'
import { type UserPreference } from '../../models/user-preference'
import { getLocalStorage, setLocalStorage } from '../../utilities/localStorage.utility'

const stateInitalOfNotification = {
  notifications: {
    showAllNotifications: true,
    inApp: {
      sound: true,
      vibration: true
    },
    push: {
      login: true,
      sendingOfMoney: true,
      receiveOfMoney: true
    },
    email: {
      login: true,
      sendingOfMoney: true,
      receiveOfMoney: true
    },
    sms: {
      login: true,
      sendingOfMoney: true,
      receiveOfMoney: true
    }
  }
}

const stateNotificationDisabled = {
  notifications: {
    showAllNotifications: false,
    inApp: {
      sound: false,
      vibration: false
    },
    push: {
      login: false,
      sendingOfMoney: false,
      receiveOfMoney: false
    },
    email: {
      login: false,
      sendingOfMoney: false,
      receiveOfMoney: false
    },
    sms: {
      login: false,
      sendingOfMoney: false,
      receiveOfMoney: false
    }
  }
}

const initialState: UserPreference = {
  notifications: {
    showAllNotifications: true,
    inApp: {
      sound: true,
      vibration: true
    },
    push: {
      login: true,
      sendingOfMoney: true,
      receiveOfMoney: true
    },
    email: {
      login: true,
      sendingOfMoney: true,
      receiveOfMoney: true
    },
    sms: {
      login: true,
      sendingOfMoney: true,
      receiveOfMoney: true
    }
  },
  preferredChannelOfUse: {
    instaCash: true,
    qr: true,
    nfc: true,
    todos: true
  },
  givenCurrency: {
    dop: true,
    bs: true,
    e4coin: true
  },
  language: 'es'
}

export const userPreferenceSlice = createSlice({
  name: 'userpreference',
  initialState: getLocalStorage('user-preference') ? JSON.parse(getLocalStorage('user-preference') as string) : initialState,
  reducers: {
    savePreference (state: UserPreference, action: PayloadAction<UserPreference>) {
      setLocalStorage('user-preference', action.payload)
      return { ...state, ...action.payload }
    },
    disabledNotificationsPreference (state: UserPreference) {
      const result = { ...state, ...stateNotificationDisabled }
      setLocalStorage('user-preference', result)
      return result
    },
    resetPreference (state: UserPreference) {
      const result = { ...state, ...stateInitalOfNotification }
      setLocalStorage('user-preference', result)
      return result
    },
    editPreferredChannelOfUse (state: UserPreference, action) {
      const result = {
        ...state,
        preferredChannelOfUse: action.payload
      }
      setLocalStorage('user-preference', result)
      return result
    },
    editGivenCurrency (state: UserPreference, action) {
      const result = {
        ...state,
        givenCurrency: action.payload
      }
      setLocalStorage('user-preference', result)
      return result
    },
    editLanguage (state: UserPreference, action: PayloadAction<string>) {
      const result = {
        ...state,
        language: action.payload
      }
      setLocalStorage('user-preference', result)
      return result
    },
    editNotificationsInApp (state: UserPreference, action) {
      const result = {
        ...state,
        notifications: {
          ...current(state).notifications,
          inApp: action.payload
        }
      }
      setLocalStorage('user-preference', result)
      return result
    },
    editNotificationsPush (state: UserPreference, action) {
      const result = {
        ...state,
        notifications: {
          ...current(state).notifications,
          push: action.payload
        }
      }
      setLocalStorage('user-preference', result)
      return result
    },
    editNotificationsEmail (state: UserPreference, action) {
      const result = {
        ...state,
        notifications: {
          ...current(state).notifications,
          email: action.payload
        }
      }
      setLocalStorage('user-preference', result)
      return result
    },
    editNotificationsSms (state: UserPreference, action) {
      const result = {
        ...state,
        notifications: {
          ...current(state).notifications,
          sms: action.payload
        }
      }
      setLocalStorage('user-preference', result)
      return result
    }
  }
})

export const {
  savePreference,
  editNotificationsInApp,
  editNotificationsPush,
  editNotificationsEmail,
  editNotificationsSms,
  resetPreference,
  disabledNotificationsPreference,

  editPreferredChannelOfUse,
  editGivenCurrency,
  editLanguage
} = userPreferenceSlice.actions
export default userPreferenceSlice.reducer
