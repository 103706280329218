import { IonButton, IonCheckbox, IonCol, IonImg, IonRow } from '@ionic/react'
import { useCallback } from 'react'
import { useAppSelector } from '../../../store/hooks'
import { type Product } from '../../../models/product'

interface Props {
  product: Product
  productIsSelected: boolean
  setProductsSelected: (value: React.SetStateAction<Product[]>) => void
}

const ProductItem = ({ product, productIsSelected, setProductsSelected }: Props) => {
  const isDarkMode = useAppSelector(store => store.userDarkmode)
  const backgroundRowSelected = isDarkMode ? '#3d3d3d' : '#f2f2f2'

  const handleSelectProduct = useCallback(({ e, product }: { e: any, product: Product }) => {
    console.log('handleSelectProduct')

    const cheked = Boolean(e?.detail?.checked)
    if (cheked) {
      setProductsSelected(prevProducts => [...prevProducts, product])
      return
    }
    setProductsSelected(prevProduct => {
      const filteredProducts = prevProduct.filter(p => p.id !== product.id)
      return filteredProducts
    })
  }, [])

  return (
    <>
      <div style={{ width: '100%', height: '1px', backgroundColor: '#f2f2f2', margin: '.5rem 0' }}></div>
      <article>
        <IonRow className='ion-justify-content-start ion-align-items-center' style={{ backgroundColor: productIsSelected ? backgroundRowSelected : 'transparent' }}>
          <IonCol className='flex ion-align-items-center'>
            <IonCheckbox checked={productIsSelected} value={productIsSelected} onIonChange={(e) => { handleSelectProduct({ e, product }) }} />
            <IonImg src={product.image} alt={product.name} style={{ width: 40, height: 40 }} className="ion-align-self-center ion-margin-start" />
          </IonCol>
          <IonCol>
            {product.name}
          </IonCol>
          <IonCol>
            <IonButton color="secondary" shape="round" fill="outline" size='small'>{product.status}</IonButton>
          </IonCol>
          <IonCol>
            {product.inventary}
          </IonCol>
          <IonCol>
            Físico
          </IonCol>
          <IonCol>
            {product.seller}
          </IonCol>
        </IonRow>
      </article>
    </>
  )
}

export default ProductItem
