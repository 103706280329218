import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { type Discount, type UserDiscount } from '../../models/discount'
import { getLocalStorage, setLocalStorage } from '../../utilities/localStorage.utility'

const initialDiscountState: UserDiscount = {
  discounts: []
}

export const discountSlice = createSlice({
  name: 'discounts',
  initialState: getLocalStorage('discounts') ? JSON.parse(getLocalStorage('discounts') as string) : initialDiscountState,
  reducers: {
    addDiscount (state: UserDiscount, action: PayloadAction<Discount>) {
      const result = { ...state, discounts: [...state.discounts, action.payload] }
      setLocalStorage('discounts', result)
      return result
    }
  }
})

export const {
  addDiscount
} = discountSlice.actions
export default discountSlice.reducer
