import { IonButton, IonCard, IonLabel } from '@ionic/react'
import { TitleForm } from '../../../../components/Atoms/TitleForm'
import ModalSmall from '../../../../components/Organisms/ModalSmall/ModalSmall'
import { useState } from 'react'

export function SyncUp () {
  const [isOpenModal, setIsOpenModal] = useState(false)

  return (
		<>
			<IonCard className="box ion-padding">
				<TitleForm title='Sincronizar' customClass='ion-padding-start'/>
				<div style={{ marginTop: '3.5rem', marginBottom: '1.5rem' }} onClick={() => { setIsOpenModal(true) }}>
					<IonButton color="secondary" shape="round" size='large' fill='outline' expand="full">Recargar todos los pedidos</IonButton>
				</div>
			</IonCard>
			<ModalSmall isOpen={isOpenModal} onDidDismiss={() => { setIsOpenModal(false) }}>
				<TitleForm title='Sincronizar' customClass='ion-padding-start ion-text-center'/>
				<IonLabel>
					<p className='ion-text-wrap ion-padding-start ion-padding-top ion-text-center'>La recarga de todos los pedidos puede tardar algunos segundos y solo debes realizarlo si tiens problemas</p>
				</IonLabel>
				<div style={{ marginTop: '1.5rem', marginBottom: '1.5rem', display: 'flex', justifyContent: 'center' }} onClick={() => { setIsOpenModal(true) }}>
					<IonButton color="danger" shape="round" size='large' expand='block'>Recargar</IonButton>
				</div>
			</ModalSmall>
		</>
  )
}
