import { IonAvatar, IonButton, IonCard, IonImg, IonItem, IonLabel, IonList, IonRadio, IonRadioGroup, useIonToast } from '@ionic/react'
import { useState } from 'react'
import { useHistory } from 'react-router'
import flagPortugal from '../../../../assets/flag-portugal.svg'
import flagSpain from '../../../../assets/flag-spain.svg'
import flagUsa from '../../../../assets/flag-united-states.svg'
import { TitleForm } from '../../../../components/Atoms/TitleForm'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import { editLanguage } from '../../../../store/states/userPreference'

const ListLanguage: React.FC = () => {
  const [present] = useIonToast()
  const userPreference = useAppSelector(store => store.userPreference)
  const dispatch = useAppDispatch()
  const [language, setLanguage] = useState<string>(userPreference.language)
  const history = useHistory()

  const handleSubmit = () => {
    dispatch(editLanguage(language))
    present({
      message: 'Cambio guardado exitosamente',
      duration: 2500,
      position: 'bottom',
      color: 'success'
    })
  }

  const onChange = (e: any) => {
    setLanguage(e.detail.value)
  }

  return (
		<>
			<IonCard className="box ion-padding">
				<div>
					<TitleForm title="Idioma" customClass="ion-margin-start" />
					<IonList>
						<IonRadioGroup value={language} onIonChange={e => { onChange(e) }}>
							<IonItem>
								<IonAvatar slot="start" className="flex ion-justify-content-center ion-align-items-center">
									<IonImg alt="Spain" src={flagSpain} style={{ width: '30px', height: '30px' }} />
								</IonAvatar>
								<IonLabel>Español</IonLabel>
								<IonRadio slot="end" value="es" />
							</IonItem>
							<IonItem>
								<IonAvatar slot="start" className="flex ion-justify-content-center ion-align-items-center">
									<IonImg alt="Portugal" src={flagPortugal} style={{ width: '30px', height: '30px' }} />
								</IonAvatar>
								<IonLabel>Portugues</IonLabel>
								<IonRadio slot="end" value="pr" />
							</IonItem>
							<IonItem>
								<IonAvatar slot="start" className="flex ion-justify-content-center ion-align-items-center">
									<IonImg alt="Usa" src={flagUsa} style={{ width: '30px', height: '30px' }} />
								</IonAvatar>
								<IonLabel>Inglés</IonLabel>
								<IonRadio slot="end" value="en" />
							</IonItem>
						</IonRadioGroup>
					</IonList>
					<div className="ion-text-end">
						<IonButton color="secondary" shape="round" type="submit" onClick={() => { handleSubmit() }}>Guardar</IonButton>
						<IonButton color="secondary" shape="round" fill="clear" type="button" onClick={() => { history.goBack() }}>Cancelar</IonButton>
					</div>
				</div>
			</IonCard>
		</>
  )
}

export default ListLanguage
