import { IonButton, IonDatetime, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonSegment, IonSegmentButton } from '@ionic/react'
import { calendar, mail, print } from 'ionicons/icons'
import { useState } from 'react'
import { TitleForm } from '../../../../components/Atoms/TitleForm'
import ModalSmall from '../../../../components/Organisms/ModalSmall/ModalSmall'

export function HeaderReports () {
  const [openModalPrint, setOpenModalPrint] = useState(false)
  const [openModalEmail, setOpenModalEmail] = useState(false)

  const [openModalDateStart, setOpenModalDateStart] = useState(false)
  const [dateStartValue, setDateStartValue] = useState('Date start')

  const [openModalDateEnd, setOpenModalDateEnd] = useState(false)
  const [dateEndValue, setDateEndValue] = useState('Date end')

  const [segment, setSegment] = useState('daily')

  return (
    <>
      <IonHeader className="ion-padding">
        <div className="flex ion-justify-content-between ion-align-items-center">
          <div>
            <IonIcon icon={calendar} style={{ fontSize: '22px' }} slot="start"></IonIcon>
          </div>
          <TitleForm title="Junio 20, 2023" customClass="ion-no-padding ion-no-margin" />
          <div>
            <IonIcon icon={print} slot="start" style={{ fontSize: '22px' }} title='Imprimir' onClick={() => { setOpenModalPrint(true) }} />
            <IonIcon icon={mail} slot="start" style={{ fontSize: '22px' }} className='ion-padding-start' onClick={() => { setOpenModalEmail(true) }} />
          </div>
        </div>
      </IonHeader>
      <ModalSmall isOpen={openModalEmail} onDidDismiss={() => { setOpenModalEmail(false) }}>
        <div className="ion-text-center ion-padding">
          <IonIcon icon={mail} style={{ fontSize: '52px' }} color='dark' />
          <TitleForm title="Correo enviado" customClass="ion-padding-start ion-no-margin" />
          <TitleForm title="juanperez@gmail.com" customClass="ion-no-padding ion-no-margin" />
        </div>
        <div className="ion-text-center">
					<IonButton color="secondary" shape="round" onClick={() => { setOpenModalEmail(false) }}>Aceptar</IonButton>
					<IonButton color="secondary" shape="round" fill="clear" type="button" onClick={() => { setOpenModalEmail(false) }}>Cancelar</IonButton>
				</div>
      </ModalSmall>

      <ModalSmall isOpen={openModalPrint} onDidDismiss={() => { setOpenModalPrint(false) }}>
        <div className="box" style={{ minWidth: '500px' }}>
          <IonItem className='ion-no-padding ion-no-margin' lines='none'>
            <TitleForm title="Sales summary" customClass="ion-padding-start ion-no-margin" />
          </IonItem>
          <div className="ion-padding" style={{ width: '100%' }}>
            <IonSegment style={{ width: '100%' }} color='secondary' value={segment} scrollable onIonChange={e => { setSegment(e.detail.value as string) }} className='ion-padding-horizontal'>
              <IonSegmentButton value={'daily'}><IonLabel>Daily</IonLabel></IonSegmentButton>
              <IonSegmentButton value={'custom'}><IonLabel>Custom</IonLabel></IonSegmentButton>
            </IonSegment>
          </div>
          <IonList lines="none">
            <IonItem button={true} detail={true} onClick={() => { setOpenModalDateStart(true) }} className='input-item'>{dateStartValue}</IonItem>
            <IonItem button={true} detail={true} onClick={() => { setOpenModalDateEnd(true) }} className='input-item'>{dateEndValue}</IonItem>
          </IonList>
          <div className="ion-text-center">
            <IonButton color="secondary" shape="round" type="submit" onClick={() => { setOpenModalPrint(false) }}>Generar reporte</IonButton>
          </div>
        </div>
      </ModalSmall>

      <ModalSmall isOpen={openModalDateStart} onDidDismiss={() => { setOpenModalDateStart(false) }}>
				<IonDatetime
					locale="es-ES"
					value={dateStartValue}
					onIonChange={e => { setDateStartValue(e.target.value as string) }}
          color='secondary'
				/>
				<div className="ion-text-end">
					<IonButton color="secondary" shape="round" type="submit" onClick={() => { setOpenModalDateStart(false) }}>Guardar</IonButton>
				</div>
			</ModalSmall>

      <ModalSmall isOpen={openModalDateEnd} onDidDismiss={() => { setOpenModalDateEnd(false) }}>
				<IonDatetime
					locale="es-ES"
					value={dateEndValue}
					onIonChange={e => { setDateEndValue(e.target.value as string) }}
				/>
				<div className="ion-text-end">
					<IonButton color="secondary" shape="round" type="submit" onClick={() => { setOpenModalDateEnd(false) }}>Guardar</IonButton>
				</div>
			</ModalSmall>

    </>
  )
}
