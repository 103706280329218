import { IonButton, IonCard, IonCheckbox, IonCol, IonGrid, IonItem, IonLabel, IonRow, IonSelect, IonSelectOption, IonText, useIonToast } from '@ionic/react'
import { useState } from 'react'
import { useHistory } from 'react-router'
import { Input } from '../../../../components/Atoms/Input/Input'
import { TitleForm } from '../../../../components/Atoms/TitleForm'
import ModalSmall from '../../../../components/Organisms/ModalSmall/ModalSmall'
import { banks } from '../../../../data/banks'
import { countries } from '../../../../data/countries'

const FormOpenBankAccount: React.FC = () => {
  const [present] = useIonToast()
  const history = useHistory()
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [checkedTerms, setCheckedTerms] = useState(false)
  const [bankAccount, setDocument] = useState({
    country: '',
    bank: '',
    typeDocument: '',
    numberDocument: '',
    phone: '',
    email: ''
  })

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    setIsOpenModal(true)
    e.preventDefault()
  }

  const handleCreateAccountBank = () => {
    present({
      message: 'Cuenta creada',
      duration: 2500,
      position: 'bottom',
      color: 'success'
    })
    setIsOpenModal(false)
    history.replace('/natural/bancos-y-tarjetas/1', { replace: true })
  }

  const handleChange = (e: any) => {
    setDocument(prevValue => ({
      ...prevValue,
      [e.target.name]: e.target.value
    }))
  }

  return (
		<>
		<IonCard className="box ion-padding">
			<form onSubmit={e => { handleSubmit(e) }}>
				<TitleForm title="Solicitar apertura de cuenta" customClass="ion-padding-start" />
				<IonGrid>
          <IonRow>
            <IonCol size="6">
							<IonItem className="input-item">
								<IonLabel position="floating">País</IonLabel>
								<IonSelect interface="popover" value={bankAccount.country} name="country" onIonChange={e => { handleChange(e) }}>
									{countries.map(country =>
										<IonSelectOption key={country.value} value={country.value}>{country.label}</IonSelectOption>
									)}
								</IonSelect>
							</IonItem>
            </IonCol>
            <IonCol size="6">
							<IonItem className="input-item">
								<IonLabel position="floating">Banco</IonLabel>
								<IonSelect interface="popover" value={bankAccount.bank} name="bank" onIonChange={e => { handleChange(e) }} aria-required="true" >
									{banks.map(bank =>
										<IonSelectOption key={bank.value} value={bank.value}>{bank.label}</IonSelectOption>
									)}
								</IonSelect>
							</IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="4">
							<Input label="Tipo" type="text" name="typeDocument" value={bankAccount.typeDocument} onChange={e => { handleChange(e) }}/>
            </IonCol>
            <IonCol size="8">
							<Input label="Documento" type="text" name="numberDocument" value={bankAccount.numberDocument} onChange={e => { handleChange(e) }}/>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
							<Input label="Teléfono" type="number" name="phone" value={bankAccount.phone} onChange={e => { handleChange(e) }}/>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
							<Input label="Email" type="text" name="email" value={bankAccount.email} onChange={e => { handleChange(e) }}/>
            </IonCol>
          </IonRow>
        </IonGrid>
				<div className="ion-text-end">
					<IonButton color="secondary" shape="round" type="submit">Siguiente</IonButton>
				</div>
			</form>
		</IonCard>
		<ModalSmall isOpen={isOpenModal} onDidDismiss={() => { setIsOpenModal(false) }}>
			<div className="form ion-padding">
				<IonText color="dark"><h3>Terminos y condiciones</h3></IonText>
					<IonText color="dark"><p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p></IonText>
				<IonItem className="ion-no-padding" lines="none">
					<IonCheckbox checked={checkedTerms} color="secondary" slot="start" onIonChange={e => { setCheckedTerms(e.detail.checked) }}/>
					<IonLabel>Acepto los términos y condiciones</IonLabel>
				</IonItem>
				<div className="ion-text-end">
					<IonButton color="secondary" shape="round" type="submit" onClick={() => { handleCreateAccountBank() }} disabled={!checkedTerms}>Aceptar</IonButton>
					<IonButton color="secondary" shape="round" fill="clear" type="button" onClick={() => { setIsOpenModal(false) }}>Cancelar</IonButton>
				</div>
			</div>
		</ModalSmall>
		</>
  )
}

export default FormOpenBankAccount
