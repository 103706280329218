import { IonCard, IonItem, IonList } from '@ionic/react'
import { useState } from 'react'
import { TitleForm } from '../../../../components/Atoms/TitleForm'
import ModalSmall from '../../../../components/Organisms/ModalSmall/ModalSmall'
import { FormChangePassword } from '../FormChangePassword'

const ListOfKeys: React.FC = () => {
  const [isOpenModal, setIsOpenModal] = useState(false)

  return (
		<>
		<IonCard className="box ion-padding">
      <div>
        <TitleForm title="Claves" customClass="ion-margin-start" />
				<IonList>
					<IonItem button={true} detail={true} onClick={() => { setIsOpenModal(true) }}>Acceso</IonItem>
					<IonItem button={true} detail={true}>Bloqueo</IonItem>
					<IonItem button={true} detail={true}>Desbloqueo</IonItem>
					<IonItem button={true} detail={true}>PIN</IonItem>
				</IonList>
      </div>
    </IonCard>
		<ModalSmall isOpen={isOpenModal} onDidDismiss={() => { setIsOpenModal(false) }}>
			<FormChangePassword closeModal={() => { setIsOpenModal(false) }} />
		</ModalSmall>
		</>
  )
}

export default ListOfKeys
