import { IonButton, IonIcon, IonItem, IonLabel, IonList } from '@ionic/react'
import { closeOutline, ellipsisVertical, personAddOutline } from 'ionicons/icons'

interface Props {
  setIsOpenModalPayment: React.Dispatch<React.SetStateAction<boolean>>
  setIsOpenModalSelectPayment: React.Dispatch<React.SetStateAction<boolean>>
  totalSumProducts: number
}

export function ModalSelectPaymentMethodNew ({ setIsOpenModalPayment, setIsOpenModalSelectPayment, totalSumProducts }: Props) {
  const handleSelectPaymentType = () => {
    setIsOpenModalSelectPayment(true)
    setIsOpenModalPayment(false)
  }

  return (
		<div style={{ minWidth: '100%' }}>
			<IonItem className='ion-no-padding ion-no-margin' lines='none'>
        <IonIcon icon={closeOutline} size='small' slot='start' onClick={() => { setIsOpenModalPayment(false) }} className='ion-padding-start' />
        <IonIcon icon={ellipsisVertical} size='small' slot='end' />
      </IonItem>
			<IonList>
				<IonItem button detail={true} lines='none'>
					<IonIcon icon={personAddOutline} slot="start"></IonIcon>
						<IonLabel>
						<h2>Agregar cliente</h2>
					</IonLabel>
				</IonItem>
				<IonItem button detail={false}>
					<IonLabel>
						<IonLabel><h2>Importe personalizado</h2></IonLabel>
					</IonLabel>
					<IonLabel slot='end'>$0.55</IonLabel>
				</IonItem>
				<div style={{ width: '100%', height: '1px', background: '#727070b1' }}></div>
				<IonItem button detail={false} lines='none'>
					<IonLabel>
						<IonLabel>
							<h2>Articulo de belleza 1 x 2</h2>
							<p>XS</p>
						</IonLabel>
					</IonLabel>
					<IonLabel slot='end'>$1.32</IonLabel>
				</IonItem>
				<IonItem button detail={false} lines='none'>
					<IonLabel>
						<IonLabel>
							<h2>Detergente x 3</h2>
							<p>Fuerte</p>
						</IonLabel>
					</IonLabel>
					<IonLabel slot='end'>$1350.0055</IonLabel>
				</IonItem>
				<IonItem button detail={false} lines='none'>
					<IonLabel>
						<IonLabel>
							<h2 style={{ fontWeight: 'bold' }}>Agregar descuento</h2>
						</IonLabel>
					</IonLabel>
				</IonItem>

				<div style={{ marginTop: '2rem' }}>
					<IonButton color="secondary" expand="block" onClick={() => { handleSelectPaymentType() }}>Cobrar { totalSumProducts }</IonButton>
					<div style={{ display: 'flex', gap: '1rem', justifyContent: 'space-between' }}>
						<IonButton color="secondary" expand="block" fill='outline' onClick={() => null } style={{ flex: 1 }}>Guardar pedido</IonButton>
						<IonButton color="secondary" expand="block" fill='outline' onClick={() => null } style={{ flex: 1 }}>Calendario</IonButton>
					</div>
				</div>
			</IonList>

{/*
      <ModalSmall isOpen={isOpenModalSelectPayment} onDidDismiss={() => { setIsOpenModalSelectPayment(false) }} customClass='large'>
				<ModalSelectPaymentType setIsOpenModalSelectPayment={setIsOpenModalSelectPayment} />
      </ModalSmall> */}
    </div>
  )
}
